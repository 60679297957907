import React from 'react';
import Card from '@material-ui/core/Card';
import { useSelector } from 'react-redux';

const LiveDrivers = () => {
  const freeTaxi = useSelector((state) => state.main.freeTaxi);
  const busyTaxi = useSelector((state) => state.main.busyTaxi);
  const inactiveTaxi = useSelector((state) => state.main.inactiveTaxi);
  const logoutTaxi = useSelector((state) => state.main.logoutTaxi);

  const all = () => {
    return (
      parseInt(freeTaxi) +
      parseInt(busyTaxi) +
      parseInt(inactiveTaxi) +
      parseInt(logoutTaxi)
    );
  };
  return (
    <Card
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 15,
        paddingBottom: 15,
        display: 'flex',
        flexDirection: 'row',
        width: 320,
        height: '1%',
      }}
    >
      <div style={styles.title}>
        <span style={{ color: 'gray' }}>all</span>
        <span style={{ color: 'black', fontSize: 25, fontWeight: 'bold' }}>
          {all()}
        </span>
      </div>
      <div style={styles.title}>
        <span style={{ color: 'gray' }}>free</span>
        <span style={{ color: 'green', fontSize: 25, fontWeight: 'bold' }}>
          {freeTaxi}
        </span>
      </div>
      <div style={styles.title}>
        <span style={{ color: 'gray' }}>busy</span>
        <span style={{ color: 'red', fontSize: 25, fontWeight: 'bold' }}>
          {busyTaxi}
        </span>
      </div>
      <div style={styles.title}>
        <span style={{ color: 'gray' }}>inactive</span>
        <span style={{ color: 'orange', fontSize: 25, fontWeight: 'bold' }}>
          {inactiveTaxi}
        </span>
      </div>
      <div style={styles.title}>
        <span style={{ color: 'gray' }}>logout</span>
        <span style={{ color: 'red', fontSize: 25, fontWeight: 'bold' }}>
          {logoutTaxi}
        </span>
      </div>
    </Card>
  );
};

const styles = {
  title: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default LiveDrivers;
