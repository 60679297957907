import React from "react";
import { Create, SimpleForm } from "react-admin";

import MyForm from "./MyForm";

export default (props) => (
  <Create title={"Захиалга үүсгэх"} {...props}>
    <SimpleForm redirect="list">
      <MyForm />
    </SimpleForm>
  </Create>
);
