import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDataProvider, downloadCSV } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import { Button, Paper } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';
import AdminsAutocompleteInput from '../../components/AdminsAutocompleteInput';
import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
    width: '95%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    color: 'white',
    top: 20,
    width: 1,
  },
}));
const status = (item, key) => {
  var count = 0;
  if (key === 'all') {
    return (
      status(item, 'ended') +
      status(item, 'driver-canceled') +
      status(item, 'pre-canceled') +
      status(item, 'admin-canceled') +
      status(item, 'bot-canceled')
    );
  }
  item.status.forEach((data) => {
    if (data.status === key) {
      count = data.count;
    }
  });
  return count;
};
function descendingComparator(a, b, orderBy) {
  if (status(b, orderBy) < status(a, orderBy)) {
    return -1;
  }
  if (status(b, orderBy) > status(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    label: 'Хэрэглэгчийн нэр',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'Хэрэглэгчийн дугаар',
  },
  { id: 'ended', numeric: true, disablePadding: false, label: 'Амжилттай' },
  {
    id: 'driver-canceled',
    numeric: true,
    disablePadding: false,
    label: 'Жолооч цуцалсан',
  },
  // {
  //   id: 'canceled',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Хэрэглэгч цуцалсан',
  // },
  {
    id: 'pre-canceled',
    numeric: true,
    disablePadding: false,
    label: 'Эрт цуцалсан',
  },
  {
    id: 'admin-canceled',
    numeric: true,
    disablePadding: false,
    label: 'Админ цуцалсан',
  },
  {
    id: 'bot-canceled',
    numeric: true,
    disablePadding: false,
    label: 'Систем цуцалсан',
  },

  { id: 'sumCount', numeric: true, disablePadding: false, label: 'Нийт' },
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={
                headCell.id === 'number' ? null : createSortHandler(headCell.id)
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default function AdminReport() {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('ended');
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const [data, setData] = React.useState([]);

  const getFrom = () => {
    const from = localStorage.getItem('admin_from');
    if (from) {
      return moment(from).format('YYYY-MM-DDTHH:mm');
    } else {
      return moment(new Date()).subtract(1, 'days').format('YYYY-MM-DDTHH:mm');
    }
  };
  const getTo = () => {
    const to = localStorage.getItem('admin_to');
    if (to) {
      return moment(to).format('YYYY-MM-DDTHH:mm');
    } else {
      return moment(new Date()).format('YYYY-MM-DDTHH:mm');
    }
  };

  const getAdmin = () => {
    const admin = localStorage.getItem('admin_admin')
      ? JSON.parse(localStorage.getItem('admin_admin'))
      : null;
    console.log('admin', admin);
    if (admin) {
      return admin;
    } else {
      return null;
    }
  };

  const [fromDate, setFromDate] = React.useState(getFrom());
  const [toDate, setToDate] = React.useState(getTo());
  const [admin, setAdmin] = React.useState(getAdmin());

  const [fromISODate, setFromISODate] = React.useState(
    moment(getFrom()).toISOString()
  );
  const [toISODate, setISOToDate] = React.useState(
    moment(getTo()).toISOString()
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const list = async () => {
      try {
        const { data: dataRequested } = await dataProvider.getList(
          'orders/reportByAdmin',
          {
            from: fromISODate,
            to: toISODate,
            pagination: {
              page: 1,
              end: 25,
              start: 0,
              perPage: 1000,
            },
            sort: {
              field: 'createdAt',
              _order: 'DESC',
            },
            admin: admin ? admin._id : undefined,
          }
        );
        setData(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    };
    list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, admin]);
  const exporter = () => {
    const postsForExport = data.map((item) => {
      const postForExport = {};
      postForExport.name = item.name;
      postForExport.success = status(item, 'ended');
      postForExport.driverCanceled = status(item, 'driver-canceled');
      // postForExport.canceled = status(item, 'canceled');
      postForExport.preCanceled = status(item, 'pre-canceled');
      postForExport.adminCanceled = status(item, 'admin-canceled');
      postForExport.botCanceled = status(item, 'bot-canceled');
      postForExport.all = status(item, 'all');
      return postForExport;
    });
    jsonExport(
      postsForExport,
      {
        headers: [
          'name',
          'success',
          'driverCanceled',
          // 'canceled',
          'preCanceled',
          'adminCanceled',
          'botCanceled',
        ],
      },
      (err, csv) => {
        downloadCSV(csv, 'admin');
      }
    );
  };
  const fromChange = (data) => {
    setFromDate(moment(data.target.value).format('YYYY-MM-DDTHH:mm'));
    setFromISODate(moment(data.target.value).toISOString());

    localStorage.setItem('admin_from', moment(data.target.value).toISOString());
  };

  const toChange = (data) => {
    setToDate(moment(data.target.value).format('YYYY-MM-DDTHH:mm'));
    setISOToDate(moment(data.target.value).toISOString());

    localStorage.setItem('admin_to', moment(data.target.value).toISOString());
  };

  var allEnded = 0;
  var allDriverCanceled = 0;
  // var allCanceled = 0;
  var allAdminCanceled = 0;
  var allBotCanceled = 0;
  var allPreCanceled = 0;
  var all = 0;

  let history = useHistory();

  const redirect = (adminId) => {
    const filter = {};
    filter.admin = adminId;
    filter.from = fromISODate;
    filter.to = toISODate;
    return () => {
      history.push(`/orders?filter=${JSON.stringify(filter)}`);
    };
  };

  return (
    <Paper style={{ padding: 32 }}>
      <form
        className={classes.container}
        noValidate
        style={{ flex: 1, flexDirection: 'row', display: 'flex' }}
      >
        <div style={{ marginRight: 30, width: 200 }}>
          <AdminsAutocompleteInput
            currentAdmin={admin}
            setCurrentAdmin={(v) => {
              setAdmin(v);
              localStorage.setItem('admin_admin', JSON.stringify(v));
            }}
            defaultValue={admin}
            variant="standard"
          />
        </div>
        <TextField
          id="fromDate"
          label="Эхлэх огноо"
          type="datetime-local"
          defaultValue={fromDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={fromChange}
        />
        <TextField
          id="toDate"
          label="Дуусах огноо"
          type="datetime-local"
          defaultValue={toDate}
          style={{ marginLeft: 32 }}
          className={classes.textField}
          onChange={toChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          style={{
            marginLeft: 32,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color="primary"
          onClick={() => {
            exporter();
          }}
        >
          Export
        </Button>
      </form>
      <TableContainer className={classes.container} style={{ marginTop: 32 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (item, index) => {
                allEnded = allEnded + status(item, 'ended');
                allDriverCanceled =
                  allDriverCanceled + status(item, 'driver-canceled');
                // allCanceled = allCanceled + status(item, 'canceled');
                allPreCanceled = allPreCanceled + status(item, 'pre-canceled');
                allAdminCanceled =
                  allAdminCanceled + status(item, 'admin-canceled');
                allBotCanceled = allBotCanceled + status(item, 'bot-canceled');

                all = all + status(item, 'all');
                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={item.id}
                    onClick={redirect(item.id)}
                  >
                    <StyledTableCell align={'left'}>
                      {item.name}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {item.username}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'ended')}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'driver-canceled')}
                    </StyledTableCell>
                    {/* <StyledTableCell align={'center'}>
                      {status(item, 'canceled')}
                    </StyledTableCell> */}
                    <StyledTableCell align={'center'}>
                      {status(item, 'pre-canceled')}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'admin-canceled')}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'bot-canceled')}
                    </StyledTableCell>

                    <StyledTableCell align={'center'}>
                      {status(item, 'all')}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              }
            )}
            <StyledTableRow hover tabIndex={-1} key={'all'}>
              <StyledTableCell align={'left'}></StyledTableCell>
              <StyledTableCell align={'left'}></StyledTableCell>
              <StyledTableCell align={'center'}>{allEnded}</StyledTableCell>
              <StyledTableCell align={'center'}>
                {allDriverCanceled}
              </StyledTableCell>
              {/* <StyledTableCell align={'center'}>{allCanceled}</StyledTableCell> */}
              <StyledTableCell align={'center'}>
                {allPreCanceled}
              </StyledTableCell>
              <StyledTableCell align={'center'}>
                {allAdminCanceled}
              </StyledTableCell>
              <StyledTableCell align={'center'}>
                {allBotCanceled}
              </StyledTableCell>

              <StyledTableCell align={'center'}>{all}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
