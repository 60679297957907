import List from './List';
import Create from './Create';
import Edit from './Edit';
import icon from '@material-ui/icons/Home';

export default {
  name: 'admins',
  list: List,
  icon: icon,
  create: Create,
  edit: Edit,
};
