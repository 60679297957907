import React from 'react';
import { TextInput, required, SelectInput } from 'react-admin';
import { Box } from '@material-ui/core';

const MyForm = ({ formData, isEditForm, ...rest }) => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={1} mr={{ md: 0, lg: '1em' }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нэр"
              source="name"
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нэр (Англи)"
              source="nameEn"
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput
              validate={[required()]}
              source="type"
              label="Төрөл"
              choices={[
                { label: 'Хэрэглэгч', name: 'Хэрэглэгч', id: 'user' },
                { label: 'Жолооч', name: 'Жолооч', id: 'driver' },
              ]}
              fullWidth
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyForm;
