import React from "react";
import { Edit, SimpleForm } from "react-admin";

import DetailForm from "./DetailForm";
// import MyEdit from "./MyEdit";

export default (props) => (
  <Edit title={"Зорчигчийн мэдээлэл"} {...props}>
    <SimpleForm>
      <DetailForm />
      {/* <MyEdit /> */}
    </SimpleForm>
  </Edit>
);
