import React from 'react';
import {
  TextInput,
  SelectInput,
  required,
  minLength,
  PasswordInput,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';

const MyForm = ({ formData, isEditForm, ...rest }) => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={1} mr={{ md: 0, lg: '1em' }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нэвтрэх нэр"
              source="username"
              validate={[required()]}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нэр"
              source="name"
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput
              validate={[required()]}
              source="role"
              label="Эрхийн төрөл"
              choices={[
                { label: 'Админ', name: 'Админ', id: 'root' },
                { label: 'Ахлах ажилтан', name: 'Ахлах ажилтан', id: 'admin' },
                { label: 'Оператор', name: 'Оператор', id: 'operator' },
                { label: 'Тайлангийн ажилтан', name: 'Тайлангийн ажилтан', id: 'reporter' },
                // { id: "operator", name: "Operator" },
              ]}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            </Box>
            </Box>
        {/* <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <BooleanInput
              label="Идэвхитэй"
              source="enabled"
              validate={[required()]}
              fullWidth
              defaultValue={true}
            ></BooleanInput>
          </Box>
        </Box> */}

        <Typography variant="h6" gutterBottom>
          {'Нууц үг'}
        </Typography>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <PasswordInput
              label="Нууц үг"
              source="password"
              validate={isEditForm ? null : [minLength(2)]}
              inputProps={{ autoComplete: 'new-password' }}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <PasswordInput
              label="Нууц үг давтах"
              source="password_again"
              validate={isEditForm ? null : [minLength(2)]}
              inputProps={{ autoComplete: 'new-password' }}
              fullWidth
            />
          </Box>
        </Box>

        {/* <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ImageInput source="avatar" label="Avatar" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default MyForm;
