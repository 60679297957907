import React, { useEffect } from 'react';
import {
  TextInput,
  required,
  // NumberInput,
  SelectInput,
  useDataProvider,
  minLength,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
  regex,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { ImageInput, ImageField, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';

import TicketList from './ticket/List';
import CreateButton from './ticket/Create';
import OrderLogs from '../../components/OrderLogs';

const MyForm = ({ formData, isEdit }) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [tickets, setTickets] = React.useState([]);

  const validatePhoneNumber = regex(/^\d{8}$/, 'Must be a valid phone number');
  const validateYear = regex(/^\d{4}$/, 'Must be a valid year');
  const validateRegister = regex(
    /^[А-ЯӨҮ]{2}\d{8}$/,
    'Must be a valid register'
  );
  // const validateVehicleNumber = regex(/^[A-Z]{2}\d{6}$/);
  const getTickets = async () => {
    const taxi = form.getState().values._id;
    if (taxi) {
      try {
        const { data: dataRequested } = await dataProvider.getList('tickets', {
          taxi: taxi,
          pagination: {
            page: 1,
            perPage: 50,
          },
          sort: {
            field: 'createdAt',
            order: 'DESC',
          },
        });
        setTickets(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    }
  };

  useEffect(() => {
    getTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = async () => {
    getTickets();

    try {
      const taxi = form.getState().values._id;
      await dataProvider.getOne('taxis', {
        id: taxi,
      });
    } catch (error) {
      notify(`${error.message}`, 'warning');
    }
  };

  return (
    <Box p="1em">
      <Box display="flex">
        <Box flex={1} mr={{ md: 0, lg: '1em' }}>
          <Typography variant="h6" gutterBottom>
            Жолоочийн мэдээлэл
          </Typography>

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Нэр"
                source="driver.name"
                validate={[required()]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Утасны дугаар"
                source="driver.tel"
                validate={[
                  minLength(8),
                  maxLength(8),
                  validatePhoneNumber,
                  required(),
                ]}
                fullWidth
                required
              />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Нэмэлт утасны дугаар"
                source="driver.tel2"
                validate={[
                  minLength(8),
                  maxLength(8),
                  validatePhoneNumber,
                ]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Нууц үг"
                source="password"
                validate={[required()]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                label="Хүйс"
                source="driver.gender"
                choices={[
                  { id: 'male', name: 'Male' },
                  { id: 'female', name: 'Female' },
                ]}
                validate={[required()]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Төрсөн он"
                source="driver.birthYear"
                validate={[
                  required(),
                  minLength(4),
                  maxLength(4),
                  validateYear,
                ]}
                fullWidth
              />
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Регистрийн дугаар"
                source="registrationNumber"
                validate={[
                  minLength(10),
                  maxLength(10),
                  validateRegister,
                  required(),
                ]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Гэрийн хаяг"
                source="address"
                validate={[required()]}
                fullWidth
              />
            </Box>
          </Box>

          <Typography variant="h6" gutterBottom>
            Машин
          </Typography>

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                label="Машины дугаар"
                source="vehicleNumber"
                validate={[required()]}
                fullWidth
              />
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceInput
                perPage={1000}
                label="Үйлдвэр"
                source="carMake"
                reference="makes"
                resource="makes"
                validate={[required()]}
                onChange={(value) => {
                  form.change('carModel', null);
                }}
                fullWidth
                allowNull
                format={(v) => (!v ? null : v)}
              >
                <AutocompleteInput optionText="name" autoSelect={true} />
              </ReferenceInput>
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              {formData.carMake && (
                <ReferenceInput
                  perPage={1000}
                  label="Загвар"
                  source="carModel"
                  reference="models"
                  validate={[required()]}
                  filter={{ carMake: formData.carMake }}
                  fullWidth
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              )}
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <AutocompleteInput
                label="Өнгө"
                source="carColor"
                choices={[
                  { id: 'Шар', name: 'Шар' },
                  { id: 'Цэнхэр', name: 'Цэнхэр' },
                  { id: 'Хар', name: 'Хар' },
                  { id: 'Цагаан', name: 'Цагаан' },
                  { id: 'Хөх', name: 'Хөх' },
                  { id: 'Саарал', name: 'Саарал' },
                  { id: 'Хүрэн', name: 'Хүрэн' },
                  { id: 'Ягаан', name: 'Ягаан' },
                  { id: 'Улаан', name: 'Улаан' },
                  { id: 'Ногоон', name: 'Ногоон' },
                  { id: 'Улбар шар', name: 'Улбар шар' },
                  { id: 'Бор', name: 'Бор' },
                  { id: 'Бусад', name: 'Бусад' },
                ]}
                validate={[required()]}
                fullWidth
              />
            </Box>
          </Box>

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ImageInput
                source="driverImage"
                label="Driver image"
                accept="image/*"
                format={(value) => {
                  if (!value || typeof value === 'string') {
                    return { src: value };
                  } else {
                    return value;
                  }
                }}
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </Box>
          </Box>
        </Box>

        {isEdit && (
          <Box flex={1} ml="1em">
            <Typography variant="h6" gutterBottom>
              Эрхийн мэдээлэл
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    label="Нийт эрхийн тоо"
                    source="ticket"
                    disabled={true}
                    fullWidth
                  />
                </Box>
              </Box>
              <CreateButton
                taxi={form.getState().values._id}
                onSubmit={handleOnSubmit}
              ></CreateButton>
            </Typography>

            <TicketList tickets={tickets} />
          </Box>
        )}

        <Box flex={1} ml="1em">
          {isEdit && (
            <Typography variant="h6" gutterBottom>
              Захиалгын түүх
              <OrderLogs taxi={form.getState().values._id}></OrderLogs>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MyForm;
