import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import TaxiStatusColor from "../constants/TaxiStatusColor";
import { useSelector, useDispatch } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import * as mainActions from "../actions/main";

const mystyles = {
  row: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    height: "50%",
  },
  box: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: { flex: 1, display: "flex", color: "white" },
  value: { flex: 1, display: "flex", color: "white" },
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "red",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    backgroundColor: "red",
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Sos = () => {
  const sosData = useSelector((state) => state.main.sosData);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(mainActions.clearSosData());
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={sosData ? true : false}
      onClose={handleClose}
    >
      {sosData && (
        <DialogContent dividers>
          <Typography variant="h6" style={{ marginLeft: 900 }}>
            SOS!!!
          </Typography>
          <Box style={{ width: 900, marginLeft: 500 }}>
            <div style={mystyles.row}>
              <div style={mystyles.box}>
                <div style={mystyles.title}>Нэр:</div>
                <div style={mystyles.value}>
                  <a href={`#/taxis/${sosData._id}`}>{sosData.driver.name}</a>
                </div>
              </div>

              <div style={mystyles.box}>
                <div style={mystyles.title}>Утасны дугаар:</div>
                <div style={mystyles.value}>{sosData.driver.tel}</div>
              </div>

              <div style={mystyles.box}>
                <div style={mystyles.title}>Улсын дугаар:</div>
                <div style={mystyles.value}>{sosData.vehicleNumber}</div>
              </div>

              <div style={mystyles.box}>
                <div style={mystyles.title}>Төлөв:</div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    color: TaxiStatusColor[sosData.status],
                  }}
                >
                  {sosData.status}
                </div>
              </div>
              <div style={mystyles.box}>
                <div style={mystyles.title}>Хүйс:</div>
                <div style={mystyles.value}>{sosData.driver.gender}</div>
              </div>

              <div style={mystyles.box}>
                <div style={mystyles.title}>Төрсөн он:</div>
                <div style={mystyles.value}>{sosData.driver.birthYear}</div>
              </div>

              <div style={mystyles.box}>
                <div style={mystyles.title}>Эрх:</div>
                <div style={mystyles.value}>{sosData.ticket}</div>
              </div>
            </div>
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          ХААХ
        </Button>
      </DialogActions>
    </Drawer>
  );
};
export default Sos;
