import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

const useOrderRadius = ({ googleMapRef }) => {
  const orders = useSelector((state) => state.orderRadius.orders);
  const [circlesMap] = useState({});

  const createCircle = useCallback(
    (order, city) => {
      const GoogleMap = googleMapRef.current.map_;
      var _radius = order.notifiedRadius;
      // var rMin = (_radius * 4) / 5;
      // var rMax = _radius;
      // var direction = 1;
      var circleOption = {
        center: city.center,
        fillColor: city.fillColor,
        fillOpacity: 0.1,
        map: GoogleMap,
        radius: _radius,
        strokeColor: '#3878c7',
        strokeOpacity: 1,
        strokeWeight: 0.5,
      };

      var circle = new googleMapRef.current.maps_.Circle(circleOption);

      //var circleTimer = setInterval(function () {
      //var radius = circle.getRadius();
      //if (radius > rMax || radius < rMin) {
      //direction *= 0;
      //}
      //var _par = radius / _radius - 0.7;
      //circleOption.radius = radius + direction * 10;
      //circleOption.fillOpacity = 0.6 * _par;
      //circle.setOptions(circleOption);
      //}, 20);

      circlesMap[order._id] = { circle, radius: _radius };
    },
    [circlesMap, googleMapRef]
  );

  const getCircleObject = useCallback(
    (orderId) => {
      return circlesMap[orderId];
    },
    [circlesMap]
  );

  const initalize = useCallback(() => {
    Object.keys(orders).forEach((key) => {
      const item = orders[key];

      if (item) {
        const existingCircleObject = getCircleObject(key);

        let isCreate = true;
        if (existingCircleObject) {
          if (existingCircleObject.radius !== item.notifiedRadius) {
            existingCircleObject.circle.setMap(null);
            // clearInterval(existingCircleObject.circleTimer);
            circlesMap[key] = null;
          } else {
            isCreate = false;
          }
        }

        if (isCreate) {
          if (item.location) {
            createCircle(item, {
              center: {
                lat: item.location.coordinates[1],
                lng: item.location.coordinates[0],
              },
              fillColor: 'red',
            });
          }
        }
      } else {
        const existingCircleObject = getCircleObject(key);
        if (existingCircleObject) {
          existingCircleObject.circle.setMap(null);
          // clearInterval(existingCircleObject.circleTimer);
          circlesMap[key] = null;
        }
      }
    });
  }, [circlesMap, createCircle, getCircleObject, orders]);

  useEffect(() => {
    const MapEvent = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.event
      : null;

    if (MapEvent) {
      initalize();
    }
  }, [googleMapRef, initalize]);

  return [];
};

export default useOrderRadius;
