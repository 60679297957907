import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import './DisableHistoryList.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
    flex: 1,
  },
  list: {
    height: 450,
  }
}));

export default function DisableHistoryList({ disableHistory }) {
  const classes = useStyles();

  return (
    <div className="DisableHistoryList">
      <List component="nav" aria-label="secondary mailbox folders" className={classes.list}>
        {disableHistory && disableHistory.map((item) => {
          return (
            <div key={item._id}>
              <Paper className={classes.paper}>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  <Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment>
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <ListItemText primary={`Хэрэглэгч: ${item.admin.name}`} />
                  </Grid>
                  <Grid item xs={6}>
                    <ListItemText
                      primary={
                        <span>
                          Төлөв:{' '}
                          {item.disabled ? (
                            <span style={{ color: 'red' }}>
                              {'Идэвхигүй / ' + item.description}
                            </span>
                          ) : (
                            <span style={{ color: 'green' }}>Идэвхитэй</span>
                          )}
                        </span>
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </div>
          );
        })}

        {/* <Grid item xs={10}>
          <ListItemText
            primary={`LIST: ${value.tickets && value.tickets.total}`}
          />
        </Grid> */}
      </List>
    </div>
  );
}
