import React, { useState, useEffect, useRef, useCallback } from 'react';
import { stringify } from 'query-string';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';

import Api from '../constants/Api';

const AdminsAutocompleteInput = ({
  currentAdmin,
  setCurrentAdmin,
  variant = 'outlined',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [, setSearching] = useState(false);

  const source = useRef(null);

  function getSource() {
    if (source.current == null) {
      const CancelToken = axios.CancelToken;
      source.current = CancelToken.source();
    }
    return source.current;
  }

  const getAdmins = useCallback(async () => {
    let api = search ? Api.admins : Api.admins;

    let query = search ? { q: search } : {};

    try {
      const url = `${api}?${stringify(
        query
      )}&_start=0&_end=400&_order=DESC&_sort=createdAt`;

      //Check if there are any previous pending requests
      if (typeof getSource() != typeof undefined) {
        getSource().cancel('Operation canceled due to new request.');
      }

      //new request
      source.current = null;

      const config = { cancelToken: getSource().token, headers: {} };
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      setLoading(true);

      const { data } = await axios.get(url, config);

      setAdmins(data.map((item, index) => ({ ...item, index })));

      setLoading(false);
    } catch (e) {
      console.log('err', e);
      setLoading(false);
    }
  }, [search]);

  useEffect(() => {
    if (!search) {
      return;
    }
    getAdmins();
  }, [getAdmins, search]);

  useEffect(() => {
    getAdmins();
  }, [getAdmins]);

  useEffect(() => {
    setSearch(null);
  }, []);

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <Autocomplete
          open={open}
          // freeSolo
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={(option) => {
            if (option) {
              return `${option.name} (${option.username}, ${option.role})`;
            } else {
              return '';
            }
          }}
          filterOptions={(x) => x}
          getOptionSelected={(option, value) => {
            return option.index === value.index;
          }}
          options={admins}
          loading={loading}
          style={{ width: '100%' }}
          value={currentAdmin}
          onChange={(e, val) => {
            setCurrentAdmin(val);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Хэрэглэгч"
              variant={variant}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onFocus={() => {
                setSearching(true);
              }}
              onBlur={() => {
                //Check if there are any previous pending requests
                if (typeof getSource() != typeof undefined) {
                  getSource().cancel('Operation canceled due to new request.');
                }

                //new request
                source.current = null;

                setSearching(false);
              }}
              value={search}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export default AdminsAutocompleteInput;
