const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};
export default (geo1, geo2) => {
  const lat1 = geo1.lat;
  const lon1 = geo1.lng;
  const lat2 = geo2.lat;
  const lon2 = geo2.lng;
  const R = 6371 * 1000; // Radius of the earth in meter
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in meter
  return d;
};
