import React, { useState } from 'react';

import LocationInput from '../../components/react-admin/LocationInput';

const MyForm = ({ value: valueParam, ...props }) => {
  // const form = useForm();

  const [placeSelected, setPlaceSelected] = useState(false);

  return (
    <LocationInput
      source="location"
      parse={(v) => {
        if (v && v.coordinates) {
          return {
            lat: v.coordinates[1],
            lng: v.coordinates[0],
          };
        } else {
          return v;
        }
      }}
      format={(v) => {
        if (v && v.coordinates) {
          return {
            lat: v.coordinates[1],
            lng: v.coordinates[0],
          };
        } else {
          return v;
        }
      }}
      // validate={[required()]}
      fullWidth
      placeSelected={placeSelected}
      setPlaceSelected={setPlaceSelected}
    />
  );
};

export default MyForm;
