import {
  SET_LOCATION,
  SET_TARGET_LOCATION,
  SET_DISTANCE,
} from '../actions/directions';

const initialState = {
  location: null,
  targetLocation: null,
  distance: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.data,
      };
    case SET_TARGET_LOCATION: {
      return {
        ...state,
        targetLocation: action.data,
      };
    }
    case SET_DISTANCE: {
      return {
        ...state,
        distance: action.data,
      };
    }
    default:
      return state;
  }
};
