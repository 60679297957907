import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-final-form';
import GoogleMapReact from 'google-map-react';
import { useInput } from 'react-admin';
import { stringify } from 'query-string';

import Api from '../../constants/Api';
import httpClient from '../../providers/httpClient';
import { roadFilter } from './roadFilter';
const LocationInput = ({
  value: valueParam,
  order,
  placeSelected,
  setPlaceSelected,
  ...props
}) => {
  // const {
  //   input: { name, onChange, value },
  //   meta: { touched, error },
  //   isRequired,
  // } = useInput(props);

  const {
    input: { value: valueForm },
    meta: { touched, error },
  } = useInput(props);
  const value = valueParam ? valueParam : valueForm;
  const [markerPosition, setMarkerPosition] = useState(null);
  const [marker, setMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const form = useForm();
  const googleMapRef = React.createRef();
  const [zoom] = useState(13);
  const [center, setCenter] = useState({ lat: 47.9216914, lng: 106.9184941 });
  useEffect(() => {
    const companyLocation = localStorage.getItem('companyLocation');
    if (companyLocation) {
      const json = JSON.parse(companyLocation)
      const cent = { lat: json.coordinates[1], lng: json.coordinates[0] }
      setCenter(cent);
    }
  }, []);
  const [centerDidSet, setCenterDidSet] = useState(false);

  useEffect(() => {
    const Marker = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.Marker
      : null;
    const GoogleMap = googleMapRef.current.map_;

    const Polyline = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.Marker
      : null;

    if (markerPosition && Marker && GoogleMap && !marker && Polyline) {
      form.change('location', markerPosition);
      setMarker(
        new Marker({
          position: markerPosition,
          map: GoogleMap,
          scaledSize: { width: 16, height: 16 },
          icon: {
            url: require('../../assets/map-marker.png'),
          },
        })
      );

      const { lat, lng } = markerPosition;

      const getPlaces = async () => {
        try {
          const url = `${Api.placesNearby}?${stringify({ lat, lng })}`;
          const { json: data } = await httpClient(url);
          if (data && data.length > 0) {
            form.change('place', data[0]._id);
          }
        } catch (e) { }
      };

      getPlaces();
    }
  }, [form, googleMapRef, marker, markerPosition, setMarker, value]);

  useEffect(() => {
    const map = googleMapRef.current.map_;
    if (map) {
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }
      if (!centerDidSet) {
        setCenterDidSet(true);

        map.setCenter(value);
        setPlaceSelected(false);
      }

      setMarkerPosition(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapRef.current, value, placeSelected]);

  // const handleOnClick = (e) => {
  //   if (marker) {
  //     marker.setMap(null);
  //     setMarker(null);
  //   }
  //   setMarkerPosition({ lat: e.lat, lng: e.lng });
  // };
  // console.log('marker', marker);
  useEffect(() => {
    if (googleMapRef.current && order && map && maps) {
      const GoogleMap = googleMapRef.current.map_;

      var pathCoordinates = [];
      const pathCoordinatesCalman = [];
      const pathCoordinatesCalmanFiltered = [];

      if (order && order.logs != null && order.logs.length > 0) {
        // order.logs.forEach((item) => {
        //   pathCoordinates.push({
        //     lat: item.location.coordinates[1],
        //     lng: item.location.coordinates[0],
        //   });
        // });
        // new maps.Polyline({
        //   path: pathCoordinates,
        //   map: GoogleMap,
        //   strokeColor: 'red',
        //   strokeWeight: 4,
        // });
      } else if (
        order &&
        order.road != null &&
        order.road.paths &&
        order.road.paths.length > 0
      ) {

        // order.road.paths
        //   .sort((path1, path2) => path1.timestamp - path2.timestamp)
        //   .forEach((item) => {
        //     //original coordinates
        //     if (item.accuracy <= 40) {
        //       pathCoordinates.push({
        //         lat: item.original_coordinates[0],
        //         lng: item.original_coordinates[1],
        //       });
        //     } else {
        //       console.log('accuracy', item.accuracy)
        //     }

        //     //calman coordinates
        //     pathCoordinatesCalman.push({
        //       lat: item.original_coordinates[0],
        //       lng: item.original_coordinates[1],
        //     });

        //     //calman fitered coordinates
        //     // if (
        //     //   // item.accuracy <= 20 &&
        //     //   // item.speed &&
        //     //   // item.speed > 0 &&
        //     //   // item.speed < 100
        //     //   // item.activityType === 'in_vehicle' ||
        //     //   // item.activityType === 'on_bicycle'
        //     //   //   item.activityType === 'still')
        //     // ) {
        //     pathCoordinatesCalmanFiltered.push({
        //       lat: item.original_coordinates[0],
        //       lng: item.original_coordinates[1],
        //     });
        //     // }
        //   });
        pathCoordinates = roadFilter(order);
        new maps.Polyline({
          path: pathCoordinates,
          map: GoogleMap,
          strokeColor: 'red',
          strokeWeight: 2,
        });
        // new maps.Polyline({
        //   path: pathCoordinatesCalman,
        //   map: GoogleMap,
        //   strokeColor: 'blue',
        //   strokeWeight: 4,
        // });
        // new maps.Polyline({
        //   path: pathCoordinatesCalmanFiltered,
        //   map: GoogleMap,
        //   strokeColor: 'yellow',
        //   strokeWeight: 2,
        // });
        // geodesicPolyline.setMap(map);
      }

      if (order && order.road != null && order.road.lastLocation) {
        const myLatLng = {
          lat: order.road.lastLocation.coordinates[1],
          lng: order.road.lastLocation.coordinates[0],
        };
        let MarkerLastLocation = new maps.Marker({
          position: myLatLng,
          map: GoogleMap,
        });
        MarkerLastLocation.setMap(map);
      }
      if (order && order.road != null && order.road.firstLocation) {
        const myLatLng = {
          lat: order.road.firstLocation.coordinates[1],
          lng: order.road.firstLocation.coordinates[0],
        };
        let MarkerLastLocation = new maps.Marker({
          position: myLatLng,
          map: GoogleMap,
          icon: {
            url: require('../../assets/map-marker.png'),
          },
        });
        MarkerLastLocation.setMap(map);
      }
    }
  }, [googleMapRef, map, maps, order]);

  return (
    <Fragment>
      <div
        style={{
          height: '500px',
          width: '100%',
          position: 'relative',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAEBWB-b-YZ5gCMN3aZxtWZ-3huACGEglk',
          }}
          options={() => {
            return {
              fullscreenControl: false,
              mapTypeControl: true,
              styles: [{ stylers: [{ visibility: 'on' }] }],
            };
          }}
          tilt={60}
          ref={googleMapRef}
          defaultCenter={center}
          defaultZoom={zoom}
          distanceToMouse={() => { }}
          yesIWantToUseGoogleMapApiInternals
          // onClick={handleOnClick}
          onGoogleApiLoaded={({ map, maps }) => {
            setMap(map);
            setMaps(maps);
          }}
        ></GoogleMapReact>
      </div>
      {!!(touched && error) && (
        <div
          style={{
            color: 'red',
            height: 40,
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Газрын зураг дээр байршил заана уу
        </div>
      )}
    </Fragment>
  );
};

export default LocationInput;
