import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  DateField,
  EditButton,
} from 'react-admin';

import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';
import { makeStyles } from '@material-ui/core/styles';
import OrderStatusColor from '../../constants/OrderStatusColor';

import AdminIcon from '@material-ui/icons/VerifiedUser';
import UserIcon from '@material-ui/icons/PhoneAndroid';
import DriverIcon from '@material-ui/icons/DriveEta';
import FilterSidebar from './filters';
const useStyles = makeStyles({
  rating: { color: 'blue' },
});

const StatusField = (props) => {
  if (props.record) {
    return (
      <span style={{ color: OrderStatusColor[props.record[props.source]] }}>
        {props.record[props.source]}
      </span>
    );
  }
  return <span></span>;
};

const RatingField = (props) => {
  const classes = useStyles();
  if (props.record) {
    return (
      <span className={classes.rating}>
        {props.record[props.source]
          ? props.record[props.source].toFixed(1)
          : '0'}
      </span>
    );
  }
  return <span></span>;
};

const AdminField = (props) => {
  const classes = useStyles();

  if (props.record) {
    return (
      <span className={classes.rating}>
        {props.record[props.source] === 'user' && (
          <UserIcon style={{ color: '#888' }} />
        )}
        {props.record[props.source] === 'admin' && (
          <AdminIcon style={{ color: '#888' }} />
        )}
        {props.record[props.source] === 'driver' && (
          <DriverIcon style={{ color: '#888' }} />
        )}
      </span>
    );
  }
  return <span></span>;
};

const TelField = (props) => {
  if (props.record && props.source && props.record[props.source]) {
    return (
      <span>
        {props.record[props.source] === 'user' && props.record.user.tel}
        {props.record[props.source] === 'admin' && props.record.tel}
      </span>
    );
  }
  return <span></span>;
};

export default ({ permissions, ...props }) => {
  return (
    <List
      perPage={25}
      title={'Захиалга'}
      filters={<Filter />}
      aside={
        (permissions === 'root' ||
          permissions === 'supreme' ||
          permissions === 'admin' ||
          permissions === 'reporter') && <FilterSidebar />
      }
      empty={<Empty emptyMessage={'Захиалга байхгүй байна.'} />}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="edit">
        <DateField
          label="Хэзээ"
          source="createdAt"
          showTime
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
          }}
        />
        {/* <TextField label="Жолооч" source="taxi.driver.name" /> */}
        <ReferenceField label="Жолооч" source="taxi" reference="taxis">
          <TextField source="driver.name" />
        </ReferenceField>
        <ReferenceField label="Улсын дугаар" source="taxi" reference="taxis">
          <TextField source="vehicleNumber" />
        </ReferenceField>
        <TelField label="Зорчигч" source="source" />
        {/* <ReferenceField label="Хаяг" source="place" reference="places">
          <TextField source="name" />
        </ReferenceField> */}
        <TextField label="Хаяг" source="place.name" />
        {(permissions === 'root' ||
          permissions === 'supreme' ||
          permissions === 'admin') && (
          <NumberField
            label="Төлбөр"
            source="calculatedCost"
            options={{ maximumFractionDigits: 0 }}
          />
        )}
        <StatusField label="Төлөв" source="status" />
        <TextField label="Нэмэлт мэдээлэл" source="description" />
        <TextField label="Тайлбар" source="cancelDescription" />
        {(permissions === 'root' ||
          permissions === 'supreme' ||
          permissions === 'admin' ||
          permissions === 'reporter') && (
          <TextField label="Жолоочийн нэмэлт" source="driverBonus" />
        )}
        <RatingField label="Зорчигч өгсөн үнэлгээ" source="rating" />
        <AdminField source="source" label="Захиалга" />
        {(permissions === 'root' || permissions === 'supreme') && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  );
};
