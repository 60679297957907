import React, { useCallback, useEffect } from 'react';
import {
  TextInput,
  ReferenceInput,
  required,
  SelectInput,
  useDataProvider,
  minLength,
  maxLength,
  regex,
} from 'react-admin';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, Typography } from '@material-ui/core';
import { ImageInput, ImageField, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import TicketList from './ticket/List';
import CreateButton from './ticket/Create';
import OrderLogs from '../../components/OrderLogs';
import Api from '../../constants/Api';
import httpClient from '../../providers/httpClient';
import DisableHistoryList from '../../components/DisableHistoryList';
import Moment from 'react-moment';
import Reviews from '../../components/Reviews';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { usePermissions } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  rating: { color: 'blue' },
}));

const RatingField = ({ form }) => {
  const classes = useStyles();
  const rating = form.getState().values.rating
    ? form.getState().values.rating.toFixed(1)
    : '0.0';
  const accountHolder = form.getState().values.accountHolder;
  const accountNumber = form.getState().values.accountNumber;
  const bank = form.getState().values.bank;
  const createdAt = form.getState().values.createdAt;
  return (
    <div
      style={{
        marginTop: 15,
        marginBottom: 15,
        color: 'gray',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        Ашиглалтын хугацаа дуусах:{' '}
        {form.getState().values.expireAt ? (
          <Moment format=" YYYY/MM/DD HH:mm">
            {form.getState().values.expireAt}
          </Moment>
        ) : (
          '-'
        )}
      </div>
      <div>
        Зорчигч өгсөн үнэлгээ: <span className={classes.rating}>{rating} </span>
      </div>
      <div>
        Ажилд орсон огноо:{' '}
        <Moment format=" YYYY/MM/DD HH:mm">{createdAt}</Moment>
      </div>

      {accountNumber && (
        <div>
          Дансны мэдээлэл: {bank}, {accountNumber}, {accountHolder}
        </div>
      )}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const MyForm = ({ formData, isEdit, item }) => {
  const classes = useStyles();

  const { permissions } = usePermissions();

  const form = useForm();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  //const item = useState(null);

  const [open, setOpen] = React.useState(false);
  const [openFix, setOpenFix] = React.useState(false);
  const [openDisable, setOpenDisable] = React.useState(false);
  const [disableDesc, setDisableDesc] = React.useState('');
  const [openExpire, setOpenExpire] = React.useState(false);
  const [expireType, setExpireType] = React.useState(null);

  const [openBankAccount, setOpenBankAccouont] = React.useState(false);
  const [bank, setBank] = React.useState(null);
  const [accountNumber, setAccountNumber] = React.useState('');
  const [accountHolder, setAccountHolder] = React.useState('');
  const [banks, setBanks] = React.useState([]);

  const validatePhoneNumber = regex(/^\d{8}$/, 'Must be a valid phone number');
  const validateYear = regex(/^\d{4}$/, 'Must be a valid year');
  const validateRegister = regex(
    /^[А-ЯӨҮ]{2}\d{8}$/,
    'Must be a valid register'
  );
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenExpire = () => {
    setExpireType(null);
    setOpenExpire(true);
  };
  const handleCloseExpire = () => {
    setOpenExpire(false);
  };

  const handleClickBankAccount = () => {
    setOpenBankAccouont(null);
    setOpenBankAccouont(true);
  };
  const handleCloseBankAccount = () => {
    setOpenBankAccouont(false);
  };

  const handleClickOpenFix = () => {
    setOpenFix(true);
  };
  const handleCloseFix = () => {
    setOpenFix(false);
  };

  const handleClickOpenDisable = () => {
    setOpenDisable(true);
  };
  const handleCloseDisable = () => {
    setOpenDisable(false);
  };
  const [tickets, setTickets] = React.useState([]);

  const getTickets = async () => {
    const taxi = form.getState().values._id;
    if (taxi) {
      try {
        const { data: dataRequested } = await dataProvider.getList('tickets', {
          taxi: taxi,
          pagination: {
            page: 1,
            perPage: 50,
          },
          sort: {
            field: 'createdAt',
            order: 'DESC',
          },
        });
        setTickets(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    }
  };

  const getBanks = async () => {
    try {
      const { data: dataRequested } = await dataProvider.getList('banks', {
        pagination: {
          page: 1,
          perPage: 50,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      });
      setBanks(dataRequested);
    } catch (error) {
      notify(`${error.message}`, 'warning');
    }
  };

  const getBankInfo = useCallback(async () => {
    try {
      const data = await httpClient(Api.taxisBankAccount(formData._id), {
        method: 'GET',
      });
      if (data.json) {
        const { json } = data;
        if (json) {
          setBank(json.bank);
          setAccountHolder(json.accountHolder);
          setAccountNumber(json.accountNumber);
        }
      }
    } catch (error) {
      notify(error.message, 'error');
    }
  }, [formData, notify]);

  useEffect(() => {
    getTickets();
    getBanks();
    getBankInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = async () => {
    getTickets();

    getTaxi();
  };

  const getTaxi = async () => {
    try {
      const taxi = form.getState().values._id;
      await dataProvider.getOne('taxis', {
        id: taxi,
      });
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      const _id = form.getState().values._id;
      await httpClient(Api.revokeDevice(_id), {
        method: 'PUT',
      });
      notify('Утас устлаа', 'info');
    } catch (error) {
      notify(error.message, 'error');
    }
    setOpen(false);
  };

  const handleExpireSubmit = async () => {
    if (!expireType) {
      notify('Хугацаа сонгоно уу', 'error');
      return;
    }

    try {
      const _id = form.getState().values._id;
      await httpClient(Api.taxiExpire(_id), {
        method: 'PUT',
        body: JSON.stringify({ type: expireType }),
      });
      getTaxi();
      notify('Амжилттай', 'info');
    } catch (error) {
      notify(error.message, 'error');
    }
    setOpenExpire(false);
  };

  const handleFixSubmit = async () => {
    try {
      const _id = form.getState().values._id;
      await httpClient(Api.fix(_id), {
        method: 'PUT',
      });
      notify('Утас засагдлаа', 'info');
    } catch (error) {
      notify(error.message, 'error');
    }
    setOpenFix(false);
  };

  const handleSubmitDisableOrEnable = async () => {
    setOpenDisable(false);
    try {
      const body = {};
      if (disableDesc) {
        body.description = disableDesc;
      }

      await httpClient(
        formData.disabled
          ? Api.taxisEnable(formData._id)
          : Api.taxisDisable(formData._id),
        {
          method: 'PUT',
          body: JSON.stringify(body),
        }
      );
      notify(
        formData.disabled ? 'Идэвхитэй болголоо' : 'Идэвхигүй болголоо',
        'info'
      );

      try {
        await dataProvider.getOne('taxis', {
          id: formData._id,
        });
      } catch (e) {}
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const handleUpdateBankAccount = async () => {
    setOpenBankAccouont(false);
    try {
      const body = {};
      if (bank) {
        body.bank = bank;
      }
      if (accountNumber) {
        body.accountNumber = accountNumber;
      }
      if (accountHolder) {
        body.accountHolder = accountHolder;
      }

      await httpClient(Api.taxisBankAccount(formData._id), {
        method: 'PUT',
        body: JSON.stringify(body),
      });
      notify('Дансны мэдээлэл шинэчлэгдлээ', 'info');

      try {
        await dataProvider.getOne('taxis', {
          id: formData._id,
        });
      } catch (e) {}
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  return (
    <div className={classes.container}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Жолоочийн мэдээлэл" {...a11yProps(0)} />
        <Tab label="Эрхийн мэдээлэл" {...a11yProps(1)} />
        <Tab label="Жолоочийн төлөв" {...a11yProps(2)} />
        <Tab label="Захиалгын түүх" {...a11yProps(3)} />
        <Tab label="Үнэлгээ" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div style={{ flexDirection: 'row', maxWidth: 300 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                style={{ maxWidth: 300, minWidth: 300 }}
              >
                Төхөөрөмж шинэчлэх
              </Button>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Мэдэгдэл'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Та төхөөрөмж шинэчлэх товч дарах гэж байна.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Үгүй
                </Button>
                <Button onClick={handleSubmit} color="primary" autoFocus>
                  Тийм
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
        </div>
        <div style={{ flexDirection: 'row', maxWidth: 300 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpenFix}
                style={{ maxWidth: 300, minWidth: 300 }}
              >
                Төхөөрөмж засах
              </Button>
            </div>
            <Dialog
              open={openFix}
              onClose={handleCloseFix}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Мэдэгдэл'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Жолоочийн утасыг засахдаа итгэлтэй байна уу?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseFix} color="primary">
                  Үгүй
                </Button>
                <Button onClick={handleFixSubmit} color="primary" autoFocus>
                  Тийм
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
        </div>

        {
          <div style={{ flexDirection: 'row', maxWidth: 300 }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {(permissions === 'root' ||
                permissions === 'admin' ||
                permissions === 'supreme' ||
                permissions === 'reporter') && (
                <div style={{ flex: 1 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpenExpire}
                    disabled={false}
                    style={{ maxWidth: 300, minWidth: 300 }}
                  >
                    АШИГЛАЛТЫН ХУГАЦАА СУНГАХ
                  </Button>
                </div>
              )}
              <Dialog
                open={openExpire}
                onClose={handleCloseExpire}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Мэдэгдэл'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Жолоочийн ашиглалтын хугацааг сунгахдаа итгэлтэй байна уу?
                  </DialogContentText>

                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Хугацаа
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={expireType}
                      onChange={(e) => {
                        setExpireType(e.target.value);
                      }}
                    >
                      {form.getState().values.active === false && (
                        <MenuItem value={'current'}>Энэ сар дуустал</MenuItem>
                      )}
                      <MenuItem value={'next'}>Дараа сар дуустал</MenuItem>
                      {/* {form.getState().values.active === false && ( */}
                      <MenuItem value={'month'}>1 сар</MenuItem>
                      {/* )} */}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseExpire} color="primary">
                    Үгүй
                  </Button>
                  <Button
                    onClick={handleExpireSubmit}
                    color="primary"
                    autoFocus
                  >
                    Тийм
                  </Button>
                </DialogActions>
              </Dialog>
            </Typography>
          </div>
        }

        {
          <div style={{ flexDirection: 'row', maxWidth: 300 }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {(permissions === 'root' ||
                permissions === 'admin' ||
                permissions === 'supreme' ||
                permissions === 'reporter') && (
                <div style={{ flex: 1 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickBankAccount}
                    disabled={false}
                    style={{ maxWidth: 300, minWidth: 300 }}
                  >
                    ДАНСНЫ МЭДЭЭЛЭЛ ЗАСАХ
                  </Button>
                </div>
              )}
              <Dialog
                open={openBankAccount}
                onClose={handleCloseBankAccount}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'Дансны мэдээлэл засах'}
                </DialogTitle>
                <DialogContent style={{ minWidth: 500 }}>
                  {/* <DialogContentText id="alert-dialog-description">
                    
                  </DialogContentText> */}

                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-label">Банк</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bank}
                      onChange={(e) => {
                        setBank(e.target.value);
                      }}
                    >
                      {banks.map((item) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>

                  <div style={{ height: 15 }}></div>

                  <TextField
                    label="Дансны дугаар"
                    id="standard-basic"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    fullWidth
                  />

                  <div style={{ height: 15 }}></div>

                  <TextField
                    label="Дансны нэр"
                    id="standard-basic"
                    value={accountHolder}
                    onChange={(e) => setAccountHolder(e.target.value)}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseBankAccount} color="primary">
                    Үгүй
                  </Button>
                  <Button
                    onClick={handleUpdateBankAccount}
                    color="primary"
                    autoFocus
                  >
                    Тийм
                  </Button>
                </DialogActions>
              </Dialog>
            </Typography>
          </div>
        }

        <RatingField form={form} />

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput
              perPage={1000}
              label="Груп"
              source="group"
              reference="groups"
              resource="groups"
              // validate={[required()]}
              onChange={(value) => {}}
              fullWidth
              allowNull
              // format={(v) => (!v ? null : v)}
              allowEmpty
            >
              <SelectInput optionText="name" allowEmpty />
            </ReferenceInput>
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нэр"
              source="driver.name"
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Утасны дугаар"
              source="driver.tel"
              validate={[
                minLength(8),
                maxLength(8),
                validatePhoneNumber,
                required(),
              ]}
              fullWidth
              required
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нэмэлт утасны дугаар"
              source="driver.tel2"
              validate={[
                minLength(8),
                maxLength(8),
                validatePhoneNumber,
                required(),
              ]}
              fullWidth
              required
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нууц үг"
              source="password"
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput
              label="Хүйс"
              source="driver.gender"
              choices={[
                { id: 'male', name: 'Male' },
                { id: 'female', name: 'Female' },
              ]}
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Төрсөн он"
              source="driver.birthYear"
              validate={[minLength(4), maxLength(4), validateYear, required()]}
              required
              fullWidth
            />
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Регистрийн дугаар"
              source="registrationNumber"
              validate={[
                minLength(10),
                maxLength(10),
                validateRegister,
                required(),
              ]}
              fullWidth
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Гэрийн хаяг"
              source="address"
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Машин
        </Typography>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Машины дугаар"
              source="vehicleNumber"
              validate={[required()]}
              fullWidth
              // disabled
            />
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput
              perPage={1000}
              label="Үйлдвэр"
              source="carMake"
              reference="makes"
              validate={[required()]}
              onChange={(value) => {
                form.change('carModel', null);
              }}
              fullWidth
              allowNull
              // format={(v) => (!v ? null : v)}
              allowEmpty
            >
              <SelectInput optionText="name" allowEmpty />
            </ReferenceInput>
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            {formData.carMake && (
              <ReferenceInput
                perPage={1000}
                label="Загвар"
                source="carModel"
                reference="models"
                validate={[required()]}
                filter={{ carMake: formData.carMake }}
                fullWidth
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )}
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput
              label="Өнгө"
              source="carColor"
              choices={[
                { id: 'Шар', name: 'Шар' },
                { id: 'Цэнхэр', name: 'Цэнхэр' },
                { id: 'Хар', name: 'Хар' },
                { id: 'Цагаан', name: 'Цагаан' },
                { id: 'Хөх', name: 'Хөх' },
                { id: 'Саарал', name: 'Саарал' },
                { id: 'Хүрэн', name: 'Хүрэн' },
                { id: 'Ягаан', name: 'Ягаан' },
                { id: 'Улаан', name: 'Улаан' },
                { id: 'Ногоон', name: 'Ногоон' },
                { id: 'Улбар шар', name: 'Улбар шар' },
                { id: 'Бор', name: 'Бор' },
                { id: 'Бусад', name: 'Бусад' },
              ]}
              validate={[required()]}
              fullWidth
            />
          </Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ImageInput
              source="driverImage"
              label="Driver image"
              accept="image/*"
              format={(value) => {
                if (!value || typeof value === 'string') {
                  return { src: value };
                } else {
                  return value;
                }
              }}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="h6" gutterBottom>
          Эрхийн мэдээлэл
        </Typography>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Нийт эрхийн тоо"
              source="ticket"
              disabled={true}
              fullWidth
            />
          </Box>
        </Box>
        <CreateButton
          taxi={form.getState().values._id}
          onSubmit={handleOnSubmit}
        />
        <TicketList tickets={tickets} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {isEdit && (
          <div>
            <Typography variant="h6" gutterBottom>
              Жолоочийн төлөв:{' '}
              {formData.disabled ? (
                <span style={{ color: 'red' }}>Идэвхигүй</span>
              ) : (
                <span style={{ color: 'green' }}>Идэвхитэй</span>
              )}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpenDisable}
              style={{ marginTop: 15 }}
            >
              {formData.disabled ? 'Идэвхитэй болгох' : 'Идэвхигүй болгох'}
            </Button>
            <DisableHistoryList disableHistory={formData.disableHistory} />
          </div>
        )}
      </TabPanel>
      <Dialog
        open={openDisable}
        onClose={handleCloseDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Мэдэгдэл'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Жолоочийн төлвийг {formData.disabled ? 'идэвхитэй' : 'идэвхигүй'}{' '}
            болгох уу?
          </DialogContentText>
          {!formData.disabled && (
            <form className={classes.root}>
              <TextField
                label="Тайлбар"
                id="standard-basic"
                value={disableDesc}
                onChange={(e) => setDisableDesc(e.target.value)}
                fullWidth
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisable} color="primary">
            Үгүй
          </Button>
          <Button
            onClick={handleSubmitDisableOrEnable}
            color="primary"
            autoFocus
          >
            Тийм
          </Button>
        </DialogActions>
      </Dialog>
      <TabPanel value={value} index={3}>
        {isEdit && (
          <>
            <OrderLogs taxi={form.getState().values._id}></OrderLogs>
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {isEdit && (
          <>
            <Reviews taxi={form.getState().values._id}></Reviews>
          </>
        )}
      </TabPanel>
      <Dialog
        open={openDisable}
        onClose={handleCloseDisable}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Мэдэгдэл'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Жолоочийн төлвийг {formData.disabled ? 'идэвхитэй' : 'идэвхигүй'}{' '}
            болгох уу?
          </DialogContentText>
          {!formData.disabled && (
            <form className={classes.root}>
              <TextField
                label="Тайлбар"
                id="standard-basic"
                value={disableDesc}
                onChange={(e) => setDisableDesc(e.target.value)}
                fullWidth
              />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisable} color="primary">
            Үгүй
          </Button>
          <Button
            onClick={handleSubmitDisableOrEnable}
            color="primary"
            autoFocus
          >
            Тийм
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyForm;
