import React from 'react';
import {
  Filter,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import moment from 'moment';

const driverRenderer = (choice) =>
  choice && choice.vehicleNumber
    ? `${choice.driver.name ? choice.driver.name + ' ' : ''}${
        choice.vehicleNumber
      }`
    : '';

export default (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Жолооч"
        source="taxi"
        reference="taxis"
        filterToQuery={(searchText) => {
          return { q: searchText };
        }}
        allowEmpty={false}
        alwaysOn
      >
        <AutocompleteInput
          optionText={driverRenderer}
          resettable={true}
          clearAlwaysVisible={true}
        />
      </ReferenceInput>
      <DateTimeInput
        label="Эхлэх огноо"
        source="from"
        alwaysOn
        onChange={(e) => {
          localStorage.setItem(
            'transaction_from',
            moment(e.target.value).toISOString()
          );
        }}
      />
      <DateTimeInput
        label="Дуусах огноо"
        source="to"
        alwaysOn
        onChange={(e) => {
          localStorage.setItem(
            'transaction_to',
            moment(e.target.value).toISOString()
          );
        }}
      />
    </Filter>
  );
};
