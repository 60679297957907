import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'react-moment';

const ChatApp = ({ channel, isLoading, chatClient, chatDone }) => {
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessageJustAdded, setNewMessageJustAdded] = useState(null);
  const messagePage = useRef(null);
  const chatContainer = useRef(null);
  const onMessageChanged = (event) => {
    setNewMessage(event.target.value);
  };
  const messagesLoaded = (messagePage) => {
    setMessages(messagePage.items);
  };

  const messageAdded = (message) => {
    setNewMessageJustAdded(message);
  };

  useEffect(() => {
    if (newMessageJustAdded) {
      setMessages([...messages, newMessageJustAdded]);
      setNewMessageJustAdded(null);
      scrollToMyRef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageJustAdded]);

  useEffect(() => {
    if (channel.current) {
      channel.current.getMessages(40).then(function (messagesPage) {
        messagesLoaded(messagesPage);
        if (messagesPage.hasPrevPage) {
          messagePage.current = messagesPage;
        }
        scrollToMyRef();
      });
      channel.current.on('messageAdded', messageAdded);
    }
  }, [channel, chatDone]);
  //-------------TWILIO CHAT---------------
  const sendMessage = (event) => {
    event.preventDefault();
    if (channel.current) {
      const message = newMessage;
      setNewMessage('');
      channel.current.sendMessage(message, {
        sender: 'admin',
        attributes: {
          sender: 'admin',
        },
      });
    }
  };

  const newMessageAdded = (li) => {
    // if (li) {
    //   li.scrollIntoView();
    // }
  };
  const fetchMoreData = () => {
    if (messagePage.current) {
      messagePage.current.prevPage().then((data) => {
        if (data && data.items) {
          setMessages(data.items.concat(messages));
        }
        if (data.hasPrevPage) {
          messagePage.current = data;
        } else {
          messagePage.current = null;
        }
      }); // here we can ask for following page
    } else {
    }
  };

  var loginOrChat;
  const messagesBody = messages.map((message) => {
    const user = chatClient.current.user;

    if (user.identity === message.author) {
      return (
        <li key={message.sid} ref={newMessageAdded}>
          <b style={{ color: 'blue' }}>{message.author}:</b> {message.body}
        </li>
      );
    }

    return (
      <li key={message.sid} ref={newMessageAdded}>
        <b>{message.author}:</b> {message.body}
        <br />
        <i style={{ color: 'gray', fontSize: 12 }}>
          {message.state && message.state.timestamp && (
            <Moment format="YYYY/MM/DD HH:mm">{message.state.timestamp}</Moment>
          )}
        </i>
      </li>
    );
  });
  const scrollToMyRef = () => {
    if (chatContainer.current) {
      chatContainer.current.scrollIntoView({ behavior: 'smooth' });
    }

  };
  loginOrChat = (
    <div >
      <ul
        className="messages"
      >
        <button onClick={fetchMoreData}>Өмнөх</button>
        {messagesBody}
      </ul>
      <form onSubmit={sendMessage} ref={chatContainer}>
        <label htmlFor="message">Message: </label>
        <input
          type="text"
          name="message"
          id="message"
          onChange={onMessageChanged}
          value={newMessage}
        />
        <button>Send</button>
      </form>
      <br />
      <br />
    </div>
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return <div 
  >{loginOrChat}</div>;
};

export default ChatApp;
