import httpClient from '../providers/httpClient';
import Api from '../constants/Api';

export const SET_SEGMENT_INDEX = 'SET_SEGMENT_INDEX';

export const GET_BOT_CANCELED_LOADING = 'GET_BOT_CANCELED_LOADING';
export const GET_BOT_CANCELED = 'GET_BOT_CANCELED';
export const GET_BOT_CANCELED_END = 'GET_BOT_CANCELED_END';

export const GET_REQUESTED_LOADING = 'GET_REQUESTED_LOADING';
export const GET_REQUESTED = 'GET_REQUESTED';
export const GET_REQUESTED_END = 'GET_REQUESTED_END';

export const GET_ACCEPTED_LOADING = 'GET_ACCEPTED_LOADING';
export const GET_ACCEPTED = 'GET_ACCEPTED';
export const GET_ACCEPTED_END = 'GET_ACCEPTED_END';

export const GET_BOT_CANCELED_COUNT = 'GET_BOT_CANCELED_COUNT';
export const GET_REQUESTED_COUNT = 'GET_REQUESTED_COUNT';
export const GET_ACCEPTED_COUNT = 'GET_ACCEPTED_COUNT';

export const CLEAR_DATA = 'CLEAR_DATA';
export const SYNC_ORDER = 'SYNC_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

export const setSegmentIndex = (index) => {
  return {
    type: SET_SEGMENT_INDEX,
    index: index,
  };
};

// export const getOrdersCount = (currentSegmentIndex) => {
//   return async (dispatch, getState) => {
//     let status;
//     if (currentSegmentIndex === 0) {
//       status = ['bot-canceled'];
//     } else if (currentSegmentIndex === 1) {
//       status = ['requested'];
//     } else if (currentSegmentIndex === 2) {
//       status = [
//         'accepted',
//         'arrived',
//         'started',
//         // 'canceled',
//         // 'driver-canceled',
//         // 'admin-canceled',
//       ];
//     }

//     var s = '';
//     for (var i = 0; i < status.length; i++) {
//       s += '&status=' + status[i];
//     }

//     try {
//       const response = await httpClient(
//         Api.orders + `?${s}&_start=0&_end=0&_order=DESC&_sort=createdAt`
//       );
//       const data = parseInt(response.headers.get('x-total-count'));
//       if (currentSegmentIndex === 0) {
//         dispatch({ type: GET_BOT_CANCELED_COUNT, data: data });
//       } else if (currentSegmentIndex === 1) {
//         dispatch({ type: GET_REQUESTED_COUNT, data: data });
//       } else if (currentSegmentIndex === 2) {
//         dispatch({ type: GET_ACCEPTED_COUNT, data: data });
//       }
//     } catch (e) {}
//   };
// };

// export const clearOrders = () => {
//   return async (dispatch, getState) => {
//     dispatch({ type: CLEAR_DATA });
//   };
// };

export const syncOrder = (data, topic) => {
  return {
    type: SYNC_ORDER,
    data: data,
    topic: topic,
  };
};

export const getOrders = ({ startIndex = 0, stopIndex = 20 } = {}) => {
  return async (dispatch, getState) => {
    const currentSegmentIndex = getState().orders.currentSegmentIndex;
    let status;

    let currentData = [];

    if (currentSegmentIndex === 0) {
      if (getState().main.isLoadingBotCanceled) {
        return;
      }
      dispatch({ type: GET_BOT_CANCELED_LOADING });
      status = ['bot-canceled'];
      currentData = getState().orders.ordersBotCanceled;
    } else if (currentSegmentIndex === 1) {
      if (getState().main.isLoadingRequested) {
        return;
      }
      dispatch({ type: GET_REQUESTED_LOADING });
      status = ['requested'];
      currentData = getState().orders.ordersRequested;
    } else if (currentSegmentIndex === 2) {
      if (getState().main.isLoadingAccepted) {
        return;
      }
      dispatch({ type: GET_ACCEPTED_LOADING });
      status = [
        'accepted',
        'arrived',
        'started',
        // 'canceled',
        // 'driver-canceled',
        // 'admin-canceled',
      ];
      currentData = getState().orders.ordersAccepted;
    }

    var s = '';
    for (var i = 0; i < status.length; i++) {
      s += '&status=' + status[i];
    }

    if (stopIndex < currentData.length) {
      return;
    }

    const realStopIndex = startIndex === stopIndex ? startIndex + 1 : stopIndex;

    const sourceParam = currentSegmentIndex === 0 ? '&source=admin' : '';

    try {
      const { json: data } = await httpClient(
        Api.orders +
          `?${s}&_start=${startIndex}&_end=${realStopIndex}&_order=DESC&_sort=createdAt${sourceParam}`
      );
      if (currentSegmentIndex === 0) {
        dispatch({
          type: GET_BOT_CANCELED,
          data: data,
          startIndex: startIndex,
          stopIndex: stopIndex,
        });
      } else if (currentSegmentIndex === 1) {
        dispatch({
          type: GET_REQUESTED,
          data: data,
          startIndex: startIndex,
          stopIndex: stopIndex,
        });
      } else if (currentSegmentIndex === 2) {
        dispatch({
          type: GET_ACCEPTED,
          data: data,
          startIndex: startIndex,
          stopIndex: stopIndex,
        });
      }
    } catch (e) {
      if (currentSegmentIndex === 0) {
        dispatch({ type: GET_BOT_CANCELED_END });
      } else if (currentSegmentIndex === 1) {
        dispatch({ type: GET_REQUESTED_END });
      } else if (currentSegmentIndex === 1) {
        dispatch({ type: GET_ACCEPTED_END });
      }
      throw e;
    }
  };
};

export const updateOrder = (order, data) => {
  return { type: UPDATE_ORDER, order, data };
};

export const getOrdersLive = ({ startIndex = 0, stopIndex = 20 } = {}) => {
  return async (dispatch, getState) => {
    const currentSegmentIndex = getState().orders.currentSegmentIndex;
    let status;

    let currentData = [];

    if (currentSegmentIndex === 0) {
      if (getState().main.isLoadingBotCanceled) {
        return;
      }
      dispatch({ type: GET_BOT_CANCELED_LOADING });
      status = ['bot-canceled'];
      currentData = getState().orders.ordersBotCanceled;
    } else if (currentSegmentIndex === 1) {
      if (getState().main.isLoadingRequested) {
        return;
      }
      dispatch({ type: GET_REQUESTED_LOADING });
      status = ['requested'];
      currentData = getState().orders.ordersRequested;
    } else if (currentSegmentIndex === 2) {
      if (getState().main.isLoadingAccepted) {
        return;
      }
      dispatch({ type: GET_ACCEPTED_LOADING });
      status = [
        'accepted',
        'arrived',
        'started',
        // 'canceled',
        // 'driver-canceled',
        // 'admin-canceled',
      ];
      currentData = getState().orders.ordersAccepted;
    }

    var s = '';
    for (var i = 0; i < status.length; i++) {
      s += '&status=' + status[i];
    }

    if (stopIndex < currentData.length) {
      return;
    }

    const realStopIndex = startIndex === stopIndex ? startIndex + 1 : stopIndex;

    const sourceParam = currentSegmentIndex === 0 ? '&source=admin' : '';

    try {
      const { json: data } = await httpClient(
        Api.ordersLive +
          `?${s}&_start=${startIndex}&_end=${realStopIndex}&_order=DESC&_sort=createdAt${sourceParam}`
      );
      if (currentSegmentIndex === 0) {
        dispatch({
          type: GET_BOT_CANCELED,
          data: data,
          startIndex: startIndex,
          stopIndex: stopIndex,
        });
      } else if (currentSegmentIndex === 1) {
        dispatch({
          type: GET_REQUESTED,
          data: data,
          startIndex: startIndex,
          stopIndex: stopIndex,
        });
      } else if (currentSegmentIndex === 2) {
        dispatch({
          type: GET_ACCEPTED,
          data: data,
          startIndex: startIndex,
          stopIndex: stopIndex,
        });
      }
    } catch (e) {
      if (currentSegmentIndex === 0) {
        dispatch({ type: GET_BOT_CANCELED_END });
      } else if (currentSegmentIndex === 1) {
        dispatch({ type: GET_REQUESTED_END });
      } else if (currentSegmentIndex === 1) {
        dispatch({ type: GET_ACCEPTED_END });
      }
      throw e;
    }
  };
};
