import httpClient from '../providers/httpClient';
import Api from '../constants/Api';

import {
  GET_BOT_CANCELED_COUNT,
  GET_REQUESTED_COUNT,
  GET_ACCEPTED_COUNT,
} from './orders';

export const GET_INIT = 'GET_INIT';
export const GET_INIT_LOADING = 'GET_INIT_LOADING';
export const GET_TAXIS = 'GET_TAXIS';
export const GET_ALL_TAXIS = 'GET_ALL_TAXIS';
export const GET_TAXIS_LOADING = 'GET_TAXIS_LOADING';
export const GET_TAXIS_INFO = 'GET_TAXIS_INFO';
export const GET_TAXIS_INFO_LOADING = 'GET_TAXIS_INFO_LOADING';
export const SOS_RECEIVED = 'SOS_RECEIVED';
export const SOS_CLEAR = 'SOS_CLEAR';
export const UPDATE_TAXI = 'UPDATE_TAXI';
export const CLEAR_UPDATE_TAXI = 'CLEAR_UPDATE_TAXI';
export const GET_CHAT_TOKEN = 'GET_CHAT_TOKEN';
export const ORDER_RADIUS = 'ORDER_RADIUS';

export const getInit = () => {
  return async (dispatch, getState) => {
    if (getState().main.isLoading) {
      return;
    }

    dispatch({ type: GET_INIT_LOADING });
    const { json: data } = await httpClient(Api.init);

    dispatch({ type: GET_INIT, data: data });
  };
};

export const getTaxis = () => {
  return async (dispatch, getState) => {
    if (getState().main.taxisIsLoading) {
      return;
    }

    const prevCheckDate = getState().main.checkDate;

    dispatch({ type: GET_TAXIS_LOADING });

    const options = {};
    if (prevCheckDate) {
      options.headers = new Headers({ 'X-Last-Check': prevCheckDate });
    }

    const { json: data, headers } = await httpClient(Api.taxisLive, options);
    const newCheckDate = headers.has('X-Last-Check')
      ? headers.get('X-Last-Check')
      : null;
    const freeTaxi = headers.get('x-taxi-free');
    const busyTaxi = headers.get('x-taxi-busy');
    const inactiveTaxi = headers.get('x-taxi-inactive');
    const logoutTaxi = headers.get('x-taxi-logout');

    const botCanceled = headers.get('x-order-canceled');
    const requested = headers.get('x-order-requested');
    const accepted = headers.get('x-order-accepted');

    dispatch({ type: GET_BOT_CANCELED_COUNT, data: parseInt(botCanceled) });
    dispatch({ type: GET_REQUESTED_COUNT, data: parseInt(requested) });
    dispatch({ type: GET_ACCEPTED_COUNT, data: parseInt(accepted) });

    dispatch({
      type: GET_TAXIS,
      data: data,
      checkDate: newCheckDate,
      freeTaxi: freeTaxi,
      busyTaxi: busyTaxi,
      inactiveTaxi: inactiveTaxi,
      logoutTaxi: logoutTaxi,
    });
  };
};

export const getTaxisAll = () => {
  return async (dispatch, getState) => {
    const { json: data } = await httpClient(Api.taxis);
    dispatch({ type: GET_ALL_TAXIS, data: data });
  };
};

export const getTaxiInfo = (taxiId) => {
  return async (dispatch, getState) => {
    if (getState().main.taxisIsLoading) {
      return;
    }

    dispatch({ type: GET_TAXIS_INFO_LOADING });
    const { json: data } = await httpClient(`${Api.taxis}/${taxiId}`);
    dispatch({ type: GET_TAXIS_INFO, data: data });
  };
};

export const updateTaxi = (data) => {
  return {
    type: UPDATE_TAXI,
    data: data,
  };
};

export const clearUpdatedTaxi = (data) => {
  return {
    type: CLEAR_UPDATE_TAXI,
  };
};

export const orderRadius = (data) => {
  return {
    type: ORDER_RADIUS,
  };
};

export const sosReceived = (data) => {
  return {
    type: SOS_RECEIVED,
    data: data,
  };
};

export const clearSosData = () => {
  return {
    type: SOS_CLEAR,
  };
};

export const getChatToken = () => {
  return async (dispatch) => {
    const { json: data } = await httpClient(`${Api.chatToken}`, {
      method: 'POST',
    });
    let token = null;
    if (data) {
      token = data.token;
    }
    dispatch({ type: GET_CHAT_TOKEN, data: token });
  };
};
