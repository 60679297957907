import React, { useCallback, useEffect, useState } from 'react';
import { useNotify, useDataProvider, Notification } from 'react-admin';
import {
  Button,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalHotKeys } from 'react-hotkeys';

import PlacesNearbyInput from './react-admin/PlacesNearbyInput';
import * as directionsActions from '../actions/directions';

const OrderCard = ({
  location,
  setLocation,
  placeSelectedByInput,
  setPlaceSelectedByInput,
}) => {
  const dispatch = useDispatch();

  const distance = useSelector((state) => state.directions.distance);
  const isStaticPrice = localStorage.getItem('staticPrice') ? Boolean(localStorage.getItem('staticPrice')==='true') : false;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [place, setPlace] = useState(null);
  const [targetPlace, setTargetPlace] = useState(null);
  const [tel, setTel] = useState('');
  const [times, setTimes] = useState(1);
  const [description, setDescription] = useState('');
  const [driverBonus, setDriverBonus] = useState('');
  const [extraFarCost, setExtraFarCost] = useState('');
  const [group, setGroup] = useState('0');

  useEffect(() => {
    if (place && place.location) {
      dispatch(
        directionsActions.setLocation({
          lat: place.location.coordinates[1],
          lng: place.location.coordinates[0],
        })
      );
    } else {
      dispatch(directionsActions.setLocation(null));
    }
  }, [dispatch, place]);

  useEffect(() => {
    if (targetPlace && targetPlace.location) {
      dispatch(
        directionsActions.setTargetLocation({
          lat: targetPlace.location.coordinates[1],
          lng: targetPlace.location.coordinates[0],
        })
      );
    } else {
      dispatch(directionsActions.setTargetLocation(null));
    }
  }, [dispatch, targetPlace]);

  const getGroups = async () => {
    setIsLoading(true);
    try {
      const { data: groups } = await dataProvider.getList('groups', {
        pagination: {
          page: 1,
          perPage: 50,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      });

      setGroups(
        groups.map((item) => {
          return (
            <MenuItem key={item._id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })
      );
    } catch (e) {
      notify(e.message, 'warning');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = { times };

      if (location) {
        data.location = location;
      } else {
        throw new Error('Байршил оруулаагүй байна');
      }

      if (place) {
        data.place = place.id;
      } else {
        throw new Error('Газар оруулаагүй байна');
      }

      if (targetPlace) {
        data.targetPlace = targetPlace.id;
      }

      if (tel) {
        data.tel = tel;
      } else {
        throw new Error('Утасны дугаар оруулна уу');
      }

      if (description && description.length > 0) {
        data.description = description;
      }

      if (driverBonus && isStaticPrice===false) {
        data.driverBonus = driverBonus;
      }

      if (group && group !== '0') {
        data.group = group;
      }
      if (isStaticPrice===true) {
        data.staticPrice = driverBonus
      }

      await dataProvider.create('orders', { data });
      setPlace(null);
      setTargetPlace(null);
      setLocation(null);
      setTel('');
      setTimes(1);
      setDescription('');
      setGroup('0');
      setDriverBonus('');
      setExtraFarCost('');

      notify('Захиалга амжилттай бүртгэгдлээ!', 'info');
    } catch (e) {
      notify(e.message, 'warning');
      console.log('e', e);
    }
    setIsLoading(false);
  }, [dataProvider, description, driverBonus, group, isStaticPrice, location, notify, place, setLocation, targetPlace, tel, times]);

  const handlers = {
    SUBMIT_ORDER: submit,
  };

  const key = localStorage.getItem('keyOrderCreate');
  const keyMap = {
    SUBMIT_ORDER: key ? key : 'ctrl+enter',
  };

  return (
    <div
      style={{
        marginTop: 15,
        wdith: 310,
        maxWidth: 320,
      }}
    >
      <Card>
        <CardContent style={{ padding: 8 }}>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              marginTop: 12,
            }}
          >
            <div style={{ flex: 1 }}>
              <PlacesNearbyInput
                location={location}
                setLocation={setLocation}
                placeSelectedByInput={placeSelectedByInput}
                setPlaceSelectedByInput={setPlaceSelectedByInput}
                value={place}
                onChange={(value) => {
                  if (value && value.extraFarCost) {
                    setExtraFarCost(value.extraFarCost);
                  }
                  else {
                    setExtraFarCost('');
                  }
                  setPlace(value);
                }}
              />
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              marginTop: 12,
            }}
          >
            <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
              <PlacesNearbyInput
                value={targetPlace}
                onChange={(value) => setTargetPlace(value)}
                label="Буух хаяг"
              />
              {distance && (
                <div
                  style={{
                    color: 'red',
                    width: 100,
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {distance}
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              marginTop: 12,
            }}
          >
            <div style={{ flex: 1, marginRight: 4 }}>
              <TextField
                variant="outlined"
                label="Утасны дугаар"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                size={'small'}
                minLength={8}
                maxLength={8}
              />
            </div>
            <div style={{ flex: 1, marginLeft: 4 }}>
              <TextField
                variant="outlined"
                label="Такси тоо"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={times}
                onChange={(e) => setTimes(e.target.value)}
                min="1"
                size={'small'}
              />
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              marginTop: 12,
            }}
          >
            <TextField
              variant="outlined"
              label="Нэмэлт мэдээлэл"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              size={'small'}
              multiline
              fullWidth
            />
          </div>
          {isStaticPrice === false && <div style={{
            flexDirection: 'row',
            display: 'flex',
            marginTop: 12,
          }}>
            <TextField
              variant="outlined"
              label="Холын нэмэгдэл"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              contentEditable={false}
              value={extraFarCost}
              size={'small'}
              fullWidth
            />
          </div>}
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              marginTop: 12,
            }}
          >

            <div style={{ flex: 1, marginRight: 4 }}>
              <TextField
                variant="outlined"
                label={isStaticPrice === true ? "Үнэ" : "TIP"}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                value={driverBonus}
                onChange={(e) => setDriverBonus(e.target.value)}
                size={'small'}
              />
            </div>
            <div style={{ flex: 1, marginLeft: 4 }}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel
                  size="small"
                  style={{
                    backgroundColor: '#fff',
                    paddingLeft: 3,
                    paddingRight: 3,
                  }}
                // shrink={true}
                >
                  Груп
                </InputLabel>
                <Select
                  value={group}
                  onChange={(e) => {
                    setGroup(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem key={'0'} value={'0'}>
                    Бүгд
                  </MenuItem>
                  {groups}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={{ height: 10 }}></div>
          <Button
            variant="contained"
            color="primary"
            onClick={submit}
            startIcon={<SaveIcon />}
            fullWidth
          >
            Захиалга үүсгэх
          </Button>
        </CardContent>
      </Card>
      {isLoading && (
        <div
          style={{
            backgroundColor: '#fff',
            flex: 1,
            position: 'absolute',
            top: 100,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Notification />
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />;
    </div>
  );
};

export default OrderCard;
