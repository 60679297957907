import React, { useState } from 'react';
import { required, TextInput, minLength, maxLength } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { useForm } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import LocationInput from '../../components/react-admin/LocationInput';
import Moment from 'react-moment';
import { getStatusText } from '../../utils/order';
import { getCosts } from '../../utils/cost';
import { usePermissions } from 'react-admin';
// import { getDistance } from 'geolib';
import getDistance from './getDistance';
import { roadFilter } from '../../components/react-admin/roadFilter';
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// const deg2rad = (deg) => {
//   return deg * (Math.PI / 180);
// };

// const calculateDistance = (geo1, geo2) => {
//   const lat1 = geo1.lat;
//   const lon1 = geo1.lng;
//   const lat2 = geo2.lat;
//   const lon2 = geo2.lng;
//   const R = 6371 * 1000; // Radius of the earth in meter
//   const dLat = deg2rad(lat2 - lat1); // deg2rad below
//   const dLon = deg2rad(lon2 - lon1);
//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(deg2rad(lat1)) *
//       Math.cos(deg2rad(lat2)) *
//       Math.sin(dLon / 2) *
//       Math.sin(dLon / 2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const d = R * c; // Distance in meter
//   return d;
// };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 280,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
    maxWidth: '350px',
  },
  rating: { color: 'blue' },
  note: { color: 'red' },
}));

const RatingField = ({ form }) => {
  const classes = useStyles();
  const rating = form.getState().values.rating
    ? form.getState().values.rating.toFixed(1)
    : '0.0';

  return (
    <div
      style={{
        marginLeft: 15,
        justifyContent: 'center',
        textAlign: 'left',
        color: 'gray',
        fontWeight: 400,
      }}
    >
      Зорчигч өгсөн үнэлгээ: <span className={classes.rating}>{rating}</span>
    </div>
  );
};

const RatingNoteField = ({ form }) => {
  const classes = useStyles();
  const ratingNote = form.getState().values.ratingNote;
  const { permissions } = usePermissions();
  return (
    <div
      style={{
        marginLeft: 15,
        justifyContent: 'center',
        textAlign: 'left',
        color: 'gray',
        fontWeight: 400,
      }}
    >
      Санал:
      {permissions === 'root' && (
        <span className={classes.note}>{ratingNote}</span>
      )}
      {permissions === 'admin' && (
        <span className={classes.note}>{ratingNote}</span>
      )}
      {permissions === 'reporter' && (
        <span className={classes.note}>{ratingNote}</span>
      )}
      {permissions === 'supreme' && (
        <span className={classes.note}>{ratingNote}</span>
      )}
    </div>
  );
};
const MyForm = ({ isEdit = false }) => {
  const form = useForm();
  const classes = useStyles();
  const [placeSelected, setPlaceSelected] = useState(false);

  const statusLogs = form.getState().values.statusLogs;
  const updateLogs = form.getState().values.updateLogs;
  const arriveMinute = form.getState().values.arriveMinute;
  const place = form.getState().values.place;
  const extraCosts = form.getState().values.extraCosts;
  const meter = form.getState().values.meter;
  const calculatedCost = form.getState().values.calculatedCost;
  const order = form.getState().values;

  const arr = extraCosts
    ? extraCosts.filter((item) => item.type === 'waiting')
    : [];
  let waitingSeconds = 0;
  if (arr && arr.length > 0) {
    waitingSeconds = arr[0].waitSecond;
  }

  const { permissions } = usePermissions();

  const systemDistance = (type = 'original') => {
    let tempLog;
    let distanceInMeter = 0;
    if (order && order.road && order.road.paths) {

     roadFilter(order).forEach((item) => {
          if (!tempLog) {
            tempLog = item;
          } else {
            const dist = getDistance(
              {
                lat: tempLog.lat,
                lng: tempLog.lng,
              },
              {
                lat: item.lat,
                lng: item.lng,
              }
            );
            distanceInMeter = distanceInMeter + dist;
            tempLog = item;
          }
        });
    }
    return distanceInMeter.toFixed(0);
  };

  return (
    <Box display={{ md: 'block', lg: 'flex' }} style={{ marginRight: 0 }}>
      <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ marginRight: 0 }}>
        <div style={{ flexDirection: 'row', marginRight: 8 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Захиалгын мэдээлэл
            </div>
            {isEdit && <RatingField form={form} />}
            {isEdit && <RatingNoteField form={form} />}
          </Typography>
        </div>

        <Box display={{ xs: 'block', sm: 'flex' }} style={{ marginRight: 0 }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              source={order.user && order.user.tel ? 'user.tel' : 'tel'}
              label="Утасны дугаар"
              validate={[required(), minLength(8), maxLength(8)]}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              multiline={true}
              source={order.user && order.user.tel ? 'user.tel' : 'admin.name'}
              label="Захиалсан Хэрэглэгч"
              // validate={}
              disabled
              fullWidth
            />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }} style={{ marginRight: 0 }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} style={{ marginRight: 0 }}>
            <LocationInput
              source="location"
              parse={(v) => {
                if (v && v.coordinates) {
                  return {
                    lat: v.coordinates[1],
                    lng: v.coordinates[0],
                  };
                } else {
                  return v;
                }
              }}
              format={(v) => {
                if (v && v.coordinates) {
                  return {
                    lat: v.coordinates[1],
                    lng: v.coordinates[0],
                  };
                } else {
                  return v;
                }
              }}
              validate={[required()]}
              fullWidth
              order={order}
              placeSelected={placeSelected}
              setPlaceSelected={setPlaceSelected}
            />
          </Box>
          {/* <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box> */}
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }} style={{ marginRight: 0 }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} style={{ marginRight: 0 }}>
            <TextInput
              multiline={true}
              source="description"
              label="Нэмэлт мэдээлэл"
              // validate={}
              fullWidth
            />
          </Box>
        </Box>
      </Box>
      {/* 
      <Box display={{ md: 'block', lg: 'flex' }}>
        <Box flex={1} mr={{ md: 0, lg: '1em' }}>
          <Typography variant="h6" gutterBottom>
            Захиалгын мэдээлэл
          </Typography>
          <Box flex={1} mr={{ md: 0, lg: '1em' }}>
            <Paper className={classes.paper}>
              {form.getState().values.statusLogs.map((item) => {
                return (
                  <div>
                    {'Цуцалсан Хэрэглэгч: '} {item.statusLogs.admin}
                  </div>
                );
              })}
            </Paper>
          </Box>
        </Box>
      </Box> */}

      {isEdit && (
        <Box
          display={{ md: 'block', lg: 'flex' }}
          style={{ marginRight: 0, display: 'grid' }}
        >
          <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ marginRight: 0 }}>
            <div style={{ flexDirection: 'row' }}>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'right',
                  }}
                >
                  Түүх
                </div>
              </Typography>
            </div>
            <Box
              flex={1}
              mr={{ md: 0, lg: '1em' }}
              style={{
                marginRight: 0,
                marginLeft: 16,
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableBody>
                    {place && (
                      <StyledTableRow key={place.name}>
                        <StyledTableCell align="right">
                          Дуудсан газар:
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {place.name}
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {arriveMinute && (
                      <StyledTableRow key={arriveMinute}>
                        <StyledTableCell align="right">
                          Очих минут:
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {arriveMinute + ' минут'}
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    {waitingSeconds && waitingSeconds > 0 ? (
                      <StyledTableRow key={waitingSeconds}>
                        <StyledTableCell align="right">
                          Хүлээсэн секунд:
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {waitingSeconds + ' секунд'}
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : null}
                    {statusLogs &&
                      statusLogs.map((item) => {
                        return (
                          <StyledTableRow key={item.id}>
                            <StyledTableCell align="right">
                              {getStatusText(item.status) +
                                ' ' +
                                (item.admin ? `(${item.admin.name})` : '')}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {
                                <Moment format="YYYY.MM.DD HH:mm">
                                  {item.date}
                                </Moment>
                              }
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {updateLogs &&
                      updateLogs.map((item) => {
                        return (
                          <StyledTableRow key={item._id}>
                            <StyledTableCell align="right">
                              {'Зассан ' +
                                (item.admin ? `(${item.admin.name}): ` : ' ') +
                                (
                                  <Moment format="YYYY.MM.DD HH:mm">
                                    {item.date}
                                  </Moment>
                                )}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          {(permissions === 'root' ||
            permissions === 'admin' ||
            permissions === 'supreme' ||
            permissions === 'reporter') && (
              <Box
                flex={1}
                mr={{ md: 0, lg: '1em' }}
                style={{ marginRight: 0, marginTop: -125 }}
              >
                <div style={{ flexDirection: 'row' }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'right',
                      }}
                    >
                      Төлбөрийн мэдээлэл
                    </div>
                  </Typography>
                </div>
                <Box
                  flex={1}
                  mr={{ md: 0, lg: '1em' }}
                  style={{
                    marginRight: 0,
                    marginLeft: 16,
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableBody>
                        {extraCosts &&
                          extraCosts.map((item) => {
                            return (
                              <StyledTableRow key={item.id}>
                                <StyledTableCell align="left">
                                  {item.description}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {item.cost + ' төгрөг'}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            Системийн бодсон метр
                          </StyledTableCell>
                          <StyledTableCell align="right" style={{ color: 'red' }}>
                            {systemDistance() + 'м original'}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell align="left">
                            Явсан метр
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {meter + 'м'}
                          </StyledTableCell>
                        </StyledTableRow>
                        {calculatedCost && (
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              Нийт төлбөр
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {calculatedCost + ' төгрөг'}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
};

export default MyForm;
