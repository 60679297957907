const MyValidation = (values) => {
  const errors = {};
  if (values.password && values.password.length > 0) {
    if (values.password_again !== values.password) {
      errors.password_again = ['Нууц үг таарахгүй байна'];
    }
  }
  return errors;
};
export default MyValidation;
