import admins from './admins';
import users from './users';
import orders from './orders';
import places from './places';
import taxis from './taxis';
import settings from './settings';
import bonuses from './bonuses';
import sos from './sos';
import groups from './groups';
import arrivalMinutes from './arrivalMinutes';
import reportUser from './reportUser';
import reportReview from './reportReview';
import reportHeader from './reportHeader';
import reportTransaction from './reportTransaction';
import cancelDescriptions from './cancelDescriptions';

export default [
  users,
  orders,
  places,
  taxis,
  admins,
  settings,
  bonuses,
  sos,
  groups,
  arrivalMinutes,
  reportUser,
  cancelDescriptions,
  reportReview,
  reportHeader,
  reportTransaction,
];
