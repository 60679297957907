import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import OrderListItem from './OrderListItem';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Box } from '@material-ui/core';
import { Button, useNotify, useDataProvider } from 'react-admin';
import * as ordersActions from '../../actions/orders';
import TextField from '@material-ui/core/TextField';
import Api from '../../constants/Api';
import httpClient from '../../providers/httpClient';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import MUButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const OrderList = ({ type = 'all', onClickVehicle, onClickPlace }) => {
  const classes = useStyles();

  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  let list = [];
  let isLoading = false;
  if (type === 'bot-canceled') {
    list = orders.ordersBotCanceled;
    isLoading = orders.isLoadingBotCanceled;
  } else if (type === 'requested') {
    list = orders.ordersRequested;
    isLoading = orders.isLoadingRequested;
  } else if (type === 'accepted') {
    list = orders.ordersAccepted;
    isLoading = orders.isLoadingAccepted;
  }

  const notify = useNotify();

  //EDIT DIALOG
  const [editItem, setEditItem] = useState(null);
  const [tel, setTel] = useState('');
  const [, setPlace] = useState(null);
  const [description, setDescription] = useState('');
  const [, setIsLoading] = useState(false);
  const [, setTargetPlace] = useState(null);
  const [, setGroup] = useState('0');
  const [, setDriverBonus] = useState('');
  const dataProvider = useDataProvider();
  const [times, setTimes] = useState(1);
  const [, setExtraFarCost] = useState('');
  const handleEditSubmit = async (values) => {
    try {
      const url = `${Api.orders}/${editItem._id}`;
      await httpClient(url, {
        method: 'PUT',
        body: JSON.stringify({ description, tel }),
      });
      setEditItem(null);
      dispatch(ordersActions.updateOrder(editItem, { tel, description }));
    } catch (e) {
      notify(e.message, 'error');
    }
  };

  const handleCancelSubmit = async () => {
    try {
      let desc =
        cancelItem.status === 'bot-canceled' ||
        (cancelItem.admin && cancelItem.status === 'requested')
          ? undefined
          : cancelDescription;
      await httpClient(Api.orderStatus(cancelItem._id), {
        method: 'PUT',
        body: JSON.stringify({ status: 'admin-canceled', description: desc }),
      });
      setCancelItem(null);
      notify('Захиалга цуцлагдлаа', 'info');
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  //CANCEL DIALOG
  const [cancelItem, setCancelItem] = useState(null);
  const [cancelDescription, setCancelDescription] = useState('');

  function rowRenderer({ key, parent, index, style }) {
    const item = index < list.length ? list[index] : null;
    return (
      <div style={style} key={key}>
        <div>
          {item ? (
            <OrderListItem
              key={item._id}
              item={item}
              onClickVehicle={onClickVehicle}
              onClickPlace={onClickPlace}
              number={index + 1}
              onClickEdit={handleOnClickEdit}
              onClickCancel={handleOnClickCancel}
              onClickRetry={handleOnClickRetry}
            />
          ) : (
            <div style={{ padding: 5 }}>
              <Skeleton count={50} height={20} />
            </div>
          )}
        </div>
      </div>
    );
  }

  const handleOnClickCancel = (item) => {
    setCancelDescription('');
    setCancelItem(item);
  };

  const handleOnClickEdit = (item) => {
    setTel(item.user ? item.user.tel : item.tel);
    setDescription(item.description);
    setEditItem(item);
  };

  const handleOnClickRetry = useCallback(
    async (item) => {
      setIsLoading(true);
      try {
        const data = { times };

        if (item.location) {
          data.location = { lat: item.location.coordinates[1], lng: item.location.coordinates[0] };
        }
        
        if (item.place) {
          data.place = item.place.id;
        } 
        console.log('place', item.place)
        if (item.targetPlace) {
          data.targetPlace = item.targetPlace;
        }
  
        if (item.tel) {
          data.tel = item.tel;
        }
  
        if (item.description && item.description.length > 0) {
          data.description = item.description;
        }
  
        if (item.driverBonus) {
          data.driverBonus = item.driverBonus;
        }
  
        if (item.group && item.group !== '0') {
          data.group = item.group;
        }
  
        await dataProvider.create('orders', { data });
        setPlace(null);
        setTargetPlace(null);
        setTel('');
        setTimes(1);
        setDescription('');
        setGroup('0');
        setDriverBonus('');
        setExtraFarCost('');
  
        notify('Захиалга амжилттай бүртгэгдлээ!', 'info');
      } catch (e) {
        notify(e.message, 'warning');
        console.log('e', e);
      }
      setIsLoading(false);
    }, [times, dataProvider, notify]);
    
  return (
    <div
      className="List"
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        height: window.innerHeight - 140,
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
        overflowY: 'scroll',
      }}
    >
      <ul style={{ flex: 1, padding: 0, margin: 0, listStyleType: 'none' }}>
        {isLoading ? (
          <li key={0}>{rowRenderer({ key: 0, index: 0 })}</li>
        ) : (
          list.map((item, index) => (
            <li key={index}>{rowRenderer({ key: index, index: index })}</li>
          ))
        )}
      </ul>

      <Dialog
        open={!!editItem}
        onClose={() => setEditItem(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            width: 500,
            paddingLeft: 20,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 15,
          }}
        >
          <DialogContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                {editItem && !editItem.user && (
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      marginTop: 20,
                    }}
                  >
                    <div style={{ flex: 1, marginRight: 4 }}>
                      <TextField
                        variant="outlined"
                        label="Утасны дугаар"
                        type="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                        size={'small'}
                        fullWidth
                      />
                    </div>
                  </div>
                )}

                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginTop: 20,
                  }}
                >
                  <div style={{ flex: 1, marginRight: 4 }}>
                    <TextField
                      variant="outlined"
                      label="Нэмэлт мэдээлэл"
                      type="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      size={'small'}
                      fullWidth
                    />
                  </div>
                </div>

                <div style={{ height: 15 }}></div>

                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <Button
                      label={'Захиалга засах'}
                      variant="contained"
                      onClick={handleEditSubmit}
                      fullWidth
                    />
                  </Box>
                </Box>

                <div style={{ height: 30 }}></div>
              </Box>
            </Box>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        open={cancelItem !== null}
        onClose={() => setCancelItem(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'МЭДЭГДЭЛ'}</DialogTitle>
        <DialogTitle id="alert-dialog-title" style={{ maxWidth: 300 }}>
          {cancelItem && cancelItem.place && cancelItem.place.name}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Жолооч:{' '}
          {cancelItem &&
            cancelItem.taxi &&
            cancelItem.taxi.driver &&
            cancelItem.taxi.driver.name}{' '}
          {cancelItem && cancelItem.taxi && cancelItem.taxi.vehicleNumber}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: 'red' }}
          >
            Цуцлахдаа итгэлтэй байна уу
          </DialogContentText>

          {cancelItem &&
            !(
              cancelItem.status === 'bot-canceled' ||
              (cancelItem.admin && cancelItem.status === 'requested')
            ) && (
              <form className={classes.root}>
                <TextField
                  label="Тайлбар"
                  id="standard-basic"
                  value={cancelDescription}
                  style={{ width: 300 }}
                  onChange={(e) => setCancelDescription(e.target.value)}
                />
              </form>
            )}
        </DialogContent>
        <DialogActions>
          <MUButton onClick={() => setCancelItem(null)} color="primary">
            Үгүй
          </MUButton>
          <MUButton onClick={handleCancelSubmit} color="primary" autoFocus>
            Тийм
          </MUButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default OrderList;
