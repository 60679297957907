import React from 'react';
import Button from '@material-ui/core/Button';

const EditButton = ({ item, disabled, size = 'medium', onClickEdit }) => {
  return (
    <div style={{ marginTop: 10, marginBottom: 0 }}>
      <Button
        size={size}
        disabled={disabled}
        variant="contained"
        onClick={() => onClickEdit(item)}
        style={{
          backgroundColor: disabled ? '#ccc' : '#77c982',
          color: '#fff',
        }}
      >
        Засах
      </Button>
    </div>
  );
};

export default EditButton;
