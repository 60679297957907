import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
// import * as colors from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import Chat from 'twilio-chat';

import Api from '../constants/Api';
import httpClient from '../providers/httpClient';
import * as initActions from '../actions/main';
import TaxiStatusColor from '../constants/TaxiStatusColor';
import OrderListItem from '../components/orders/OrderListItem';
import ChatApp from '../components/ChatApp';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(2),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: '#fff',
//   },
// });

const mystyles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    // height: '100%',
    overflowY: 'scroll',
    marginTop: 10,
    marginBottom: 10,
  },
  box: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#f8f8f8',
    maringLeft: 5,
    marginRight: 5,
  },
  title: { flex: 1, display: 'flex', color: '#888' },
  value: { flex: 1, display: 'flex', color: '#000' },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    // height: 450,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DriverDetailDialog = ({ item, handleClose, onClickPlace }) => {
  const dispatch = useDispatch();

  //-------------TWILIO CHAT---------------
  const [token, setToken] = useState('');
  const [chatReady, setChatReady] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(true);
  const [taxiItem, setTaxiItem] = useState(null);

  const taxis = useSelector((state) => state.main.taxis);
  const [channelName] = useState(item.id);

  const [chatDone, setChatDone] = useState(false);

  useEffect(() => {
    const { id } = item;

    const taxisMap = {};
    taxis.forEach((_item) => {
      taxisMap[_item.id] = _item;
    });

    setTaxiItem(taxisMap[id]);
  }, [item, taxis]);

  const chatClient = useRef(null);
  const channel = useRef(null);

  const getToken = async () => {
    try {
      const { json: data } = await httpClient(`${Api.chatToken}`, {
        method: 'POST',
      });
      setToken(data.token);
    } catch (e) { }
  };

  useEffect(() => {
    if (token) {
      initChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const initChat = () => {
    chatClient.current = new Chat(token);
    chatClient.current.initialize().then(clientInitiated);
  };

  const clientInitiated = () => {
    setIsChatLoading(true);
    setChatReady(true);
  };

  useEffect(() => {
    if (chatReady) {
      chatClient.current
        .getChannelByUniqueName(channelName)
        .then((_channel) => {
          if (_channel) {
            return (channel.current = _channel);
          }
        })
        .catch((err) => {
          if (err.body.code === 50300) {
            return chatClient.current.createChannel({
              uniqueName: channelName,
            });
          }
        })
        .then((_channel) => {
          channel.current = _channel;
          window.channel = _channel;
          return channel.current.join();
        })
        .catch((err) => {
          if (err.code !== 50404) {
            console.log('code', err.code);
            console.log('keys', Object.keys(err));
            console.log('err', err);
          }
        })
        .then(() => {
          // channel.current.getMessages().then(messagesLoaded);
          // channel.current.on('messageAdded', messageAdded);
          setIsChatLoading(false);
          setChatDone(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatReady]);

  //-------------TWILIO CHAT---------------

  useEffect(() => {
    if (item) {
      const getTaxis = async () => {
        try {
          await dispatch(initActions.getTaxiInfo(item.id));
        } catch (e) {
          console.log('err', e);
        }
      };
      getTaxis();
      getToken();
    }
  }, [dispatch, item]);
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={taxiItem ? true : false}
    >
      {/* <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{ backgroundColor: '#fff', color: '#000' }}
      >
        {taxiItem && taxiItem.driver && taxiItem.driver.name + ' ' + taxiItem.vehicleNumber}
      </DialogTitle> */}
      {taxiItem && (
        <DialogContent dividers>
          <Typography variant="h6">Жолооч</Typography>

          <div style={mystyles.row}>
            <div style={mystyles.box}>
              <div style={mystyles.title}>Нэр:</div>
              <div style={mystyles.value}>
                <a href={`#/taxis/${taxiItem._id}`}>{taxiItem.driver.name}</a>
              </div>
            </div>

            <div style={mystyles.box}>
              <div style={mystyles.title}>Утасны дугаар:</div>
              <div style={mystyles.value}>{taxiItem.driver.tel}</div>
            </div>

            <div style={mystyles.box}>
              <div style={mystyles.title}>Улсын дугаар:</div>
              <div style={mystyles.value}>{taxiItem.vehicleNumber}</div>
            </div>

            <div style={mystyles.box}>
              <div style={mystyles.title}>Төлөв:</div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  color: TaxiStatusColor[taxiItem.status],
                }}
              >
                {taxiItem.status}
              </div>
            </div>
          </div>

          <div style={mystyles.row}>
            <div style={mystyles.box}>
              <div style={mystyles.title}>Хүйс:</div>
              <div style={mystyles.value}>{taxiItem.driver.gender}</div>
            </div>

            <div style={mystyles.box}>
              <div style={mystyles.title}>Төрсөн он:</div>
              <div style={mystyles.value}>{taxiItem.driver.birthYear}</div>
            </div>

            <div style={mystyles.box}>
              <div style={mystyles.title}>Эрх:</div>
              <div style={mystyles.value}>{taxiItem.ticket}</div>
            </div>
          </div>

          {taxiItem.currentOrder && (
            <Typography variant="h6">Захиалга</Typography>
          )}

          {taxiItem.currentOrder && (
            <OrderListItem
              item={{ ...taxiItem.currentOrder, taxi: taxiItem }}
              disableClickVehicle={true}
              onClickPlace={onClickPlace}
            />
          )}

          <div style={{ height: 20 }}></div>
          <Paper
            // fullWidth={true}
            className={classes.paper}
            // TransitionComponent={Transition}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            // open={open}
            style={{
              maxWidth: 'auto',
              maxHeight: '350px',
              alignItems: 'center',
              overflow: 'auto',
            }}
          >
            <GridList cellHeight="auto" className={classes.gridList} cols={1}>
              <GridListTile cols={1}>
                <Typography variant="h6">Messages</Typography>
                 <ChatApp
                  channel={channel}
                  // messages={messages}
                  isLoading={isChatLoading}
                  chatClient={chatClient}
                  chatDone={chatDone}
                />
              </GridListTile>
            </GridList>
          </Paper>
        </DialogContent>
      )}

      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          ХААХ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DriverDetailDialog;
