// in src/createAdminStore.js
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { adminReducer, USER_LOGOUT, adminSaga } from 'react-admin';
import ReduxThunk from 'redux-thunk';

import { createHashHistory as createHistory } from 'history';

import mainReducer from './reducers/main';
import ordersReducer from './reducers/orders';
import orderRadiusReducer from './reducers/orderRadius';
import directionsReducer from './reducers/directions';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

const history = createHistory({ basename: process.env.PUBLIC_URL });

const createAdminStore = () => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    main: mainReducer,
    orders: ordersReducer,
    orderRadius: orderRadiusReducer,
    directions: directionsReducer,
  });
  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork)
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
        ReduxThunk
      )
      // add your own enhancers here
    )
  );
  sagaMiddleware.run(saga);
  return store;
};

const store = createAdminStore();
export default store;
