import React, { useState, useEffect, useRef, useCallback } from 'react';
import { stringify } from 'query-string';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';

import Api from '../constants/Api';

const TaxisAutocompleteInput = ({
  currentTaxi,
  setCurrentTaxi,
  setAllTaxis,
  variant = 'outlined',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const [taxis, setTaxis] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [, setSearching] = useState(false);

  const source = useRef(null);

  function getSource() {
    if (source.current == null) {
      const CancelToken = axios.CancelToken;
      source.current = CancelToken.source();
    }
    return source.current;
  }

  const getTaxis = useCallback(async () => {
    let api = search ? Api.taxis : Api.taxis;

    let query = search ? { q: search } : {};

    try {
      const url = `${api}?${stringify(
        query
      )}&_start=0&_end=400&_order=DESC&_sort=createdAt`;

      //Check if there are any previous pending requests
      if (typeof getSource() != typeof undefined) {
        getSource().cancel('Operation canceled due to new request.');
      }

      //new request
      source.current = null;

      const config = { cancelToken: getSource().token, headers: {} };
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      setLoading(true);

      const { data } = await axios.get(url, config);

      setTaxis(data.map((item, index) => ({ ...item, index })));

      setLoading(false);
    } catch (e) {
      console.log('err', e);
      setLoading(false);
    }
  }, [search]);
  
  useEffect(() => {
    if (taxis && taxis.length && setAllTaxis) {
      setAllTaxis(taxis);
    }
  }, [setAllTaxis, taxis])

  useEffect(() => {
    if (!search) {
      return;
    }
    getTaxis();
  }, [getTaxis, search]);

  useEffect(() => {
    getTaxis();
  }, [getTaxis]);

  useEffect(() => {
    setSearch(null);
  }, []);

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <Autocomplete
          open={open}
          // freeSolo
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={(option) => {
            if (option) {
              return `${option.driver.name ? option.driver.name + ' ' : ''}${option.vehicleNumber
                }`;
            } else {
              return '';
            }
          }}
          filterOptions={(x) => x}
          getOptionSelected={(option, value) => {
            return option.index === value.index;
          }}
          options={taxis}
          loading={loading}
          style={{ width: '100%' }}
          value={currentTaxi}
          onChange={(e, val) => {
            setCurrentTaxi(val);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Жолооч"
              variant={variant}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onFocus={() => {
                setSearching(true);
              }}
              onBlur={() => {
                //Check if there are any previous pending requests
                if (typeof getSource() != typeof undefined) {
                  getSource().cancel('Operation canceled due to new request.');
                }

                //new request
                source.current = null;

                setSearching(false);
              }}
              value={search}
            />
          )}
        />
      </div>
    </>
  );
};

export default TaxisAutocompleteInput;
