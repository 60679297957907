import React from 'react';
import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { CreateButton } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
);

const Empty = (props) => {
  const { emptyMessage = 'Үр дүн олдсонгүй', basePath } = props;
  const classes = useStyles(props);

  const inviteMessage = 'Шинээр нэмэх үү?';

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph>
          {emptyMessage}
        </Typography>
        <Typography variant="body1">{inviteMessage}</Typography>
      </div>
      <div className={classes.toolbar}>
        <CreateButton variant="contained" basePath={basePath} />
      </div>
    </>
  );
};

export default Empty;
