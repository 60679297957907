import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
});

const ListAvatar = (props) => {
  const classes = useStyles();

  const source = props.source;
  let src = null;
  if (source === 'driver.image') {
    src = props.record && props.record.driver ? props.record.driver.image : '';
  } else {
    src = props.record && props.record[source] ? props.record[source] : '';
  }
  const hasSrc = src && src.length > 0 ? true : false;

  return (
    <Avatar
      className={classes.avatar}
      src={`${
        hasSrc ? src : `${process.env.PUBLIC_URL}/assets/empty-image.png`
      }`}
    />
  );
};

export default ListAvatar;
