import React, { useState, useRef, useCallback, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { Title, SelectField } from 'react-admin';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import './MainMap.css';
import Grid from '@material-ui/core/Grid';
import LiveDrivers from '../components/LiveDrivers';
import Api from '../constants/Api';
import moment from 'moment';
import { startStream, useStream } from '../hooks/useStream';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import StopIcon from '@material-ui/icons/Stop';
import TaxisAutocompleteInput from '../components/TaxisAutocompleteInput';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    width: 1740 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  textField: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Replay = (props) => {
  const streamRef = useRef();
  const classes = useStyles();
  const googleMapRef = React.createRef();
  const [center, setCenter] = useState({ lat: 47.9216914, lng: 106.9184941 });
  useEffect(() => {
    const companyLocation = localStorage.getItem('companyLocation');
    if (companyLocation) {
      const json = JSON.parse(companyLocation)
      const cent = { lat: json.coordinates[1], lng: json.coordinates[0] }
      setCenter(cent);
    }
  }, []);
  const [zoom] = useState(14);
  const mainData = useRef("");
  const timer = useRef(null);
  const currentDate = useRef(moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'));
  const [taxis, setTaxis] = useState([]);
  const [fast, setFast] = useState(20);
  const markers = useRef([]);
  const [value, setValue] = React.useState(0);
  const [infoWindows] = useState([]);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'hour').toISOString());
  const [toDate, setToDate] = useState(moment().toISOString());
  const [allTaxis, setAllTaxis] = useState([]);
  const [currentDateIso, setCurrentDateIso] = useState(moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'));
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isDone, setDone] = useState(false);
  const [isPlay, setPlay] = useState(false);


  const onNext = useCallback(res => {
    if (res) {
      mainData.current = mainData.current ? (mainData.current + res) : res;

      setData(mainData.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTaxi = () => {
    const taxi = localStorage.getItem('driver_taxi')
      ? JSON.parse(localStorage.getItem('driver_taxi'))
      : null;
    if (taxi) {
      return taxi;
    } else {
      return null;
    }
  };
  const [currentTaxi, setCurrentTaxi] = useState(getTaxi());


  const onDone = useCallback(() => {
    setLoading(false);
    setDone(true);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const taxiById = (id) => {
    for (var i = 0; i < allTaxis.length; i++) {
      if (allTaxis[i]._id === id) {
        return allTaxis[i];
      }
    }
  };

  useEffect(() => {
    try {
      if (data && isDone) {
        const jsonData = JSON.parse(data);
        var carLocations = new Map();
        for (var i = 0; i < jsonData.length; i++) {
          const item = jsonData[i];
          if (carLocations[item.taxi]) {
            item.realTaxi = taxiById(item.taxi);
            carLocations[item.taxi].push(item);
          }
          else {
            carLocations[item.taxi] = [];
            item.realTaxi = taxiById(item.taxi);
            carLocations[item.taxi].push(item);
          }
        }
        setTaxis(carLocations);
      }
    }
    catch (e) {
      console.log('data', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDone])

  const close = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.abort();
    }
  }, []);

  useEffect(() => {
    console.log('fromDate', fromDate);
    console.log('toDate', toDate);
    console.log('diff', moment(toDate).diff(moment(fromDate), 'minutes'));
    if (onNext && onDone && allTaxis && allTaxis.length > 0 && moment(toDate).diff(moment(fromDate), 'minutes') <= 60 && moment(toDate).diff(moment(fromDate), 'minutes') !== 0) {
      if (streamRef.current) {
        streamRef.current.abort();
      }
      //Api.logs + '?from=2021-06-20T00:00:00.000Z&to=2021-06-20T00:18:00.000Z'
      streamRef.current = new AbortController();
      if (currentTaxi) {
        startStream(Api.logs + `?from=${fromDate}&to=${toDate}&taxi=${currentTaxi.id}`, {
          onNext: onNext,
          onDone: onDone,
          fetchParams: {
            signal: streamRef.current.signal
          }
        });
      }
      else {
        startStream(Api.logs + `?from=${fromDate}&to=${toDate}`, {
          onNext: onNext,
          onDone: onDone,
          fetchParams: {
            signal: streamRef.current.signal
          }
        });
      }
    }

    return () => {
      close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, allTaxis]);





  const handleGoogleMapApi = async ({ maps, map }) => {
    maps = googleMapRef.current.maps_;
    map = googleMapRef.current.map_;

    googleMapRef.current.maps_.event.addListener(
      map,
      'click',
      function (event) {
        infoWindows.forEach((item) => item.close());
        while (infoWindows.length) {
          infoWindows.pop();
        }
      }
    );

    googleMapRef.current.maps_.event.addListener(
      map,
      'dragstart',
      function (event) {
        infoWindows.forEach((item) => item.close());
        while (infoWindows.length) {
          infoWindows.pop();
        }
      }
    );
  };

  const renderMarker = (map, maps, marker, item) => {
    const googleMap = map;
    const MapEvent = maps.event;
    const MapInfoWindow = maps.InfoWindow;
    let statusDriver = null;
    if (item.taxiStatus === 'free') {
      statusDriver = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber}-green.png`;
    } else if (item.taxiStatus === 'busy') {
      statusDriver = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber}-red.png`;
    } else if (item.taxiStatus === 'inactive') {
      statusDriver = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber}-orange.png`;
    }
    marker.setIcon(statusDriver);
    var latlng = new maps.LatLng(item.location.coordinates[1], item.location.coordinates[0]);
    marker.setPosition(latlng);
    var infowindow = new MapInfoWindow({
      content: moment(item.createdAt).format('YYYY-MM-DDTHH:mm') + item.realTaxi.vehicleNumber,
    });
    MapEvent.addListener(marker, 'mouseover', function () {
      infoWindows.forEach((item) => item.close());
      while (infoWindows.length) {
        infoWindows.pop();
      }

      infowindow.open(googleMap, marker);
      infoWindows.push(infowindow);
    });
    MapEvent.addListener(marker, 'mouseout', function () {
      infoWindows.forEach((item) => item.close());
      while (infoWindows.length) {
        infoWindows.pop();
      }
    });
  }
  console.log('currentTaxi',);
  useEffect(() => {
    if (isPlay && googleMapRef.current) {
      var free = 0;
      var busy = 0;
      var inactive = 0;
      const maps = googleMapRef.current.maps_;
      const map = googleMapRef.current.map_;
      Object.keys(taxis).map((key, value) => {
        if (currentTaxi && currentTaxi._id === key) {
          for (var i = 0; i < taxis[key].length; i++) {
            const item = taxis[key][i];
            if (moment(item.createdAt).isSame(moment(currentDateIso), 'seconds') && item.realTaxi) {
              let icon;
              const status = item.taxiStatus;
              if (status === 'free') {
                icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber
                  }-green.png`;
              } else if (status === 'busy') {
                icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber
                  }-red.png`;
              } else if (status === 'inactive') {
                icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber
                  }-orange.png`;
              }
              if (markers.current[key]) {
                markers.current[key].map = map;
                renderMarker(map, maps, markers.current[key], item);
              }
              else {
                const marker = new maps.Marker({
                  position: {
                    lat: item.location.coordinates[1],
                    lng: item.location.coordinates[0],
                  },
                  icon: {
                    url: icon,
                    scaledSize: maps.Size(25, 25),
                  },
                  map: map,
                  title: item.realTaxi.vehicleNumber,
                  tracksViewChanges: false,
                });
                markers.current[key] = marker;
                renderMarker(map, maps, marker, item);
              }
              if (status === 'free') {
                free = free + 1;
              } else if (status === 'busy') {
                busy = busy + 1;
              } else if (status === 'inactive') {
                inactive = inactive + 1;
              }
            }
            else {
              const status = item.taxiStatus;
              // if (markers.current[key] && markers.current[key].map)
              //   if (moment.duration((moment(currentDateIso)).diff(moment(item.createdAt))).asSeconds() > 60) {
              //     markers.current[key].map = null;
              //     if (status === 'free') {
              //       free = free - 1 > 0 ? free - 1 : 0;
              //     } else if (status === 'busy') {
              //       busy = busy - 1 > 0 ? busy - 1 : 0;
              //     } else if (status === 'inactive') {
              //       inactive = inactive - 1 > 0 ? inactive - 1 : 0;
              //     }
              //   }
              //   else {
              //     markers.current[key].map = map;
              //     if (status === 'free') {
              //       free = free + 1;
              //     } else if (status === 'busy') {
              //       busy = busy + 1;
              //     } else if (status === 'inactive') {
              //       inactive = inactive + 1;
              //     }
              //   }
            }
            // console.log('status', free, busy, inactive);

          }
        }
        else {
          for (var i = 0; i < taxis[key].length; i++) {
            const item = taxis[key][i];
            if (moment(item.createdAt).isSame(moment(currentDateIso), 'seconds') && item.realTaxi) {
              let icon;
              const status = item.taxiStatus;
              if (status === 'free') {
                icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber
                  }-green.png`;
              } else if (status === 'busy') {
                icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber
                  }-red.png`;
              } else if (status === 'inactive') {
                icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.realTaxi.vehicleNumber
                  }-orange.png`;
              }
              if (markers.current[key]) {
                markers.current[key].map = map;
                renderMarker(map, maps, markers.current[key], item);
              }
              else {
                const marker = new maps.Marker({
                  position: {
                    lat: item.location.coordinates[1],
                    lng: item.location.coordinates[0],
                  },
                  icon: {
                    url: icon,
                    scaledSize: maps.Size(25, 25),
                  },
                  map: map,
                  title: item.realTaxi.vehicleNumber,
                  tracksViewChanges: false,
                });
                markers.current[key] = marker;
                renderMarker(map, maps, marker, item);
              }
              if (status === 'free') {
                free = free + 1;
              } else if (status === 'busy') {
                busy = busy + 1;
              } else if (status === 'inactive') {
                inactive = inactive + 1;
              }
            }
            else {
              const status = item.taxiStatus;
              // if (markers.current[key] && markers.current[key].map)
              //   if (moment.duration((moment(currentDateIso)).diff(moment(item.createdAt))).asSeconds() > 60) {
              //     markers.current[key].map = null;
              //     if (status === 'free') {
              //       free = free - 1 > 0 ? free - 1 : 0;
              //     } else if (status === 'busy') {
              //       busy = busy - 1 > 0 ? busy - 1 : 0;
              //     } else if (status === 'inactive') {
              //       inactive = inactive - 1 > 0 ? inactive - 1 : 0;
              //     }
              //   }
              //   else {
              //     markers.current[key].map = map;
              //     if (status === 'free') {
              //       free = free + 1;
              //     } else if (status === 'busy') {
              //       busy = busy + 1;
              //     } else if (status === 'inactive') {
              //       inactive = inactive + 1;
              //     }
              //   }
            }
            // console.log('status', free, busy, inactive);

          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlay, currentDateIso, googleMapRef, taxis])

  useEffect(() => {
    if (isPlay) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      timer.current = setInterval(
        () => {
          currentDate.current = moment(currentDate.current).add(fast, 'seconds').format("YYYY-MM-DD HH:mm:ss");
          if (moment(toDate).diff(moment(currentDate.current), 'seconds') > 0) {
            setCurrentDateIso(currentDate.current);
            setValue(moment.duration((moment(currentDate.current)).diff(moment(fromDate))).asMinutes());
          }
        }
        , 1000 / fast)
    }
    else {
      if (timer.current) {
        clearInterval(timer.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlay, fast])

  const handleSliderChange = (event, newValue) => {
    currentDate.current = moment(moment(fromDate) + newValue * 60 * 1000).format("YYYY-MM-DD HH:mm:ss");
    setValue(newValue);
    setPlay(true);
  };
  return (
    <Card
      style={{
        display: 'flex',
        flexdirection: 'row',
        overflow: 'scroll',
        height: 'calc((100vh - 80px))',
      }}
    >
      <Title title="Replay" />
      <div className="Map2">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAEBWB-b-YZ5gCMN3aZxtWZ-3huACGEglk' }}
          options={(maps) => {
            return {
              fullscreenControl: true,
              mapTypeControl: true,
            };
          }}
          tilt={60}
          ref={googleMapRef}
          defaultCenter={center}
          defaultZoom={zoom}
          distanceToMouse={() => { }}
          onGoogleApiLoaded={handleGoogleMapApi}
          yesIWantToUseGoogleMapApiInternals
        >

        </GoogleMapReact>
        {isLoading && <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: "center"
        }} >
          <CircularProgress /></div>}
        <div
          style={{
            position: 'absolute',
            left: 8,
            top: 8,
            zIndex: 1,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <LiveDrivers />
          <Card style={{ marginTop: 32, width: 320, justifyContent: 'center', alignItems: 'center' }}>
            <div className={classes.root} style={{ flexDirection: 'column' }}>

              <form className={classes.container} noValidate style={{ flexDirection: 'column', width: 140 }}>
                <TextField
                  id="fromDate"
                  type="datetime-local"
                  defaultValue={moment(fromDate).format('YYYY-MM-DDTHH:mm')}
                  className={classes.textField}
                  onChange={(data) => {
                    console.log('fromDate', data);
                    setFromDate(moment(data).toISOString());
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  style={{ marginTop: 16 }}
                  type="datetime-local"
                  defaultValue={moment(toDate).format('YYYY-MM-DDTHH:mm')}
                  className={classes.textField}
                  onChange={(data) => {
                    console.log('date', data);
                    setToDate(moment(data).toISOString());
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </form>
              <div style={{ marginRight: 30, width: 200 }}>
                <TaxisAutocompleteInput
                  setAllTaxis={setAllTaxis}
                  currentTaxi={currentTaxi}
                  setCurrentTaxi={(v) => {
                    setCurrentTaxi(v);
                    localStorage.setItem('driver_taxi', JSON.stringify(v));
                  }}
                  variant={'standard'}
                />
              </div>
            </div>
          </Card>
        </div>

      </div>

      {!isLoading && <div style={{
        position: 'absolute',
        left: 8,
        bottom: 0,
        right: 16,
        zIndex: 1,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }} >
        <Card>
          <div style={{
            display: 'flex',
            flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 16,
          }}>
            {moment(currentDateIso).format('YYYY-MM-DD HH:mm:ss')}  {fast}x
          </div>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            style={{ marginLeft: 16, marginRight: 16, }}
            max={moment.duration((moment(toDate)).diff(moment(fromDate))).asMinutes()}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            {/* <IconButton color="primary" aria-label="upload picture" component="span">
              <FastRewindIcon />
            </IconButton> */}
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
              currentDate.current = moment(fromDate).format("YYYY-MM-DD HH:mm:ss");
              setValue(moment.duration((moment(currentDate.current)).diff(moment(fromDate))).asMinutes());
              setPlay(false);
              setFast(20);
              if (googleMapRef.current) {
                if (googleMapRef.current.maps_) {
                  googleMapRef.current.maps_.clear();
                }
                if (googleMapRef.current.map_) {
                  googleMapRef.current.map_.clear();
                }
              }
            }}>
              <StopIcon />
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
              setPlay(!isPlay);
            }}>
              {isPlay ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => {
              if (fast < 640) {
                setFast(fast * 2);
              }
              else {
                setFast(20);
              }
            }}>
              <FastForwardIcon />
            </IconButton>
          </div>
        </Card>
      </div>
      }
    </Card >
  );
};

export default Replay;
