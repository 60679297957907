import React from 'react';
import { TextInput, required } from 'react-admin';
import { Box } from '@material-ui/core';

const MyForm = () => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={1} mr={{ md: 0, lg: '1em' }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="name" validate={[required()]} fullWidth />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyForm;
