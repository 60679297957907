import React from 'react';
import { Edit, SimpleForm, FormDataConsumer } from 'react-admin';

import MyForm from './MyForm';
import MyValidation from './MyValidation';

export default (props) => {
  return (
    <Edit title={'Хэрэглэгч засах'} {...props}>
      <SimpleForm validate={MyValidation}>
        <FormDataConsumer>
          {(formDataProps) => <MyForm {...formDataProps} isEditForm={true} />}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
