import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDataProvider, downloadCSV } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';

import { Button, Paper } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment';
import jsonExport from 'jsonexport/dist';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import TaxisAutocompleteInput from '../../components/TaxisAutocompleteInput';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import DetailsIcon from '@material-ui/icons/Details';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';

moment.locale('mn', {
  relativeTime: {
    s: '1 секунд',
    m: '1 минут',
    mm: '%d минут',
    h: '1 цаг',
    hh: '%d цаг',
    d: '1 өдөр',
    dd: '%d өдөр',
    M: '1 сар',
    MM: '%d сар',
    y: '1 жил',
    yy: '%d жил',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
    width: '95%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    color: 'white',
    top: 20,
    width: 1,
  },
}));
const status = (item, key) => {
  var count = 0;
  if (key === 'all') {
    return (
      status(item, 'ended') +
      status(item, 'driver-canceled') +
      status(item, 'admin-canceled') +
      status(item, 'canceled')
    );
  }
  item.status.forEach((data) => {
    if (data.status === key) {
      count = data.count;
    }
  });
  return count;
};
function descendingComparator(a, b, orderBy) {
  if (status(b, orderBy) < status(a, orderBy)) {
    return -1;
  }
  if (status(b, orderBy) > status(a, orderBy)) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: 'number',
    numeric: false,
    disablePadding: true,
    label: 'Таксины дугаар',
  },
  { id: 'name', numeric: false, disablePadding: true, label: 'Нэр' },
  { id: 'shifts', numeric: true, disablePadding: false, label: 'Shifts' },
  { id: 'duration', numeric: true, disablePadding: false, label: 'Хугацаа' },
  { id: 'started', numeric: true, disablePadding: false, label: 'Эхэлсэн' },
  { id: 'end', numeric: true, disablePadding: false, label: 'Дууссан' },
  { id: 'ended', numeric: true, disablePadding: false, label: 'Амжилттай' },
  {
    id: 'driver-canceled',
    numeric: true,
    disablePadding: false,
    label: 'Жолооч цуцалсан',
  },
  {
    id: 'canceled',
    numeric: true,
    disablePadding: false,
    label: 'Хэрэглэгч цуцалсан',
  },
  {
    id: 'admin-canceled',
    numeric: true,
    disablePadding: false,
    label: 'Админ цуцалсан',
  },
  { id: 'sumCount', numeric: true, disablePadding: false, label: 'Нийт' },
  {
    id: 'cost',
    numeric: true,
    disablePadding: false,
    label: 'Нийт дүн',
  },
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'cost' ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={
                headCell.id === 'number' ? null : createSortHandler(headCell.id)
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default function DriverReport(filter) {
  var allEnded = 0;
  var allDriverCanceled = 0;
  var allCanceled = 0;
  var allAdminCanceled = 0;
  var all = 0;
  var allCost = 0;

  const getFrom = () => {
    const from = localStorage.getItem('driver_from');
    if (from) {
      return moment(from).format('YYYY-MM-DDTHH:mm');
    } else {
      return moment(new Date()).subtract(1, 'days').format('YYYY-MM-DDTHH:mm');
    }
  };
  const getTo = () => {
    const to = localStorage.getItem('driver_to');
    if (to) {
      return moment(to).format('YYYY-MM-DDTHH:mm');
    } else {
      return moment(new Date()).format('YYYY-MM-DDTHH:mm');
    }
  };
  const getTaxi = () => {
    const taxi = localStorage.getItem('driver_taxi')
      ? JSON.parse(localStorage.getItem('driver_taxi'))
      : null;
    if (taxi) {
      return taxi;
    } else {
      return null;
    }
  };

  const [open, setOpen] = React.useState(false);
  const [openTicket, setOpenTicket] = React.useState(false);
  const [selectedTaxi, setTaxi] = React.useState(null);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('ended');
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const [data, setData] = React.useState([]);
  const [dataMap, setDataMap] = React.useState({});
  const [allShifts, setAllShifts] = React.useState([]);
  const [allTickets, setAllTickets] = React.useState([]);

  const [fromDate, setFromDate] = React.useState(getFrom());
  const [toDate, setToDate] = React.useState(getTo());

  const [fromISODate, setFromISODate] = React.useState(
    moment(getFrom()).toISOString()
  );
  const [toISODate, setISOToDate] = React.useState(
    moment(getTo()).toISOString()
  );
  const [currentTaxi, setCurrentTaxi] = useState(getTaxi());

  const handleClickOpen = (item) => {
    setTaxi(item);
    setOpen(true);
  };

  const handleClose = () => {
    setTaxi(null);
    setOpen(false);
    // setShifts([]);
  };
  const handleClickOpenTicket = (item) => {
    setTaxi(item);
    setOpenTicket(true);
  };

  const handleCloseTicket = () => {
    setOpenTicket(false);
    setTaxi(null);
  };
  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [source, setSource] = React.useState('all');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const list = async () => {
      try {
        const { data: dataRequested } = await dataProvider.getList(
          'orders/reportByTaxi',
          {
            from: fromISODate,
            to: toISODate,
            pagination: {
              page: 1,
              end: 25,
              start: 0,
              perPage: 1000,
            },
            sort: {
              field: 'createdAt',
              _order: 'DESC',
            },
            source: source === 'all' ? undefined : source,
            taxi: currentTaxi ? currentTaxi._id : undefined,
          }
        );
        const map = {};
        dataRequested.forEach((item) => {
          map[item._id] = item;
        });
        setDataMap(map);
        setData(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    };
    list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, source, currentTaxi]);

  useEffect(() => {
    const list = async () => {
      try {
        const { data: dataRequested } = await dataProvider.getList('shifts', {
          from: fromISODate,
          to: toISODate,
          pagination: {
            page: 1,
            end: 25,
            start: 0,
            perPage: 1000,
          },
          sort: {
            field: 'createdAt',
            _order: 'DESC',
          },
          taxi: currentTaxi ? currentTaxi._id : undefined,
        });
        setAllShifts(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    };
    list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaxi, source, fromISODate, toISODate]);

  useEffect(() => {
    const list = async () => {
      try {
        const { data: dataRequested } = await dataProvider.getList('tickets', {
          from: fromISODate,
          to: toISODate,
          pagination: {
            page: 1,
            end: 25,
            start: 0,
            perPage: 1000,
          },
          sort: {
            field: 'createdAt',
            order: 'DESC',
          },
          taxi: currentTaxi ? currentTaxi._id : undefined,
        });
        setAllTickets(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    };
    list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaxi, source, fromISODate, toISODate]);

  const exporter = () => {
    const postsForExport = data.map((item) => {
      const postForExport = {};
      postForExport.number = item.vehicleNumber;
      postForExport.name = item.driver.name;
      postForExport.shifts = item.shifts ? item.shifts.count : 0;
      postForExport.duration =
        (item.shifts.duration / 1000 / 60 / 60).toFixed(0) +
        ':' +
        ((item.shifts.duration / 1000 / 60) % 60).toFixed(0);
      postForExport.started = item.shifts.first
        ? moment(item.shifts.first.startedAt).format('YYYY-MM-DD HH:mm')
        : '-';
      postForExport.end =
        item.shifts.last && item.shifts.last.endedAt
          ? moment(item.shifts.last.endedAt).format('YYYY-MM-DD HH:mm')
          : '-';
      postForExport.success = status(item, 'ended');
      postForExport.driverCanceled = status(item, 'driver-canceled');
      postForExport.canceled = status(item, 'canceled');
      postForExport.adminCanceled = status(item, 'admin-canceled');
      postForExport.all = status(item, 'all');
      postForExport.cost = item.cost;
      return postForExport;
    });
    jsonExport(
      postsForExport,
      {
        headers: [
          'number',
          'name',
          'shifts',
          'duration',
          'started',
          'end',
          'success',
          'driverCanceled',
          'canceled',
          'adminCanceled',
          'all',
          'cost',
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, 'driver'); // download as 'posts.csv` file
      }
    );
  };

  const shiftExporter = () => {
    console.log('allShifts', allShifts);
    const postsForExport = allShifts
      .filter((item) => {
        if (dataMap[item.taxi]) {
          return true;
        } else {
          return false;
        }
      })
      .map((item) => {
        const postForExport = {};
        postForExport.driverName = dataMap[item.taxi].driver.name;
        postForExport.vehicleNumber = dataMap[item.taxi].vehicleNumber;
        postForExport.startedAt = moment(item.startedAt).format(
          'YYYY-MM-DD HH:mm'
        );
        postForExport.endedAt = moment(item.endedAt).format('YYYY-MM-DD HH:mm');
        postForExport.duration = moment(item.endedAt).from(
          moment(item.startedAt),
          true
        );
        postForExport.distance = `${(item.distance / 1000).toFixed(2)}км`;
        postForExport.accepted = item.acceptedOrderCount;
        postForExport.rejected = item.rejectedOrderCount;
        postForExport.sum = item.rejectedOrderCount + item.acceptedOrderCount;

        return postForExport;
      });
    jsonExport(
      postsForExport,
      {
        headers: [
          'driverName',
          'vehicleNumber',
          'startedAt',
          'endedAt',
          'duration',
          'distance',
          'accepted',
          'rejected',
          'sum',
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, 'driver_shifts'); // download as 'posts.csv` file
      }
    );
  };

  const ticketExporter = () => {
    const postsForExport = allTickets
      .filter((item) => {
        if (dataMap[item.taxi]) {
          return true;
        } else {
          return false;
        }
      })
      .map((item) => {
        const postForExport = {};
        if (dataMap[item.taxi]) {
          postForExport.driverName = dataMap[item.taxi].driver.name;
          postForExport.vehicleNumber = dataMap[item.taxi].vehicleNumber;
        } else {
          console.log('item.taxi', item.taxi);
        }
        postForExport.createdAt = moment(item.createdAt).format(
          'YYYY-MM-DD HH:mm'
        );
        postForExport.total = item.total;
        return postForExport;
      });
    jsonExport(
      postsForExport,
      {
        headers: ['driverName', 'vehicleNumber', 'createdAt', 'total'], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, 'driver_tickets'); // download as 'posts.csv` file
      }
    );
  };

  const fromChange = (data) => {
    setFromDate(moment(data.target.value).format('YYYY-MM-DDTHH:mm'));
    setFromISODate(moment(data.target.value).toISOString());

    localStorage.setItem(
      'driver_from',
      moment(data.target.value).toISOString()
    );
  };

  const toChange = (data) => {
    setToDate(moment(data.target.value).format('YYYY-MM-DDTHH:mm'));
    setISOToDate(moment(data.target.value).toISOString());

    localStorage.setItem('driver_to', moment(data.target.value).toISOString());
  };

  const currencyFormat = (num) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '₮';
  };

  let history = useHistory();

  const redirect = (taxiId) => {
    const filter = {};
    filter.taxi = taxiId;
    filter.from = fromISODate;
    filter.to = toISODate;
    return () => {
      history.push(`/orders?filter=${JSON.stringify(filter)}`);
    };
  };

  return (
    <Paper style={{ padding: 32 }}>
      <form
        className={classes.container}
        noValidate
        style={{ flex: 1, flexDirection: 'row', display: 'flex' }}
      >
        <FormControl style={{ marginRight: 30, width: 200 }}>
          <InputLabel size="small" style={{}}>
            Төрөл
          </InputLabel>
          <Select
            value={source}
            onChange={(e) => {
              setSource(e.target.value);
            }}
            size="small"
          >
            {[
              { key: 'all', name: 'Бүгд' },
              { key: 'admin', name: 'Дис' },
              { key: 'user', name: 'Апп' },
              { key: 'driver', name: 'Замаас авсан' },
            ].map((item) => {
              return (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div style={{ marginRight: 30, width: 200 }}>
          <TaxisAutocompleteInput
            currentTaxi={currentTaxi}
            setCurrentTaxi={(v) => {
              setCurrentTaxi(v);
              localStorage.setItem('driver_taxi', JSON.stringify(v));
            }}
            variant={'standard'}
          />
        </div>
        <TextField
          id="fromDate"
          label="Эхлэх огноо"
          type="datetime-local"
          defaultValue={fromDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={fromChange}
        />
        <TextField
          id="toDate"
          label="Дуусах огноо"
          type="datetime-local"
          defaultValue={toDate}
          style={{ marginLeft: 32 }}
          className={classes.textField}
          onChange={toChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          style={{
            marginLeft: 32,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color="primary"
          onClick={() => {
            exporter();
          }}
        >
          Export
        </Button>

        <Button
          style={{
            marginLeft: 32,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color="primary"
          onClick={() => {
            if (allShifts.length > 0) {
              shiftExporter();
            } else {
              alert('Татаж дуусаагүй байна!');
            }
          }}
        >
          Export Shift
        </Button>

        <Button
          style={{
            marginLeft: 32,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          color="primary"
          onClick={() => {
            if (allTickets.length > 0) {
              ticketExporter();
            } else {
              alert('Татаж дуусаагүй байна!');
            }
          }}
        >
          Export Tickets
        </Button>
      </form>
      <TableContainer className={classes.container} style={{ marginTop: 32 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (item, index) => {
                allEnded = allEnded + status(item, 'ended');
                allDriverCanceled =
                  allDriverCanceled + status(item, 'driver-canceled');
                allCanceled = allCanceled + status(item, 'canceled');
                allAdminCanceled =
                  allAdminCanceled + status(item, 'admin-canceled');
                all = all + status(item, 'all');
                allCost = allCost + item.cost;
                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={item.id}
                    // onClick={redirect(item.id)}
                  >
                    <StyledTableCell align={'left'}>
                      {item.vehicleNumber}(
                      {
                        allTickets.filter((_item) => _item.taxi === item._id)
                          .length
                      }
                      )
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => {
                          handleClickOpenTicket(item);
                        }}
                      >
                        <InfoOutlined />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align={'left'}>
                      {item.driver.name}
                    </StyledTableCell>
                    <StyledTableCell align={'right'}>
                      {item.shifts ? item.shifts.count : 0}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => {
                          handleClickOpen(item);
                        }}
                      >
                        <InfoOutlined />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {(item.shifts.duration / 1000 / 60 / 60).toFixed(0) +
                        ':' +
                        ((item.shifts.duration / 1000 / 60) % 60).toFixed(0)}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {item.shifts.first
                        ? moment(item.shifts.first.startedAt).format(
                            'YYYY-MM-DD HH:mm'
                          )
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {item.shifts.last && item.shifts.last.endedAt
                        ? moment(item.shifts.last.endedAt).format(
                            'YYYY-MM-DD HH:mm'
                          )
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'ended')}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'driver-canceled')}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'canceled')}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'admin-canceled')}
                    </StyledTableCell>
                    <StyledTableCell align={'center'}>
                      {status(item, 'all')}
                    </StyledTableCell>
                    <StyledTableCell align={'right'}>
                      {currencyFormat(item.cost)}
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={redirect(item.id)}
                      >
                        <DetailsIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              }
            )}
            <StyledTableRow hover tabIndex={-1} key={'all'}>
              <StyledTableCell align={'left'}></StyledTableCell>
              <StyledTableCell align={'left'}></StyledTableCell>
              <StyledTableCell align={'center'}></StyledTableCell>
              <StyledTableCell align={'center'}></StyledTableCell>
              <StyledTableCell align={'center'}></StyledTableCell>
              <StyledTableCell align={'center'}></StyledTableCell>

              <StyledTableCell align={'center'}>{allEnded}</StyledTableCell>
              <StyledTableCell align={'center'}>
                {allDriverCanceled}
              </StyledTableCell>
              <StyledTableCell align={'center'}>{allCanceled}</StyledTableCell>
              <StyledTableCell align={'center'}>
                {allAdminCanceled}
              </StyledTableCell>
              <StyledTableCell align={'center'}>{all}</StyledTableCell>
              <StyledTableCell align={'center'}>
                {currencyFormat(allCost)}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={!!selectedTaxi && open}
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {selectedTaxi && selectedTaxi.vehicleNumber}{' '}
          {selectedTaxi && selectedTaxi.driver.name} shifts
        </DialogTitle>
        <DialogContent dividers={true}>
          <TableContainer
            className={classes.container}
            style={{ marginTop: 0 }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align={'center'}>Ажилд гарсан цаг</TableCell>
                  <TableCell align={'center'}>Ажил дууссан цаг</TableCell>
                  <TableCell align={'center'}>Ажилласан цаг</TableCell>
                  <TableCell align={'center'}>Явсан зам</TableCell>
                  <TableCell align={'center'}>Зөвшөөрсөн</TableCell>
                  <TableCell align={'center'}>Татгалзсан</TableCell>
                  <TableCell align={'center'}>Нийт</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedTaxi &&
                  allShifts
                    .filter((item) => item.taxi === selectedTaxi._id)
                    .map((item, index) => {
                      return (
                        <StyledTableRow hover tabIndex={-1} key={item.id}>
                          <StyledTableCell align={'center'}>
                            {moment(item.startedAt).format('YYYY-MM-DD HH:mm')}
                          </StyledTableCell>
                          <StyledTableCell align={'center'}>
                            {moment(item.endedAt).format('YYYY-MM-DD HH:mm')}
                          </StyledTableCell>
                          <StyledTableCell align={'center'}>
                            {moment(item.endedAt).from(
                              moment(item.startedAt),
                              true
                            )}
                          </StyledTableCell>
                          <StyledTableCell align={'center'}>
                            {(item.distance / 1000).toFixed(2)}км
                          </StyledTableCell>
                          <StyledTableCell align={'center'}>
                            {item.acceptedOrderCount}
                          </StyledTableCell>
                          <StyledTableCell align={'center'}>
                            {item.rejectedOrderCount}
                          </StyledTableCell>
                          <StyledTableCell align={'right'}>
                            {item.rejectedOrderCount + item.acceptedOrderCount}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Хаах
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!selectedTaxi && openTicket}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleCloseTicket}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {selectedTaxi && selectedTaxi.vehicleNumber}{' '}
          {selectedTaxi && selectedTaxi.driver.name} хэтэвч
        </DialogTitle>
        <DialogContent dividers={true}>
          <TableContainer
            className={classes.container}
            style={{ marginTop: 0 }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align={'center'}>Огноо</TableCell>
                  <TableCell align={'center'}>Нэмэгдэл</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTickets.length > 0 ? (
                  selectedTaxi &&
                  allTickets
                    .filter((item) => item.taxi === selectedTaxi._id)
                    .map((item, index) => {
                      if (item.total > 0) {
                        return (
                          <StyledTableRow hover tabIndex={-1} key={item.id}>
                            <StyledTableCell align={'center'}>
                              {moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm'
                              )}
                            </StyledTableCell>
                            <StyledTableCell align={'center'}>
                              {item.total}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      } else {
                        return null;
                      }
                    })
                ) : (
                  <div
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      padding: 16,
                    }}
                  >
                    Өгөгдөл олдсонгүй
                  </div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTicket} color="primary">
            Хаах
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
