const host = process.env.NODE_ENV === 'production' ? 'https://ezcab.mn' : '';

export default {
  // host: 'http://10.201.187.144:8002',
  // host: 'https://deepmedia.mn',
  base: `${host}/admin-api/v1`,
  init: `${host}/admin-api/v1/init`,
  taxisLive: `${host}/admin-api/v1/taxis/live`,
  taxis: `${host}/admin-api/v1/taxis`,
  places: `${host}/admin-api/v1/places`,
  placesNearby: `${host}/admin-api/v1/places/nearby`,
  placesTextSearch: `${host}/admin-api/v1/places/textSearch`,
  stats: `${host}/admin-api/v1/stats`,
  auth: `${host}/admin-api/v1/auth`,
  logs: `${host}/admin-api/v1/logs`,
  orders: `${host}/admin-api/v1/orders`,
  ordersLive: `${host}/admin-api/v1/orders/live`,
  orderStatus: (orderId) => `${host}/admin-api/v1/orders/${orderId}/cancel`,
  companies: `${host}/admin-api/v1/companies`,

  revokeDevice: (taxiId) => `${host}/admin-api/v1/taxis/${taxiId}/revokeDevice`,
  fix: (taxiId) => `${host}/admin-api/v1/taxis/${taxiId}/fix`,
  chatToken: `${host}/admin-api/v1/chats/token`,
  taxisDisable: (taxiId) => `${host}/admin-api/v1/taxis/${taxiId}/disable`,
  taxisEnable: (taxiId) => `${host}/admin-api/v1/taxis/${taxiId}/enable`,
  admins: `${host}/admin-api/v1/admins`,
  users: `${host}/admin-api/v1/users`,

  autoComplete: `${host}/admin-api/v1/places/autocomplete`,
  placeGet: (placeId) => `${host}/admin-api/v1/places/googlePlace/${placeId}`,
  password: `${host}/admin-api/v1/admins/me/password`,
  key: `${host}/admin-api/v1/admins/me/key`,
  counts: `${host}/v1/orders/counts`,

  taxiExpire: (taxiId) => `${host}/admin-api/v1/taxis/${taxiId}/expire`,
  taxisBankAccount: (taxiId) =>
    `${host}/admin-api/v1/taxis/${taxiId}/bankAccount`,
};
