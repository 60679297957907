import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import MyForm from './MyForm';

export default (props) => (
  <Edit title={'Байршил засах'} {...props}>
    <SimpleForm>
      <MyForm />
    </SimpleForm>
  </Edit>
);
