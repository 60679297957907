import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import SocketContext from '../../socket.io/socket-context';
import * as ordersActions from '../../actions/orders';
import OrderList from './OrderList';
import { useNotify } from 'react-admin';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minWidth: '100%', // a number of your choice
    width: '100%',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
    width: '100%',
  },
  button: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function OrderTabList({ onClickVehicle, onClickPlace }) {
  const notify = useNotify();
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentSegmentIndex = useSelector(
    (state) => state.orders.currentSegmentIndex
  );

  const ordersBotCanceledCount = useSelector((state) =>
    state.orders.ordersBotCanceled ? state.orders.ordersBotCanceled.length : 0
  );
  const ordersRequestedCount = useSelector(
    (state) => state.orders.ordersRequestedCount
  );
  const ordersAcceptedCount = useSelector(
    (state) => state.orders.ordersAcceptedCount
  );

  const ordersBotCanceledFetched = useSelector(
    (state) => state.orders.ordersBotCanceledFetched
  );
  const ordersRequestedFetched = useSelector(
    (state) => state.orders.ordersRequestedFetched
  );
  const ordersAcceptedFetched = useSelector(
    (state) => state.orders.ordersAcceptedFetched
  );

  const getOrders = useCallback(async () => {
    try {
      await dispatch(
        ordersActions.getOrdersLive({ startIndex: 0, stopIndex: 1000 })
      );
    } catch (e) {
      console.log('e', e);
      notify(e.message, 'error');
    }
  }, [dispatch, notify]);

  useEffect(() => {
    if (currentSegmentIndex === 0 && !ordersBotCanceledFetched) {
      getOrders();
    } else if (currentSegmentIndex === 1 && !ordersRequestedFetched) {
      getOrders();
    } else if (currentSegmentIndex === 2 && !ordersAcceptedFetched) {
      getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSegmentIndex]);

  useEffect(() => {
    if (ordersBotCanceledFetched) {
      if (!socket.connected) {
        console.log('socket.open()');
        socket.open();
      }
    }
  }, [ordersBotCanceledFetched, socket]);

  return (
    <div
      className={classes.root}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <AppBar position="static" color="default">
        <Tabs
          value={currentSegmentIndex}
          onChange={(e, value) =>
            dispatch(ordersActions.setSegmentIndex(value))
          }
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label={`Зөвшөөрөөгүй (${ordersBotCanceledCount})`}
            {...a11yProps(1)}
            style={{ minWidth: '10%', fontSize: 10 }}
          />
          <Tab
            label={`Захиалгууд (${ordersRequestedCount})`}
            {...a11yProps(1)}
            style={{ minWidth: '10%', fontSize: 10 }}
          />
          <Tab
            label={`Зөвшөөрсөн (${ordersAcceptedCount})`}
            {...a11yProps(1)}
            style={{ minWidth: '10%', fontSize: 10 }}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={currentSegmentIndex} index={0}>
        <OrderList
          type={'bot-canceled'}
          onClickVehicle={onClickVehicle}
          onClickPlace={onClickPlace}
        />
      </TabPanel>
      <TabPanel value={currentSegmentIndex} index={1}>
        <OrderList
          type={'requested'}
          onClickVehicle={onClickVehicle}
          onClickPlace={onClickPlace}
        />
      </TabPanel>
      <TabPanel value={currentSegmentIndex} index={2}>
        <OrderList
          type={'accepted'}
          onClickVehicle={onClickVehicle}
          onClickPlace={onClickPlace}
        />
      </TabPanel>
    </div>
  );
}
