import React from 'react';
import { TextInput } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import OrderLogs from './OrderLogs';
import { useForm } from 'react-final-form';

const Detail = () => {
  const form = useForm();
  return (
    <Box flex={1} ml="1em">
      <Typography variant="h6" gutterBottom>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput label="Зорчигч" source="tel" disabled={true} fullWidth />
          </Box>
        </Box>
        Захиалсан түүх
        <OrderLogs user={form.getState().values._id}></OrderLogs>
      </Typography>
    </Box>
  );
};

export default Detail;
