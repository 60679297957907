import List from "./List";
import icon from "@material-ui/icons/Home";
import Edit from "./Edit";

export default {
  name: "users",
  list: List,
  icon: icon,
  edit: Edit,
};
