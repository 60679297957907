export const roadFilter = (order) => {
  if (!order || !order.road || !order.road.paths || !order.road.paths.length) {
    return [];
  }

  const checkAccuracy = (p) => {
    // console.log('p.accuracy', p.accuracy);
    return p.accuracy < 150;
  };

  const filteredPaths = order.road.paths.filter((p) => checkAccuracy(p));

  const medianAccuracy = filteredPaths.sort(
    (p1, p2) => p1.accuracy - p2.accuracy
  )[parseInt(filteredPaths.length / 2, 10)].accuracy;

  var midValue = 0;
  var roadCount = 0;
  filteredPaths.forEach((item) => {
    // console.log('item.accuracy', item.accuracy);
    // console.log('xazailt', Math.sqrt(item.variance));
    midValue = midValue + item.accuracy;
    roadCount++;
  });
  const mid = midValue / roadCount;

  const pathCoordinates = [];

  const diff = Math.abs(mid - medianAccuracy);
  console.log('diff', diff, mid, medianAccuracy);
  let checkMedian = diff < 1 || diff > 2;

  filteredPaths
    .sort((path1, path2) => path1.timestamp - path2.timestamp)
    .forEach((item) => {
      if (checkMedian) {
        if (item.accuracy <= medianAccuracy) {
          pathCoordinates.push({
            lat: item.original_coordinates[0],
            lng: item.original_coordinates[1],
          });
        }
      } else {
        pathCoordinates.push({
          lat: item.original_coordinates[0],
          lng: item.original_coordinates[1],
        });
      }
    });
  return pathCoordinates;
};
