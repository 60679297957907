import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';

import httpClient from './httpClient';

import Api from '../constants/Api';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const jsonDataProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {
  return {
    getList: (resource, params) => {
      const {
        admin,
        taxi,
        user,
        from,
        to,
        pagination: { page, perPage },
        sort: { field, order },
        ...others
      } = params;
      console.log('params', params);
      // console.log('params', params.filter);
      // console.log('params', fetchUtils.flattenObject(params.filter));
      // const { page, perPage } = params.pagination;
      // const { field, order } = params.sort;
      const query = params.status
        ? {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
            status: params.status,
            ...others,
          }
        : {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
            ...others,
          };
      if (from) {
        query.from = from;
      }
      if (to) {
        query.to = to;
      }

      let resourcePrefixPath = '';

      if (
        resource === 'tickets' ||
        resource === 'orders' ||
        resource === 'shifts' ||
        resource === 'orders/reportByTaxi' ||
        resource === 'orders/reportByAdmin' ||
        resource === 'reportByUser' ||
        resource === 'reviews'
      ) {
        if (admin) query.admin = admin;
        if (taxi) query.taxi = taxi;
        if (user) query.user = user;
      } else if (resource === 'orders/autocomplete' && query.q.length < 2) {
        return Promise.resolve({
          data: [],
          total: 0,
        });
      } else if (resource === 'cancelDescriptions') {
        if (!query.type) {
          query.type = 'user'; //default value
        }
      }

      if (resource === 'reportByUser' || resource === 'reportHeader') {
        resourcePrefixPath = 'orders/';
      }

      const url = `${apiUrl}/${resourcePrefixPath}${resource}?${stringify(
        query
      )}`;

      return httpClient(url).then(({ headers, json }) => {
        if (
          !headers.has('x-total-count') &&
          resource !== 'orders/reportByUserHeader' &&
          resource !== 'reportHeader'
        ) {
          throw new Error(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
          );
        }

        if (resource === 'reviews') {
          if (headers.get('x-average-rating')) {
            localStorage.setItem(
              'x-average-rating',
              parseFloat(headers.get('x-average-rating')).toFixed(1)
            );
          } else {
            localStorage.removeItem('x-average-rating');
          }
          localStorage.setItem('x-total-count', headers.get('x-total-count'));
        }

        let resultJson = json;

        let total = 0;
        if (resource === 'reportHeader') {
          total = json.length;

          let sum = 0;
          json.forEach((item) => {
            sum += item.count;
          });

          resultJson = [
            ...json,
            {
              id: 'sum',
              _id: 'sum',
              count: sum,
            },
          ];
        }

        return {
          data: resultJson.map((item) => {
            if (resource === 'orders/autocomplete') {
              return { id: item, name: item };
            } else if (resource === 'reportByUser') {
              const { status, ...others } = item;
              const statusMap = {};
              let sum = 0;
              status.forEach((item) => {
                statusMap[item.status] = item.count;
                sum += item.count;
              });
              statusMap.sum = sum;
              return { ...others, ...statusMap };
            }
            return { ...item, id: item._id };
          }),
          total: headers.get('x-total-count')
            ? parseInt(headers.get('x-total-count').split('/').pop(), 10)
            : total,
        };
      });
    },

    getOne: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: { ...json, id: json._id },
        })
      );
    },

    getMany: (resource, params) => {
      if (resource === 'orders/autocomplete') {
        return Promise.resolve({
          data: params.ids.map((item) => ({ id: item, name: item })),
        });
      }

      const query = {
        id: params.ids,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url).then(({ json }) => ({
        data: json.map((item) => {
          return { ...item, id: item._id };
        }),
      }));
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...fetchUtils.flattenObject(params.filter),
        [params.target]: params.id,
        _sort: field,
        _order: order,
        _start: (page - 1) * perPage,
        _end: page * perPage,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url).then(({ headers, json }) => {
        if (!headers.has('x-total-count')) {
          throw new Error(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
          );
        }
        return {
          data: json.map((item) => {
            if (resource === 'orders/autocomplete') {
              return { id: item, name: item };
            }
            return { ...item, id: item._id };
          }),
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
        };
      });
    },

    update: async (resource, params) => {
      if (resource === 'taxis') {
        const body = { ...params.data };
        if (body.driverImage && typeof body.driverImage !== 'string') {
          const driverImage = await convertFileToBase64(body.driverImage);
          body['driverImage'] = { src: driverImage };
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(body),
        }).then(({ json }) => ({ data: json }));
      }

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
    },

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({ data: responses.map(({ json }) => json) })),

    create: async (resource, params) => {
      if (resource === 'taxis') {
        const body = { ...params.data };
        if (body.driverImage && typeof body.driverImage !== 'string') {
          const driverImage = await convertFileToBase64(body.driverImage);
          body['driverImage'] = { src: driverImage };
        }

        return httpClient(`${apiUrl}/${resource}`, {
          method: 'POST',
          body: JSON.stringify(body),
        }).then(({ json }) => ({ data: json }));
      }

      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    },

    delete: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'DELETE',
      }).then(({ json }) => ({ data: json }));
    },

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then((responses) => ({ data: responses.map(({ json }) => json) })),
  };
};

export default jsonDataProvider(Api.base, httpClient);
