import React, { Fragment } from 'react';
import { List, Datagrid, TextField, DatagridBody } from 'react-admin';
import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TaxiStatusColor from '../../constants/TaxiStatusColor';
import { getStatusText } from '../../utils/order';

const StatusField = (props) => {
  if (props.record) {
    return (
      <div
        style={{
          justifyContent: 'left',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            color: props.record
              ? TaxiStatusColor[props.record[props.source]]
              : '#000',
            minWidth: 30,
            minHeight: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 15,
          }}
        >
          {props.record[props.source]
            ? getStatusText(props.record[props.source])
            : '-'}
        </div>
      </div>
    );
  }
  return <span></span>;
};

const CountField = (props) => {
  if (props.record) {
    return (
      <div
        style={{
          justifyContent: 'left',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            color: props.record ? TaxiStatusColor[props.record._id] : '#000',
            minWidth: 30,
            minHeight: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 15,
          }}
        >
          {props.record[props.source] ? props.record[props.source] : '-'}
        </div>
      </div>
    );
  }
  return <span></span>;
};

const MyDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
  basePath,
}) => (
  <TableRow key={id}>
    {React.Children.map(children, (field) => (
      <TableCell key={`${id}-${field.props.source}`}>
        {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
      </TableCell>
    ))}
  </TableRow>
);

const MyDatagridBody = (props) => {
  return (
    <Fragment>
      <DatagridBody {...props} row={<MyDatagridRow />} />
    </Fragment>
  );
};
const MyDatagrid = (props) => {
  return (
    <Fragment>
      <Datagrid {...props} body={<MyDatagridBody />} />
    </Fragment>
  );
};

export default (props, sos) => {
  return (
    <List
      perPage={25}
      title={'Ерөнхий'}
      filters={<Filter />}
      empty={<Empty emptyMessage={'Үр дүн байхгүй байна.'} />}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <MyDatagrid>
        <StatusField label="Төрөл" source="_id" sortable={false} />
        <CountField label="Тоо" source="count" sortable={false} />
      </MyDatagrid>
    </List>
  );
};
