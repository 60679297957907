import React, { useEffect } from 'react';

import {
  TextField,
  ReferenceField,
  List,
  Datagrid,
  NumberField,
  DateField,
  ResourceContextProvider,
} from 'react-admin';
import './OrderLogs.css';
import OrderStatusColor from '../constants/OrderStatusColor';
import Empty from '../components/react-admin/Empty';


const StatusField = (props) => {
  if (props.record) {
    return (
      <span style={{ color: OrderStatusColor[props.record[props.source]] }}>
        {props.record[props.source]}
      </span>
    );
  }
  return <span></span>;
};

const TelField = (props) => {

  if (props.record && props.source && props.record[props.source]) {
    return (
      <span>
        {props.record[props.source] === 'user' && (
          props.record.user.tel
        )}
        {props.record[props.source] === 'admin' && (
          props.record.tel
        )}
      </span>
    );
  }
  return <span></span>;
};

export default function OrderLogs({ permissions, taxi, ...props }) {
  // const { loaded, error, data } = useQueryWithStore({
  //   type: 'getList',
  //   resource: 'orders',
  //   payload: { taxi }

  // });

  // useEffect(() => {
  //   console.log('data', data);
  // }, [data])

  useEffect(() => {

  }, []);

  console.log('taxi', taxi);
  return (
    <div className="OrderLogs">
      <ResourceContextProvider value="orders">
        <List
          basePath="/orders"
          perPage={25}
          hasEdit={false}
          hasCreate={false}
          hasList={true}
          hasShow={false}
          exporter={false}
          resource="orders"
          title={'Захиалга'}
          filterDefaultValues={{ taxi }}
          empty={<Empty emptyMessage={'Захиалга байхгүй байна.'} />}
          {...props}
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <Datagrid size={'small'}>
            <DateField label="Хэзээ" source="createdAt" showTime options={{
              year: 'numeric', month: 'numeric', day: 'numeric',
              hour: 'numeric', minute: 'numeric', second: 'numeric',
              hour12: false,
            }} />
            <TelField label="Зорчигч" source="source" />
            <ReferenceField label="Хаяг" source="place._id" reference="places">
              <TextField source="name" />
            </ReferenceField>
            {(permissions === 'root' || permissions === 'admin' || permissions === 'supreme') && (
              <NumberField
                label="Төлбөр"
                source="calculatedCost"
                options={{ maximumFractionDigits: 0 }}
              />
            )}
            <StatusField label="Төлөв" source="status" />
            <TextField label="Нэмэлт мэдээлэл" source="description" />
            <TextField label="Тайлбар" source="cancelDescription" />
            {(permissions === 'root' || permissions === 'admin' || permissions === 'reporter' || permissions === 'supreme') && (
              <TextField label="Жолоочийн нэмэлт" source="driverBonus" />
            )}
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </div>
  );
}
