export default {
  requested: '#fea530',
  accepted: '#fea530',
  arrived: '#2e97fc',
  started: '#3cb39f',
  ended: '#3cb39f',
  canceled: '#d60046',
  'driver-canceled': '#d60046',
  'admin-canceled': '#d60046',
  'bot-canceled': '#d60046',
};
