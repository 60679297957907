import React from 'react';
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  SaveButton,
  Toolbar,
} from 'react-admin';

// import MyForm from "./MyForm";
import MyEdit from './MyEdit';
const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
export default (props) => (
  <Edit title={'Жолооч засах'} {...props}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <FormDataConsumer>
        {(formDataProps) => <MyEdit {...formDataProps} isEdit={true} />}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
