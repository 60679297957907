import React, { useState } from 'react';
import { required, TextInput, BooleanInput, NumberInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { useForm } from 'react-final-form';

import LocationInput from '../../components/react-admin/PlaceLocation';
import dataProvider from '../../providers/dataProvider';

const MyForm = () => {
  const form = useForm();

  const [placeSelected, setPlaceSelected] = useState(false);
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={1} mr={{ md: 0, lg: '1em' }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <LocationInput
              source="location"
              parse={(v) => {
                if (v && v.coordinates) {
                  return {
                    lat: v.coordinates[1],
                    lng: v.coordinates[0],
                  };
                } else {
                  return v;
                }
              }}
              format={(v) => {
                if (v && v.coordinates) {
                  return {
                    lat: v.coordinates[1],
                    lng: v.coordinates[0],
                  };
                } else {
                  return v;
                }
              }}
              validate={[required()]}
              fullWidth
              placeSelected={placeSelected}
              setPlaceSelected={setPlaceSelected}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              source="nameEn"
              label="Address"
              validate={[required()]}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <NumberInput
              source="extraDistance"
              label="Нэмэлт зай / метрээр"
              validate={[required()]}
              fullWidth
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput
              label="Хаяг"
              source="name"
              validate={[required()]}
              fullWidth
              onChange={async (value) => {
                try {
                  const {
                    data: {
                      location: { coordinates },
                    },
                  } = await dataProvider.getOne('places');
                  if (coordinates) {
                    const location = {
                      lat: coordinates[1],
                      lng: coordinates[0],
                    };
                    form.change('location', location);
                  }

                  setPlaceSelected(true);
                } catch (e) {}
              }}
            ></TextInput>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
        </Box>

        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <BooleanInput
              label="Баталгаажаагүй"
              source="notVerified"
              validate={[required()]}
              fullWidth
            ></BooleanInput>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyForm;
