import React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import { createHashHistory as createHistory } from 'history';
import store from './createAdminStore';
import { Provider } from 'react-redux';
import { configure } from 'react-hotkeys';

import customRoutes from './customRoutes';
import MyLayout from './components/react-admin/MyLayout';
import LoginWithTheme from './components/LoginPage';
import MyLogoutButton from './components/react-admin/MyLogoutButton';
import containers from './containers';
import MainMap from './pages/MainMap';
import SocketContext from './socket.io/socket-context';
import socket from './socket.io/socket';

configure({
  ignoreTags: [],
});

const history = createHistory({ basename: process.env.PUBLIC_URL });

const getResources = (permissions) => {
  const data = containers.map((entry) => (
    <Resource key={entry.name} {...entry} />
  ));

  const resources = [
    'places/nearby',
    'makes',
    'models',
    'orders/autocomplete',
    'banks',
  ];
  resources.forEach((key) => data.push(<Resource key={key} name={key} />));
  return data;
};

const App = () => {
  return (
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <Admin
          dashboard={MainMap}
          history={history}
          layout={MyLayout}
          loginPage={LoginWithTheme}
          logoutButton={MyLogoutButton}
          authProvider={authProvider}
          dataProvider={dataProvider}
          customRoutes={customRoutes}
        >
          {(permissions) => getResources(permissions)}
        </Admin>
      </Provider>
    </SocketContext.Provider>
  );
};

export default App;
