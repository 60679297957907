import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';

export default (props) => (
  <List
    perPage={25}
    title={'Цуцлах шалтгаан'}
    filters={<Filter />}
    empty={<Empty emptyMessage={'Цуцлах шалтгаан байхгүй байна.'} />}
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" label="Нэр" />
      <TextField source="nameEn" label="Нэр (Англи)" />
      <TextField source="type" label="Төрөл" />
      <EditButton />
    </Datagrid>
  </List>
);
