import React from 'react';
import {
  Filter,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  DateTimeInput,
} from 'react-admin';
import { usePermissions } from 'react-admin';

const userNameRenderer = (choice) =>
  choice && choice.name
    ? `${choice.name} (${choice.username}, ${choice.role})`
    : '';

export default (props) => {
  const { permissions } = usePermissions();

  const isShowFilter =
    permissions === 'root' ||
    permissions === 'supreme' ||
    permissions === 'admin' ||
    permissions === 'reporter';

  return (
    <Filter {...props}>
      <TextInput label="Хайх" source="q" alwaysOn />

      {isShowFilter && <DateTimeInput label="Эхлэх огноо" source="from" />}
      {isShowFilter && <DateTimeInput label="Дуусах огноо" source="to" />}

      {isShowFilter && (
        <ReferenceInput
          label="Хэрэглэгч"
          source="admin"
          reference="admins"
          filterToQuery={(searchText) => {
            return { q: searchText };
          }}
          allowEmpty={false}
        >
          <AutocompleteInput
            optionText={userNameRenderer}
            resettable={true}
            clearAlwaysVisible={true}
          />
        </ReferenceInput>
      )}

      {isShowFilter && (
        <ReferenceInput
          label="Жолооч"
          source="taxi"
          reference="taxis"
          filterToQuery={(searchText) => {
            return { q: searchText };
          }}
          allowEmpty={false}
        >
          <AutocompleteInput
            optionText="vehicleNumber"
            resettable={true}
            clearAlwaysVisible={true}
          />
        </ReferenceInput>
      )}

      {isShowFilter && (
        <ReferenceInput
          label="Зорчигч"
          source="user"
          reference="users"
          filterToQuery={(searchText) => {
            return { q: searchText };
          }}
          allowEmpty={false}
        >
          <AutocompleteInput
            optionText="tel"
            resettable={true}
            clearAlwaysVisible={true}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};
