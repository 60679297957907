import React, { Fragment, useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Button,
  useRecordContext,
  useListContext,
  DatagridBody,
} from 'react-admin';
import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';
import TaxiStatusColor from '../../constants/TaxiStatusColor';
import { useHistory } from 'react-router-dom';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDataProvider } from 'react-admin';
import { useNotify } from 'react-admin';

const StatusField = (props) => {
  if (props.record) {
    return (
      <div
        style={{
          justifyContent: 'left',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            color: props.record ? TaxiStatusColor[props.source] : '#000',
            minWidth: 30,
            minHeight: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 15,
          }}
        >
          {props.record[props.source] ? props.record[props.source].toFixed(0) : '-'}
        </div>
      </div>
    );
  }
  return <span></span>;
};

const ShowOrdersButton = (_props) => {
  const { record } = useRecordContext(_props);
  const { filterValues } = useListContext();

  let history = useHistory();

  const redirect = (userId) => {
    const filter = {};
    if (filterValues.from) {
      filter.from = filterValues.from;
    }
    if (filterValues.to) {
      filter.to = filterValues.to;
    }
    filter.user = userId;
    history.push(`/orders?filter=${JSON.stringify(filter)}`);
  };

  return (
    <Button
      onClick={() => {
        redirect(record._id);
      }}
      label=""
    >
      <MenuBookIcon />
    </Button>
  );
};

const MyDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
  basePath,
}) => (
  <TableRow key={id}>
    {React.Children.map(children, (field) => (
      <TableCell key={`${id}-${field.props.source}`}>
        {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
      </TableCell>
    ))}
  </TableRow>
);

const MyDatagridBody = (props) => {
  return (
    <Fragment>
      <DatagridBody {...props} row={<MyDatagridRow />} />
    </Fragment>
  );
};
const MyDatagrid = (props) => {
  return (
    <Fragment>
      <MyHeader></MyHeader>
      <Datagrid {...props} body={<MyDatagridBody />} />
    </Fragment>
  );
};

const MyHeader = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [data, setData] = useState(null);
  const { filterValues } = useListContext();

  const { from, to } = filterValues;

  useEffect(() => {
    const list = async () => {
      try {
        const { data: dat } = await dataProvider.getList(
          'orders/reportByUserHeader',
          {
            filter: {
              from: filterValues.from,
              to: filterValues.to,
            },
            pagination: {
              page: 1,
              perPage: 50,
            },
            sort: {
              field: 'createdAt',
              order: 'DESC',
            },
          }
        );
        let datMap = {};
        let sum = 0;
        dat.forEach((item) => {
          datMap[item._id] = item.count;
          sum += item.count;
        });
        datMap.sum = sum;
        setData(datMap);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    };
    list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 15,
    marginRight: 15,
  };

  const titleStyle = {
    marginRight: 3,
    fontWeight: 'bold',
  };
  const valStyle = {
    fontWeight: 'bold',
  };

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        height: 80,
      }}
    >
      {data && (
        <div
          style={{
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={rowStyle}>
            <div style={titleStyle}>Амжилттай:</div>
            <div style={{ ...valStyle, color: TaxiStatusColor['ended'] }}>
              {data['ended']}
            </div>
          </div>
          <div style={rowStyle}>
            <div style={titleStyle}>Зөвшөөрсөн:</div>
            <div style={{ ...valStyle, color: TaxiStatusColor['accepted'] }}>
              {data['accepted']}
            </div>
          </div>
          <div style={rowStyle}>
            <div style={titleStyle}>Эхэлсэн:</div>
            <div style={{ ...valStyle, color: TaxiStatusColor['started'] }}>
              {data['started']}
            </div>
          </div>
          <div style={rowStyle}>
            <div style={titleStyle}>Жолооч цуцалсан:</div>
            <div style={{ ...valStyle, color: TaxiStatusColor['canceled'] }}>
              {data['driver-canceled']}
            </div>
          </div>
          <div style={rowStyle}>
            <div style={titleStyle}>Хэрэглэгч цуцалсан:</div>
            <div style={{ ...valStyle, color: TaxiStatusColor['canceled'] }}>
              {data['canceled']}
            </div>
          </div>
          <div style={rowStyle}>
            <div style={titleStyle}>Админ цуцалсан:</div>
            <div style={{ ...valStyle, color: TaxiStatusColor['canceled'] }}>
              {data['admin-canceled']}
            </div>
          </div>
          <div style={rowStyle}>
            <div style={titleStyle}>Систем цуцалсан:</div>
            <div style={{ ...valStyle, color: TaxiStatusColor['canceled'] }}>
              {data['bot-canceled']}
            </div>
          </div>
          <div style={rowStyle}>
            <div style={titleStyle}>Нийт:</div>
            <div style={valStyle}> {data['sum']}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default (props, sos) => {
  return (
    <List
      perPage={25}
      title={'Зорчигч'}
      filters={<Filter />}
      empty={<Empty emptyMessage={'Үр дүн байхгүй байна.'} />}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <MyDatagrid>
        <TextField label="Хэрэглэгч" source="tel" sortable={false} />
        <StatusField label="Амжилттай" source="ended" sortable={false} />
        <StatusField label="Зөвшөөрсөн" source="accepted" sortable={false} />
        <StatusField label="Эхэлсэн" source="started" sortable={false} />
        <StatusField
          label="Жолооч цуцалсан"
          source="driver-canceled"
          sortable={false}
        />
        <StatusField
          label="Хэрэглэгч цуцалсан"
          source="canceled"
          sortable={false}
        />
        <StatusField
          label="Админ цуцалсан"
          source="admin-canceled"
          sortable={false}
        />
        <StatusField
          label="Систем цуцалсан"
          source="bot-canceled"
          sortable={false}
        />
        <StatusField label="Нийт" source="sum" sortable={false} />
        <StatusField
          label="Төлсөн төлбөр"
          source="cost"
          sortable={false}
        />
        <ShowOrdersButton />
      </MyDatagrid>
    </List>
  );
};
