export const getStatusText = (status) => {
  if (status === 'requested') {
    return 'Захиалсан';
  }
  if (status === 'accepted') {
    return 'Зөвшөөрсөн';
  }
  if (status === 'canceled') {
    return 'Зорчигч цуцалсан';
  }
  if (status === 'arrived') {
    return 'Жолооч ирсэн';
  }
  if (status === 'started') {
    return 'Аялал эхэлсэн';
  }
  if (status === 'ended') {
    return 'Хүргэж ирсэн';
  }
  if (status === 'driver-canceled') {
    return 'Жолооч цуцалсан';
  }
  if (status === 'admin-canceled') {
    return 'Админ цуцалсан';
  }
  if (status === 'bot-canceled') {
    return 'Ойр такси олдоогүй';
  }
  if (status === 'pre-canceled') {
    return 'Эрт цуцалсан';
  }

  if (status === 'sum') {
    return 'Нийт';
  }

  return status;
};
