import React from "react";
import { Edit, SimpleForm } from "react-admin";

import MyForm from "./MyForm";

export default (props) => (
  <Edit actions={<div></div>} title={"Захиалга засах"} {...props}>
    <SimpleForm toolbar={<div/>}>
      <MyForm isEdit={true} delete={null}/>
    </SimpleForm>
  </Edit>
);
