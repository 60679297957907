import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  useDataProvider,
} from 'react-admin';
import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';
import ListAvatar from '../../components/react-admin/ListAvatar';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import httpClient from '../../providers/httpClient';
import Api from '../../constants/Api';
import { useNotify } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { usePermissions } from 'react-admin';

const useStyles = makeStyles({
  rating: { color: 'blue' },
});

const DisableField = (props) => {
  if (props.record) {
    return (
      <span>
        {props.record[props.source] === false && <Check />}
        {props.record[props.source] === true && <Close />}
      </span>
    );
  }
  return <span></span>;
};

// const ActiveField = (props) => {
//   if (props.record) {
//     return (
//       <span>
//         {props.record[props.source] === true && <Check />}
//         {props.record[props.source] === false && <Close />}
//       </span>
//     );
//   }
//   console.log('aa', props.record[props.source]);
//   return <span></span>;
// };

const RatingField = (props) => {
  const classes = useStyles();
  if (props.record) {
    return (
      <span className={classes.rating}>
        {props.record[props.source]
          ? props.record[props.source].toFixed(1)
          : '0'}
      </span>
    );
  }
  return <span></span>;
};

const ExpireField = ({ onChange, record }) => {
  return (
    <Switch
      checked={record ? record.active : false}
      onChange={(e) => {
        onChange(e, record);
      }}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    ></Switch>
  );
};

export default (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [openExpire, setOpenExpire] = React.useState(false);
  const [currentRowData, setCurrentRowData] = React.useState(null);
  const [expireType, setExpireType] = React.useState(null);

  const { permissions } = usePermissions();

  const handleCloseExpire = () => {
    setOpenExpire(false);
    setCurrentRowData(null);
  };

  const onChange = (e, record) => {
    setExpireType(null);
    setOpenExpire(true);
    setCurrentRowData(record);
  };

  const handleExpireSubmit = async (record) => {
    if (!expireType) {
      notify('Хугацаа сонгоно уу', 'error');
      return;
    }

    try {
      const _id = record._id;
      await httpClient(Api.taxiExpire(_id), {
        method: 'PUT',
        body: JSON.stringify({ type: expireType }),
      });

      record.active = true;

      try {
        const taxi = record._id;
        await dataProvider.getOne('taxis', {
          id: taxi,
        });
      } catch (error) {}

      notify('Амжилттай', 'info');
    } catch (error) {
      notify(error.message, 'error');
    }
    setOpenExpire(false);
    setCurrentRowData(null);
  };

  return (
    <Fragment>
      <List
        perPage={25}
        title={'Жолооч'}
        filters={<Filter />}
        empty={<Empty emptyMessage={'Жолооч байхгүй байна.'} />}
        {...props}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <Datagrid rowClick={''}>
          <ListAvatar label="" source="driver.image" sortable={false} />
          <ReferenceField label="Груп" source="group" reference="groups">
            <TextField label="Груп" source="name" />
          </ReferenceField>
          <TextField label="Нэр" source="driver.name" />
          <TextField label="Утасны дугаар" source="driver.tel" />
          <TextField label="Хүйс" source="driver.gender" />
          <TextField label="Төрсөн он" source="driver.birthYear" />
          <TextField label="Регистрийн дугаар" source="registrationNumber" />
          <TextField label="Гэрийн хаяг" source="address" />
          <TextField label="Эрх" source="ticket" />

          <TextField label="Машины дугаар" source="vehicleNumber" />
          <TextField label="Машин" source="carModelString" />
          <TextField label="Машины өнгө" source="carColor" />
          <DisableField label="Төлөв" source="disabled" />
          {/* <ActiveField label="Ашиглах хугацаа" source="active" /> */}
          <ExpireField
            label="Ашиглах хугацаа"
            source="active"
            onChange={(e, record) => {
              if (
                permissions === 'root' ||
                permissions === 'admin' ||
                permissions === 'supreme' ||
                permissions === 'reporter'
              ) {
                onChange(e, record);
              }
            }}
          />
          <RatingField label="Үнэлгээ" source="rating" />
          {/* <TextField label="Нууц үг" source="password" /> */}
          <EditButton />
        </Datagrid>
      </List>
      {
        <div style={{ flexDirection: 'row', maxWidth: 300 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Dialog
              open={openExpire}
              onClose={handleCloseExpire}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Мэдэгдэл'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Жолоочийн ашиглалтын хугацааг сунгахдаа итгэлтэй байна уу?
                </DialogContentText>

                <FormControl className={{}} fullWidth>
                  <InputLabel id="demo-simple-select-label">Хугацаа</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={expireType}
                    onChange={(e) => {
                      setExpireType(e.target.value);
                    }}
                  >
                    {currentRowData && currentRowData.active === false && (
                      <MenuItem value={'current'}>Энэ сар дуустал</MenuItem>
                    )}
                    <MenuItem value={'next'}>Дараа сар дуустал</MenuItem>
                    {currentRowData && currentRowData.active === false && (
                      <MenuItem value={'month'}>1 сар</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseExpire} color="primary">
                  Үгүй
                </Button>
                <Button
                  onClick={() => {
                    handleExpireSubmit(currentRowData);
                  }}
                  color="primary"
                  autoFocus
                >
                  Тийм
                </Button>
              </DialogActions>
            </Dialog>
          </Typography>
        </div>
      }
    </Fragment>
  );
};
