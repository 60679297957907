import React from 'react';
import { TextInput, Filter, DateTimeInput } from 'react-admin';
import moment from 'moment';

export default (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Хайх" source="q" alwaysOn />
      <DateTimeInput
        label="Эхлэх огноо"
        source="from"
        alwaysOn
        onChange={(e) => {
          localStorage.setItem(
            'user_from',
            moment(e.target.value).toISOString()
          );
        }}
      />
      <DateTimeInput
        label="Дуусах огноо"
        source="to"
        alwaysOn
        onChange={(e) => {
          localStorage.setItem('user_to', moment(e.target.value).toISOString());
        }}
      />
    </Filter>
  );
};
