import React from 'react';
import { Filter, DateTimeInput, SelectInput } from 'react-admin';
import moment from 'moment';

export default (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Төрөл"
        source="source"
        choices={[
          { id: 'admin', name: 'Дис' },
          { id: 'user', name: 'Апп' },
          { id: 'driver', name: 'Жолооч' },
        ]}
      />
      <DateTimeInput
        label="Эхлэх огноо"
        source="from"
        alwaysOn
        onChange={(e) => {
          localStorage.setItem(
            'header_from',
            moment(e.target.value).toISOString()
          );
        }}
      />
      <DateTimeInput
        label="Дуусах огноо"
        source="to"
        alwaysOn
        onChange={(e) => {
          localStorage.setItem(
            'header_to',
            moment(e.target.value).toISOString()
          );
        }}
      />
    </Filter>
  );
};
