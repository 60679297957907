import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Form } from 'react-final-form';
import AdminIcon from '@material-ui/icons/VerifiedUser';
import UserIcon from '@material-ui/icons/PhoneAndroid';
import DriverIcon from '@material-ui/icons/DriveEta';
import OrderStatusColor from '../../constants/OrderStatusColor';
import EditButton from './EditButton';
import { getStatusText } from '../../utils/order';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    display: 'flex',
    backgroundColor: '#888',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // minHeight: 30,
  },
  value: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    marginRight: 8,
    // paddingTop: 3,
    // paddingBottom: 3,
    minHeight: 20,
    flexDirection: 'column',
    minWidth: 80,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const OrderListItem = ({
  item,
  onClickVehicle,
  disableClickVehicle = false,
  number,
  onClickPlace,
  disableClickPlace,
  onClickEdit,
  onClickCancel,
  onClickRetry
}) => {
  const classes = useStyles();
  const defaultProps = {
    bgcolor: 'background.paper',
    style: { width: '100%' },
    color: 'grey.600',
  };

  const extraFarCost = () => {
    if (item && item.extraCosts) {
      for (var i = 0; i < item.extraCosts.length; i++) {
        if (item.extraCosts[i].type === 'extraFarCost') {
          return item.extraCosts[i].cost;
        }
      }
    }
    return undefined;
  };

  return (
    <div key={item.id} style={{ fontSize: 12 }}>
      <Grid>
        <Paper
          variant="outlined"
          className={classes.paper}
          style={{ backgroundColor: OrderStatusColor[item.status] + '1A' }}
        >
          <div className={classes.content}>
            <div className={classes.title} style={{ width: 60 }}>
              Хэзээ
            </div>
            <div className={classes.value} style={{ minWidth: 60 }}>
              <Moment format=" MM/DD HH:mm">{item.createdAt}</Moment>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.title} style={{ width: 60 }}>
              Зорчигч
            </div>
            <div className={classes.value} style={{ minWidth: 50 }}>
              {item.user ? item.user.tel : item.tel}
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.title} style={{ minWidth: 50 }}>
              Төлөв
            </div>
            <div
              className={classes.value}
              style={{
                color: OrderStatusColor[item.status],
              }}
            >
              {getStatusText(item.status)}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {item.source === 'user' && <UserIcon style={{ color: '#888' }} />}
            {item.source === 'admin' && <AdminIcon style={{ color: '#888' }} />}
            {item.source === 'driver' && (
              <DriverIcon style={{ color: '#888' }} />
            )}
            {number && `#${number}`}
          </div>
          <Box borderBottom={1} {...defaultProps} />
          <div className={classes.content}>
            <div className={classes.title} style={{ width: 60 }}>
              Хаана
            </div>
            <div className={classes.value}>
              {disableClickPlace ? (
                item.place && item.place.name
              ) : item.place && item.place.name ? (
                <Link
                  onClick={() => onClickPlace(item.place)}
                  component="button"
                >
                  {item.place ? item.place.name : 'GPS'}
                </Link>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <Box borderBottom={1} {...defaultProps} />
          <div className={classes.content}>
            <div className={classes.title} style={{ width: 60 }}>
              Хаашаа
            </div>
            <div className={classes.value}>
              {item.targetPlace ? item.targetPlace.name : 'GPS'}
            </div>
          </div>
          <>
            <Box borderBottom={1} {...defaultProps} />

            <div className={classes.content}>
              <div className={classes.title} style={{ width: 60 }}>
                Машин
              </div>
              <div className={classes.value}>
                {disableClickVehicle ? (
                  `${item.taxi ? item.taxi.vehicleNumber : ''} ${(item.taxi && item.taxi.carModelString) ? item.taxi.carModelString : ''}`
                ) : item.taxi && item.taxi.vehicleNumber ? (
                  <Link
                    onClick={() => onClickVehicle(item.taxi)}
                    component="button"
                  >
                    {`${item.taxi ? item.taxi.vehicleNumber : ''} ${(item.taxi && item.taxi.carModelString) ? item.taxi.carModelString : ''}`}
                  </Link>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className={classes.content}>
              <div className={classes.title} style={{ width: 60 }}>
                Жолооч
              </div>
              <div className={classes.value}>
                {item.taxi && item.taxi.driver && item.taxi.driver.name}
              </div>
            </div>

            <>
              <div className={classes.content}>
                <div className={classes.title} style={{ width: 90 }}>
                  Очих минут
                </div>
                {item.arriveMinute && (
                  <div className={classes.value} style={{ minWidth: 30 }}>
                    {item.arriveMinute} минут
                  </div>
                )}
              </div>
            </>
          </>
          <Box borderBottom={1} {...defaultProps} />

          <div className={classes.content}>
            <div className={classes.title} style={{ width: 130 }}>
              Нэмэлт мэдээлэл
            </div>{' '}
            <div className={classes.value}>{item.description}</div>
          </div>
          <Box borderBottom={1} {...defaultProps} />

          <div className={classes.content}>
            <div className={classes.title} style={{ width: 130 }}>
              TIP
            </div>{' '}
            <div className={classes.value}>{item.driverBonus}</div>
            {extraFarCost() && (
              <div className={classes.content}>
                <div className={classes.title} style={{ width: 130 }}>
                  Холын нэмэгдэл
                </div>{' '}
                <div className={classes.value}>{extraFarCost()}</div>
              </div>
            )}
          </div>
          <>
            <Box borderBottom={1} {...defaultProps} />

            <div className={classes.content}>
              <div className={classes.title} style={{ width: 60 }}>
                Түүх
              </div>
              <div
                className={classes.value}
                style={{ flexDirection: 'column' }}
              >
                {item.statusLogs &&
                  item.statusLogs.map((item) => {
                    return (
                      <div key={item._id}>
                        {getStatusText(item.status)}{' '}
                        {item.admin ? `(${item.admin.name})` : ''}
                        {': '}
                        <Moment format="MM/DD HH:mm">{item.date}</Moment>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>

          <>
            <Box borderBottom={1} {...defaultProps} />

            <div style={{ marginTop: 10, marginBottom: 0, marginRight: 10 }}>
              <Button
                size="small"
                variant="contained"
                style={{
                  color: '#fff',
                  backgroundColor:
                    item.status !== 'canceled' &&
                    item.status !== 'admin-canceled' &&
                    item.status !== 'bot-canceled' &&
                    item.status !== 'driver-canceled' &&
                    item.status !== 'ended'
                      ? 'red'
                      : 'red',
                }}
                onClick={() => {
                  onClickCancel(item);
                }}
              >
                Цуцлах
              </Button>
            </div>
          </>

          <>
            <Form
              onSubmit={() => {}}
              render={() => (
                <EditButton
                  size="small"
                  item={item}
                  disabled={
                    !(
                      !item.user &&
                      item.status !== 'canceled' &&
                      item.status !== 'admin-canceled' &&
                      item.status !== 'bot-canceled' &&
                      item.status !== 'driver-canceled' &&
                      item.status !== 'ended'
                    )
                  }
                  onClickEdit={onClickEdit}
                />
              )}
            />
          </>
        {item.status === 'bot-canceled' && 
          <div style={{ marginTop: 10, marginBottom: 0, marginLeft: 10 }}>
              <Button
                size="small"
                variant="contained"
                style={{
                  color: '#fff',
                  backgroundColor: 'blue',
                }}
                
                onClick={() => {
                  onClickRetry(item);
                }}
              >
                Дахин цацах
              </Button>
            </div>
          }
        </Paper>
      </Grid>
    </div>
  );
};

export default OrderListItem;
