import React, { useState, Fragment } from "react";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import { useForm } from "react-final-form";
import {
  required,
  Button,
  SaveButton,
  NumberInput,
  FormWithRedirect,
  useCreate,
  useNotify,
  TextInput,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function CreateButton({ taxi, onSubmit }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create] = useCreate("tickets");
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: { ...values, taxi } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          form.change("post_id", data.id);
          onSubmit();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };
  return (
    <FormWithRedirect
      resource="tickets"
      save={handleSubmit}
      render={({ handleSubmitWithRedirect, saving }) => (
        <Fragment>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClick} label="Нэмэх">
            <IconContentAdd />
          </Button>
          <Dialog
            fullWidth
            open={showDialog}
            onClose={handleCloseClick}
            aria-label="Create"
          >
            <DialogTitle>Create</DialogTitle>
            <DialogContent>
              <NumberInput
                label="Эрх"
                source="total"
                validate={required()}
                fullWidth
              />
              <TextInput
                label="Тайлбар"
                source="description"
                validate={required()}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button label="ra.action.cancel" onClick={handleCloseClick}>
                <IconCancel />
              </Button>
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                saving={saving}
              />
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
}
export default CreateButton;
