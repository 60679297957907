import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-virtualized/styles.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

var originalLog = console.error;
console.error = function log(...args) {
  if (
    args.length > 0 &&
    typeof args[0] === 'string' &&
    /^Warning: Missing translation/.test(args[0])
  ) {
    return;
  }
  originalLog.apply(console, args);
};
