import io from 'socket.io-client';
import store from '../createAdminStore';
import * as ordersActions from '../actions/orders';
import * as mainActions from '../actions/main';
import * as orderRadiusActions from '../actions/orderRadius';

const socketioHost =
  process.env.NODE_ENV === 'production'
    ? 'https://ezcab.mn'
    : 'https://ezcab.mn';

const socket = io(socketioHost, {
  autoConnect: false,
  path: '/admin-socket/socket.io',
  secure: true,
  transports: ['websocket'],
});

socket.on('connect', async (msg) => {
  const token = localStorage.getItem('token');
  if (token && token.length > 0) {
    socket.emit('auth', token);
  } else {
    socket.close();
  }
});

socket.on('authCallback', (data) => {
  if (data.status !== 200) {
    socket.close();
    console.log('SOCKET Not CONNECTED!', socket);
  } else {
    console.log('SOCKET CONNECTED!', socket);
  }
});
// Шинэ захиалга ирэх
socket.on('newOrder', (data) => {
  console.log('socket', 'newOrder');
  store.dispatch(ordersActions.syncOrder(data));
  store.dispatch(mainActions.updateTaxi(data));
});
socket.on('accepted', (data) => {
  console.log('socket', 'accepted');
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});
socket.on('start', (data) => {
  console.log('socket', 'start');
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});

socket.on('arrived', (data) => {
  console.log('socket', 'start');
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});
socket.on('driver:canceled', (data) => {
  console.log('socket', 'start');
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});

socket.on('end', (data) => {
  console.log('socket', 'end');
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});
socket.on('canceled', (data) => {
  console.log('socket', 'canceled');
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});
socket.on('cancel', (data) => {
  console.log('socket', 'cancel');
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});
socket.on('admin:canceled', (data) => {
  console.log('admin:canceled', data);
  store.dispatch(ordersActions.syncOrder(data));

  store.dispatch(mainActions.updateTaxi(data));
  store.dispatch(orderRadiusActions.removeOrder(data));
});
socket.on('sos', (data) => {
  console.log('sos');
  store.dispatch(mainActions.sosReceived(data));
});
socket.on('order:radius', (data) => {
  console.log('order:radius', data);
  store.dispatch(orderRadiusActions.pushOrder(data));
});
socket.on('arriveMinute', (data) => {
  console.log('arriveMinute', data);
  store.dispatch(ordersActions.syncOrder(data, 'arriveMinute'));
  // store.dispatch(mainActions.updateTaxi(data));
});
export default socket;
