import { PUSH_ORDER, REMOVE_ORDER } from '../actions/orderRadius';

const initialState = {
  orders: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PUSH_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.data._id]: action.data,
        },
      };
    case REMOVE_ORDER: {
      const orders = state.orders;
      delete orders[action.data._id];
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.data._id]: null,
        },
      };
    }
    default:
      return state;
  }
};
