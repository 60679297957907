import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as directionsActions from '../actions/directions';

const useDirections = ({ googleMapRef }) => {
  const dispatch = useDispatch();

  const location = useSelector((state) => state.directions.location);
  const targetLocation = useSelector(
    (state) => state.directions.targetLocation
  );

  const [polyline, setPolyline] = useState(null);

  const initalize = useCallback(() => {
    if (location && targetLocation) {
      const Map = googleMapRef.current.map_;
      const Polyline = googleMapRef.current.maps_.Polyline;
      const LatLng = googleMapRef.current.maps_.LatLng;

      const directionsService = new googleMapRef.current.maps_.DirectionsService();
      const directionsDisplay = new googleMapRef.current.maps_.DirectionsRenderer();

      directionsService.route(
        {
          origin: new LatLng(location.lat, location.lng),
          destination: new LatLng(targetLocation.lat, targetLocation.lng),
          waypoints: [],
          // waypoints: [{ location: 'Mobile AL' }],
          travelMode: 'DRIVING',
        },
        (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
            if (!polyline) {
              const routePolyline = new Polyline({
                path: response.routes[0].overview_path,
                strokeColor: 'red',
              });
              routePolyline.setMap(Map);
              setPolyline(routePolyline);
              routePolyline.setMap(Map);
            } else {
              polyline.setPath(response.routes[0].overview_path);
              polyline.setMap(Map);
            }
            dispatch(
              directionsActions.setDistance(
                response.routes[0].legs[0].distance.text
              )
            );
          } else {
            console.log('Directions request failed due to ' + status);
          }
        }
      );
    } else {
      if (polyline) {
        polyline.setMap(null);
      }
      dispatch(directionsActions.setDistance(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, googleMapRef, location, targetLocation]);

  useEffect(() => {
    const MapEvent = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.event
      : null;

    if (MapEvent) {
      initalize();
    }
  }, [googleMapRef, initalize]);

  return [];
};

export default useDirections;
