import {
  SET_SEGMENT_INDEX,
  GET_BOT_CANCELED_LOADING,
  GET_BOT_CANCELED,
  GET_BOT_CANCELED_END,
  GET_REQUESTED_LOADING,
  GET_REQUESTED,
  GET_REQUESTED_END,
  GET_ACCEPTED_LOADING,
  GET_ACCEPTED,
  GET_ACCEPTED_END,
  GET_BOT_CANCELED_COUNT,
  GET_REQUESTED_COUNT,
  GET_ACCEPTED_COUNT,
  CLEAR_DATA,
  SYNC_ORDER,
  UPDATE_ORDER,
} from '../actions/orders';

const initialState = {
  currentSegmentIndex: 0,

  isLoadingBotCanceled: false,
  ordersBotCanceled: [],
  ordersBotCanceledCount: 0,
  ordersBotCanceledFetched: false,

  isLoadingRequested: false,
  ordersRequested: [],
  ordersRequestedCount: 0,
  ordersRequestedFetched: false,

  isLoadingAccepted: false,
  ordersAccepted: [],
  ordersAcceptedCount: 0,
  ordersAcceptedFetched: false,
};

// const mergeData = (oldData, newData, startIndex) => {
//   newData.forEach((item, index) => {
//     const realIndex = startIndex + index;
//     oldData[realIndex] = item;
//   });
//   return oldData;
// };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEGMENT_INDEX:
      return {
        ...state,
        currentSegmentIndex: action.index,
      };

    case GET_BOT_CANCELED_LOADING:
      return {
        ...state,
        isLoadingBotCanceled: true,
      };
    case GET_BOT_CANCELED:
      return {
        ...state,
        // ordersBotCanceled: mergeData(
        //   state.ordersBotCanceled,
        //   action.data,
        //   action.startIndex
        // ),
        ordersBotCanceled: action.data,
        isLoadingBotCanceled: false,
        ordersBotCanceledFetched: true,
      };
    case GET_BOT_CANCELED_END:
      return {
        ...state,
        isLoadingBotCanceled: false,
      };

    case GET_REQUESTED_LOADING:
      return {
        ...state,
        isLoadingRequested: true,
      };
    case GET_REQUESTED:
      return {
        ...state,
        // ordersRequested: mergeData(
        //   state.ordersRequested,
        //   action.data,
        //   action.startIndex
        // ),
        ordersRequested: action.data,
        isLoadingRequested: false,
        ordersRequestedFetched: true,
      };
    case GET_REQUESTED_END:
      return {
        ...state,
        isLoadingRequested: false,
      };

    case GET_ACCEPTED_LOADING:
      return {
        ...state,
        isLoadingAccepted: true,
      };
    case GET_ACCEPTED:
      return {
        ...state,
        // ordersAccepted: mergeData(
        //   state.ordersAccepted,
        //   action.data,
        //   action.startIndex
        // ),
        ordersAccepted: action.data,
        isLoadingAccepted: false,
        ordersAcceptedFetched: true,
      };
    case GET_ACCEPTED_END:
      return {
        ...state,
        isLoadingAccepted: false,
      };

    case GET_BOT_CANCELED_COUNT:
      return {
        ...state,
        ordersBotCanceledCount: action.data,
      };
    case GET_REQUESTED_COUNT: {
      return { ...state, ordersRequestedCount: action.data };
    }
    case GET_ACCEPTED_COUNT: {
      return { ...state, ordersAcceptedCount: action.data };
    }

    case CLEAR_DATA:
      const changes = {};
      if (state.currentSegmentIndex === 0) {
        changes.ordersBotCanceled = [];
      } else if (state.currentSegmentIndex === 1) {
        changes.ordersRequested = [];
      } else if (state.currentSegmentIndex === 2) {
        changes.ordersAccepted = [];
      }
      return {
        ...state,
        ...changes,
      };

    case UPDATE_ORDER: {
      const order = action.order;
      const dat = action.data;
      const changes = {};
      if (state.currentSegmentIndex === 0) {
        changes.ordersBotCanceled = state.ordersBotCanceled.map((item) => {
          if (item._id === order._id) {
            return { ...item, ...dat };
          } else {
            return item;
          }
        });
      } else if (state.currentSegmentIndex === 1) {
        changes.ordersRequested = state.ordersRequested.map((item) => {
          if (item._id === order._id) {
            return { ...item, ...dat };
          } else {
            return item;
          }
        });
      } else if (state.currentSegmentIndex === 2) {
        changes.ordersAccepted = state.ordersAccepted.map((item) => {
          if (item._id === order._id) {
            return { ...item, ...dat };
          } else {
            return item;
          }
        });
      }
      return {
        ...state,
        ...changes,
      };
    }

    case SYNC_ORDER: {
      const order = action.data;
      const topic = action.topic;

      let ordersBotCanceledCount = state.ordersBotCanceledCount;
      let ordersRequestedCount = state.ordersRequestedCount;
      let ordersAcceptedCount = state.ordersAcceptedCount;

      //----------------BOT-CANCELED----------------
      let orderFound = false;
      const ordersBotCanceled = state.ordersBotCanceled.filter((item) => {
        if (item.id !== order.id) {
          return true;
        } else {
          orderFound = true;
          if (order.status !== 'bot-canceled') {
            //REMOVE
            ordersBotCanceledCount -= 1;
            return false;
          } else {
            return true;
          }
        }
      });
      //INSERT
      if (
        !orderFound &&
        order.status === 'bot-canceled' &&
        order.source === 'admin'
      ) {
        ordersBotCanceled.unshift(order);
        ordersBotCanceledCount += 1;
      }

      //----------------REQUESTED----------------
      orderFound = false;
      const ordersRequested = state.ordersRequested.filter((item) => {
        if (item.id !== order.id) {
          return true;
        } else {
          orderFound = true;
          if (order.status !== 'requested') {
            //REMOVE
            ordersRequestedCount -= 1;
            return false;
          } else {
            return true;
          }
        }
      });
      //INSERT
      if (!orderFound && order.status === 'requested') {
        ordersRequested.unshift(order);
        ordersRequestedCount += 1;
      }

      //----------------ACCEPTED----------------
      orderFound = false;
      let updateRequired = false;
      let ordersAccepted = state.ordersAccepted.filter((item) => {
        if (item.id !== order.id) {
          return true;
        } else {
          orderFound = true;
          if (
            order.status !== 'accepted' &&
            order.status !== 'arrived' &&
            order.status !== 'started'
          ) {
            //REMOVE
            ordersAcceptedCount -= 1;
            return false;
          } else {
            updateRequired = true;
            return true;
          }
        }
      });
      //INSERT
      if (
        topic !== 'arriveMinute' &&
        !orderFound &&
        (order.status === 'accepted' ||
          order.status === 'arrived' ||
          order.status === 'started')
      ) {
        ordersAccepted.unshift(order);
        ordersAcceptedCount += 1;
      }
      //UPDATE
      if (updateRequired) {
        ordersAccepted = ordersAccepted.map((item) => {
          if (item.id === order.id) {
            if (topic === 'arriveMinute') {
              return { ...item, arriveMinute: order.arriveMinute };
            } else {
              return order;
            }
          }
          return item;
        });
      }

      return {
        ...state,
        ordersBotCanceled,
        ordersBotCanceledCount,
        ordersRequested,
        ordersRequestedCount,
        ordersAccepted,
        ordersAcceptedCount,
      };
    }
    default:
      return state;
  }
};
