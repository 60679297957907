import List from "./List";
import icon from "@material-ui/icons/Home";

import Edit from "./Edit";

export default {
  name: "sos",
  list: List,
  icon: icon,
  // create: Create,
  edit: Edit,
};
