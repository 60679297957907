import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import Paper from '@material-ui/core/Paper';
// import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import './OrderLogs.css';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
//   paper: {
//     padding: theme.spacing(1),
//     textAlign: 'left',
//     flexWrap: 'wrap',
//     display: 'flex',
//     color: 'theme.palette.text.secondary',
//     margin: theme.spacing(1),
//     width: '95%',
//   },
// }));

export default function Reviews({ taxi }) {
  const dataProvider = useDataProvider();
  // const classes = useStyles();
  const notify = useNotify();
  const [data, setData] = React.useState([]);

  useEffect(() => {
    const list = async () => {
      try {
        const { data: dataRequested } = await dataProvider.getList('reviews', {
          filter: {
            taxi,
          },
          pagination: {
            page: 1,
            perPage: 50,
          },
          sort: {
            field: 'createdAt',
            order: 'DESC',
          },
        });
        setData(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    };
    list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OrderLogs">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Хэзээ</TableCell>
              <TableCell align="left">Зорчигч</TableCell>
              <TableCell align="right">Зорчигчийн өгсөн үнэлгээ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  <Moment format="YYYY/MM/DD HH:mm">{row.createdAt}</Moment>
                </TableCell>
                <TableCell align="left">{row.user.tel}</TableCell>
                <TableCell align="right">{row.rating}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
