import React from 'react';
import { Create, SimpleForm, FormDataConsumer } from 'react-admin';

import MyForm from './MyForm';

export default (props) => (
  <Create title={'Жолооч нэмэх'} {...props}>
    <SimpleForm redirect="list">
      <FormDataConsumer>
        {(formDataProps) => <MyForm {...formDataProps} isEdit={false} />}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
