import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

const CarDefault = require('../assets/Car.png');

const useTaxiMarkers = ({
  googleMapRef,
  handleMarkerClick,
  allowInit,
  infoWindows,
}) => {
  const taxis = useSelector((state) => state.main.taxis);
  const [taxiMarkers] = useState([]);

  const updatedTaxi = useSelector((state) => state.main.updatedTaxi);

  const getInfoWindowTitle = useCallback(
    (item) => {
      let title = '';
      let description = '';
      title = `${item.driver.name} (${item.status})`;
      description = '';
      return `<div style="padding-bottom: 10px;"><strong style="font-size: 18px;">${
        taxis[item.taxi] ? taxis[item.taxi] : ''
      }</strong><br/><strong style="font-size: 18px;">${title}</strong><br/></div><div style="font-size: 15px">${description}</div>`;
    },
    [taxis]
  );

  const setupMarker = useCallback(
    ({ item, MapEvent, GoogleMap, MapInfoWindow, marker, onClick }) => {
      var infowindow = new MapInfoWindow({
        content: getInfoWindowTitle(item),
      });
      MapEvent.addListener(marker, 'mouseover', function () {
        infoWindows.forEach((item) => item.close());
        while (infoWindows.length) {
          infoWindows.pop();
        }

        infowindow.open(GoogleMap, marker);
        infoWindows.push(infowindow);
      });
      MapEvent.addListener(marker, 'mouseout', function () {
        infoWindows.forEach((item) => item.close());
        while (infoWindows.length) {
          infoWindows.pop();
        }
      });

      marker.addListener('click', onClick);
    },
    [getInfoWindowTitle, infoWindows]
  );

  useEffect(() => {
    const Marker = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.Marker
      : null;
    const MapEvent = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.event
      : null;
    const MapInfoWindow = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.InfoWindow
      : null;
    const GoogleMap = googleMapRef.current.map_;

    if (Marker) {
      const taxisMap = {};
      taxis.forEach((item) => {
        if (item.location) {
          if (
            updatedTaxi &&
            updatedTaxi.taxi &&
            item.id === updatedTaxi.taxi.id
          ) {
            taxisMap[item.id] = updatedTaxi.taxi;
          } else {
            taxisMap[item.id] = item;
          }
        }
      });

      const updateableMarkers = [];

      //Өмнөх байгаа marker уудын coordinate шинэчлэх
      taxiMarkers.forEach((_item) => {
        const { marker, item } = _item;
        if (taxisMap[item.id]) {
          if (
            (item.location.coordinates[1] !==
              taxisMap[item.id].location.coordinates[1] &&
              item.location.coordinates[0] !==
                taxisMap[item.id].location.coordinates[0]) ||
            !marker.map
          ) {
            marker.setPosition({
              lat: taxisMap[item.id].location.coordinates[1],
              lng: taxisMap[item.id].location.coordinates[0],
            });
            marker.setMap(GoogleMap);
          }

          //UPDATE MARKER
          if (item.status !== taxisMap[item.id].status) {
            updateableMarkers.push(_item);
          } else {
            delete taxisMap[item.id];
          }
        } else {
          marker.setMap(null);
        }
          if (item.status === 'logout') {
            marker.setMap(null);
          }
      });

      updateableMarkers.forEach((item) => {
        const index = taxiMarkers.indexOf(item);
        if (index > -1) {
          //CLEAR PREVIOUS
          const { marker } = item;
          marker.setMap(null);

          taxiMarkers.splice(index, 1);
        }
      });

      //Шинээр нэмэгдэх marker ууд
      Object.keys(taxisMap).forEach((id) => {
        const item = taxisMap[id];

        if (item.location) {
          let icon = CarDefault;
          if (item.status === 'free') {
            icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.vehicleNumber}-green.png`;
          } else if (item.status === 'busy') {
            icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.vehicleNumber}-red.png`;
          } else if (item.status === 'inactive') {
            icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.vehicleNumber}-orange.png`;
          } else {
            icon = `https://ubinfo-s3.s3.amazonaws.com/taxi/sign/${item.vehicleNumber}-red.png`;
          } 

          const map = item.status === 'logout' ? null : GoogleMap

          const marker = new Marker({
            position: {
              lat: item.location.coordinates[1],
              lng: item.location.coordinates[0],
            },
            map: map,
            icon: {
              url: icon,
              scaledSize: new googleMapRef.current.maps_.Size(60, 60),
            },
            title: item.status,
            tracksViewChanges: false,
          });
         
          setupMarker({
            item: { markerType: 'packet', ...item },
            map,
            MapEvent,
            MapInfoWindow,
            marker,
            title: item.driver.name,
            onClick: () => {
              handleMarkerClick(item);
            },
          });
          taxiMarkers.push({ marker, item });
        }
      });
    } else {
      taxiMarkers.forEach(({ marker }) => marker.setMap(GoogleMap));
    }
  }, [
    allowInit,
    googleMapRef,
    handleMarkerClick,
    setupMarker,
    taxiMarkers,
    taxis,
    updatedTaxi,
  ]);

  return [taxiMarkers];
};

export default useTaxiMarkers;
