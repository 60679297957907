//@flow
import React, { Component } from 'react';

const keycodes = {
  DIGIT_ZERO: 48,
  DIGIT_NINE: 57,
  KEY_A: 65,
  KEY_Z: 90,
};

export default class ShortcutTextField extends Component {
  constructor() {
    super();

    this.state = {
      textValue: '',
    };
  }

  _isAlphabetKey(keyCode) {
    if (keycodes.KEY_A <= keyCode && keyCode <= keycodes.KEY_Z) {
      return true;
    } else {
      return false;
    }
  }

  _isNumberKey(keyCode) {
    if (keycodes.DIGIT_ZERO <= keyCode && keyCode <= keycodes.DIGIT_NINE) {
      return true;
    } else {
      return false;
    }
  }

  _isValidKey(keyCode) {
    if (!this.props.allowNumber && this._isNumberKey(keyCode)) {
      return false;
    } else if (this._isAlphabetKey(keyCode) || this._isNumberKey(keyCode)) {
      return true;
    } else {
      return false;
    }
  }

  _handleKeyDown(event) {
    if (!(event instanceof KeyboardEvent)) {
      throw new Error('Invalid event type error.');
    }

    event.preventDefault();
    event.stopPropagation();

    let key = '';

    if (this._isValidKey(event.keyCode)) {
      let prefix = '';

      if (event.ctrlKey) {
        prefix += 'Ctrl+';
      }

      if (event.shiftKey) {
        prefix += 'Shift+';
      }

      if (event.altKey) {
        prefix += 'Alt+';
      }

      key = prefix + event.key.toUpperCase();
    }

    this.setState({
      textValue: key,
    });

    this.props.onChange(key);
  }

  componentDidMount() {
    const node = this.input;
    node.addEventListener('keydown', this._handleKeyDown.bind(this), false);

    this.setState({
      textValue: this.props.value,
    });
  }

  componentWillUnmount() {
    const node = this.input;
    node.removeEventListener('keydown', this._handleKeyDown.bind(this));
  }

  render() {
    return (
      <input
        type="text"
        className="ShortcutTextField"
        name={this.props.name}
        ref={(ref) => (this.input = ref)}
        value={this.state.textValue}
        onChange={(e) => {}}
      />
    );
  }
}
