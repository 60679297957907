import React from "react";

import { Filter, TextInput, useTranslate } from "react-admin";
import { makeStyles, Chip } from "@material-ui/core";

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));
const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={translate(label)} />;
};

export default (props) => (
  <Filter {...props}>
    <TextInput label="Хайх" source="q" alwaysOn />
    <QuickFilter source="driver.name" label="Нэр" defaultValue={true} />
    <QuickFilter label="Утасны дугаар" source="driver.tel" defaultValue={150} />
    <QuickFilter label="Хүйс" source="driver.gender" defaultValue={[3]} />
  </Filter>
);
