import React from 'react';
import { TextInput, required, BooleanInput, NumberInput } from 'react-admin';
import { Box } from '@material-ui/core';

const MyForm = ({ formData, isEditForm, ...rest }) => {
  return (
    <Box display={{ md: 'block', lg: 'flex' }}>
      <Box flex={1} mr={{ md: 0, lg: '1em' }}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            {formData.type === 'text' && (
              <TextInput
                label="Утга"
                source="value"
                validate={[required()]}
                fullWidth
              />
            )}
            {formData.type === 'boolean' && (
              <BooleanInput
                label="Утга"
                source="value"
                defaultValue={false}
              />
            )}
            {formData.type === 'number' && (
              <NumberInput
                label="Утга"
                source="value"
                validate={[required()]}
                fullWidth
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyForm;
