import React from 'react';
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// const role = localStorage.getItem('role');

const useSidebarStyles = makeStyles({
  drawerPaper: {
    'padding-top': 0,//role === 'viewer' ? 'calc((100vh - 60px - 120px) / 2)' : 0,
    width:0
  },
});

const MySidebar = (props) => {
  const classes = useSidebarStyles();
  return <Sidebar classes={classes} {...props} />;
};

export default MySidebar;
