import React from 'react';
import { Filter, SelectInput } from 'react-admin';

export default (props) => (
  <Filter {...props}>
    <SelectInput
      source="type"
      label="Төрөл"
      choices={[
        { label: 'Хэрэглэгч', name: 'Хэрэглэгч', id: 'user' },
        { label: 'Жолооч', name: 'Жолооч', id: 'driver' },
      ]}
      initialValue={'user'}
      alwaysOn
    />
  </Filter>
);
