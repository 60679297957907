import Api from '../constants/Api';

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(Api.auth, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(async (response) => {
        if (response.status < 200 || response.status >= 300) {
          const json = await response.json();
          if (json.error) {
            throw new Error(json.error);
          } else {
            throw new Error(response.statusText);
          }
        } else {
          localStorage.setItem('token', response.headers.get('token'));
          return response.json();
        }
      })
      .then((json) => {
        console.log('json.staticPrice',json.staticPrice)
        localStorage.setItem('username', json.username);
        localStorage.setItem('role', json.role);
        localStorage.setItem('company', json.company);
        localStorage.setItem('keyOrderCreate', json.keyOrderCreate);
        localStorage.setItem('staticPrice', json.staticPrice);
        if (json.companyLocation) {
          localStorage.setItem(
            'companyLocation',
            JSON.stringify(json.companyLocation)
          );
        }
      });
  },
  logout: (params) => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('company');
    localStorage.removeItem('keyOrderCreate');
    localStorage.removeItem('companyLocation');
    localStorage.removeItem('staticPrice');
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('company');
      localStorage.removeItem('role');
      localStorage.removeItem('keyOrderCreate');
      localStorage.removeItem('companyLocation');
      localStorage.removeItem('staticPrice');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem('role')
    if (role || window.location.hash==='#/privacy'  || window.location.hash==='#/privacy-driver'){
      return Promise.resolve(role)
    }
    return Promise.reject()
  },
  checkAuth: () => {
     const token = localStorage.getItem('token')
     if (token || window.location.hash==='#/privacy'  || window.location.hash==='#/privacy-driver'){
      return Promise.resolve()
     }
     return Promise.reject()
  },
};

export default authProvider;
