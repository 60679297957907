import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import Filter from "./Filter";
import Empty from "../../components/react-admin/Empty";

export default (props) => (
  <List
    perPage={25}
    title={"Хүлээлгийн минут"}
    filters={<Filter />}
    empty={<Empty emptyMessage={"Хүлээлгийн минут байхгүй байна."} />}
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="edit">
      <TextField source="minute" />
      <EditButton />
    </Datagrid>
  </List>
);
