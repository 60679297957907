import React, { Fragment } from 'react';
import {
  List,
  Datagrid,
  DatagridBody,
  DateField,
  TextField,
  ReferenceField,
} from 'react-admin';
import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const CountField = (props) => {
  if (props.record) {
    return (
      <div
        style={{
          justifyContent: 'left',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            color: props.record.amount >= 0 ? 'green' : 'red',
            minWidth: 30,
            minHeight: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 15,
          }}
        >
          {props.record[props.source] ? props.record[props.source] : '-'}
        </div>
      </div>
    );
  }
  return <span></span>;
};

const DescField = (props) => {
  if (props.record) {
    return (
      <div
        style={{
          justifyContent: 'left',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            minWidth: 30,
            minHeight: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 15,
          }}
        >
          {props.record[props.source] ? props.record[props.source] : '-'}
        </div>
      </div>
    );
  }
  return <span></span>;
};

const DriverField = (props) => {
  if (props.record) {
    const { driver } = props.record;
    return (
      <div
        style={{
          justifyContent: 'left',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            minWidth: 30,
            minHeight: 30,
            borderRadius: 15,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            fontWeight: 'bold',
            fontSize: 15,
          }}
        >
          {driver.name} {props.record.vehicleNumber}
        </div>
      </div>
    );
  }
  return <span></span>;
};

const MyDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
  basePath,
}) => (
  <TableRow key={id}>
    {React.Children.map(children, (field) => (
      <TableCell key={`${id}-${field.props.source}`}>
        {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
      </TableCell>
    ))}
  </TableRow>
);

const MyDatagridBody = (props) => {
  return (
    <Fragment>
      <DatagridBody {...props} row={<MyDatagridRow />} />
    </Fragment>
  );
};
const MyDatagrid = (props) => {
  return (
    <Fragment>
      <Datagrid {...props} body={<MyDatagridBody />} />
    </Fragment>
  );
};

const driverRenderer = (choice) =>
  choice && choice.vehicleNumber
    ? `${choice.driver.name ? choice.driver.name + ' ' : ''}${
        choice.vehicleNumber
      }`
    : '';

export default (props, sos) => {
  return (
    <List
      perPage={25}
      title={'Хэтэвч'}
      filters={<Filter />}
      empty={<Empty emptyMessage={'Үр дүн байхгүй байна.'} />}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <MyDatagrid>
        <DateField
          label="Огноо"
          source="createdAt"
          showTime
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
          }}
        />
        <ReferenceField label="Жолооч" source="taxi" reference="taxis">
          <DriverField />
        </ReferenceField>
        <CountField label="Эрх" source="amount" sortable={false} />
        <DescField label="Тайлбар" source="description" sortable={false} />
      </MyDatagrid>
    </List>
  );
};
