import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useDataProvider } from 'react-admin';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import '../taxis/ticket/index.css';
import Moment from 'react-moment';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
// import ListItem from "@material-ui/core/ListItem";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
    width: '95%',
  },
}));

export default function OrderLogs({ user, orders = [] }) {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const [data, setData] = React.useState([]);
  useEffect(() => {
    const list = async () => {
      try {
        const { data: dataRequested } = await dataProvider.getList('orders', {
          user: user,
          pagination: {
            page: 1,
            perPage: 50,
          },
          sort: {
            field: 'createdAt',
            order: 'DESC',
          },
        });
        setData(dataRequested);
      } catch (error) {
        notify(`${error.message}`, 'warning');
      }
    };
    list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="List2">
      <List component="nav" aria-label="secondary mailbox folders">
        {data.map((item) => {
          return (
            <div key={item._id}>
              <Paper className={classes.paper}>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  <Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment>
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={10} user={user}>
                    <ListItemText
                      primary={`Хаанаас: ${
                        item.place ? item.place.name : 'GPS'
                      }`}
                    />
                  </Grid>
                </Grid>
                <Button
                  key={item.id}
                  component={Link}
                  to={`/orders/${item.id}`}
                  variant="outlined"
                  color="primary"
                >
                  Дэлгэрэнгүй
                </Button>
              </Paper>
            </div>
          );
        })}

        {/* <Grid item xs={10}>
          <ListItemText
            primary={`LIST: ${value.tickets && value.tickets.total}`}
          />
        </Grid> */}
      </List>
    </div>
  );
}
