import * as React from "react";
import get from "lodash/get";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import sanitizeRestProps from "./sanitizeRestProps.tsx";
import {
  fieldPropTypes,
} from "./types.tsx";

const useStyles = makeStyles(
  {
    chip: { margin: 4 },
  },
  { name: "RaChipField" }
);

const ChipField = (props) => {
  const {
    className,
    classes: classesOverride,
    source,
    record = {},
    emptyText,
    ...rest
  } = props;
  const classes = useStyles(props);
  const value = get(record, source);

  if (value == null && emptyText) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {emptyText}
      </Typography>
    );
  }

  let myval = value;
  if (value === "root") {
    myval = "Админ";
  }
  if (value === "admin") {
    myval = "Ахлах ажилтан";
  }
  if (value === "operator") {
    myval = "Оператор";
  }
  if (value === "reporter") {
    myval = "Тайлангийн ажилтан";
  }

  return (
    <Chip
      className={classnames(classes.chip, className)}
      label={myval}
      {...sanitizeRestProps(rest)}
    />
  );
};

ChipField.defaultProps = {
  addLabel: true,
};

ChipField.propTypes = {
  ...ChipField.propTypes,
  ...fieldPropTypes,
};

export default ChipField;
