import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import Filter from "./Filter";
// import ListAvatar from '../../components/ListAvatar';
import Empty from "../../components/react-admin/Empty";
import RoleChipField from "../../components/react-admin/RoleChipField";

export default (props) => {
  return (
    <List
      perPage={25}
      title={"Хэрэглэгчид"}
      filters={<Filter />}
      empty={<Empty emptyMessage={"Үр дүн олдсонгүй."} />}
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        {/* <ListAvatar label="Avatar" source="avatar" sortable={false} /> */}
        <TextField label="Нэвтрэх нэр" source="username" />
        <TextField label="Нэр" source="name" />
        <RoleChipField label="Эрхийн төрөл" source="role" />
        {/* <BooleanField label="Идэвхитэй" source="enabled" defaultValue={true} /> */}
        {/* <DateField source="createdAt" showTime /> */}
        <EditButton />
      </Datagrid>
    </List>
  );
};
