import { fetchUtils } from 'react-admin';

export default async (url, options = {}) => {
  try {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    const result = await fetchUtils.fetchJson(url, options);
    return result;
  } catch (e) {
    if (e.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('company');
      localStorage.removeItem('role');
      localStorage.removeItem('keyOrderCreate');
      localStorage.removeItem('companyLocation')
      window.location.reload();
    }

    throw e;
  }
};
