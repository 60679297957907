import React, { forwardRef, useEffect, Fragment } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { useNotify, useDataProvider } from 'react-admin';
import httpClient from '../providers/httpClient';
import TaxiStatusColor from '../constants/TaxiStatusColor';
import Api from '../constants/Api';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import './Table.css';
import View from '@material-ui/icons/Visibility';
import { usePermissions } from 'react-admin';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  rating: { color: 'blue' },
}));

// const TOOLBAR_ID = 'MY_TOOLBAR';

let searchEnterIndex = -1;

export default function Table({ onPress, onSearchChange, maxBodyHeight }) {
  const classes = useStyles();
  const notify = useNotify();
  const tableRef = React.useRef();
  const taxis = useSelector((state) => state.main.taxis);
  const dataProvider = useDataProvider();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [data, setData] = React.useState([]);

  useEffect(() => {
    if (taxis && taxis.length > 0) {
      setData(taxis);
    }
  }, [taxis]);
  const theme = useTheme();

  let customStyle = {
    padding: theme.spacing(1, 1, 1, 0),
    marginLeft: 15,
  };

  const [disableDesc, setDisableDesc] = React.useState('');
  const [disableChecked, setDisableChecked] = React.useState();
  const [currentRowData, setCurrentRowData] = React.useState(null);
  const [openDisable, setOpenDisable] = React.useState(false);

  useEffect(() => {
    if (currentRowData) {
      setDisableDesc(currentRowData.disableDescription);
      setDisableChecked(!currentRowData.disabled);
    } else {
      setDisableDesc('');
    }
  }, [currentRowData]);

  const handleCloseDisable = () => {
    setOpenDisable(false);
    setCurrentRowData(null);
  };

  const handleSubmitDisableOrEnable = async (item, value = undefined) => {
    try {
      const body = {};
      if (disableDesc) {
        body.description = disableDesc;
      }

      let isEnable;
      if (value !== undefined) {
        isEnable = value ? true : false;
      } else {
        //TOGGLE
        isEnable = item.disabled ? true : false;
      }

      let url = isEnable
        ? Api.taxisEnable(item._id)
        : Api.taxisDisable(item._id);

      await httpClient(url, {
        method: 'PUT',
        body: JSON.stringify(body, { disableDesc }),
      });
      notify('Амжилттай', 'info');

      try {
        await dataProvider.getOne('taxis', {
          id: item._id,
        });
      } catch (e) {}

      item.disabled = isEnable ? false : true;
      item.disableDescription = disableDesc;

      // item.disableHistory = response.json.disableHistory;
      const dataCopy = [...data];
      const index = dataCopy.indexOf(item);
      dataCopy[index] = item;
      setData(dataCopy);
    } catch (error) {
      notify(error.message, 'error');
    }
    setCurrentRowData(null);
    setOpenDisable(false);
  };

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (tableRef.current.state.data.length > 0) {
        searchEnterIndex += 1;

        for (
          let i = searchEnterIndex;
          i < tableRef.current.state.data.length;
          i++
        ) {
          if (!tableRef.current.state.data[i].location) {
            searchEnterIndex += 1;
          } else {
            break;
          }
        }

        if (searchEnterIndex >= tableRef.current.state.data.length) {
          searchEnterIndex = 0;
        }

        const value = tableRef.current.state.data[searchEnterIndex];
        if (onSearchChange) {
          onSearchChange(value);
        }
      }
    }
  };
  const { permissions } = usePermissions();

  return (
    <div onKeyDown={_handleKeyDown}>
      <MaterialTable
        sizeSmall={0}
        stickyHeader
        tableRef={tableRef}
        options={{
          showTitle: false,
          pageSize: 20,
          search: true,
          sizeSmall: 0,
          headerStyle: { position: 'sticky' },
          maxBodyHeight: maxBodyHeight,
          searchFieldStyle: customStyle,
          rowStyle: (rowData) => {
            if (rowData.disabled) {
              return {
                backgroundColor: '#f66257',
                color: '#fff',
                fontSize: 11,
                padding: 0,
              };
            } else {
              return {
                backgroundColor: '#fff',
                coor: '#000',
                fontSize: 11,
                padding: 0,
              };
            }
          },
        }}
        style={{
          fontSize: '12px',
          width: '100%',
          sizeSmall: 0,
        }}
        icons={tableIcons}
        title="Жолооч"
        columns={[
          {
            title: 'Жолооч',
            field: 'driver.name',
            padding: 0,
            cellStyle: { padding: 0 },
          },
          {
            title: 'Дугаар',
            field: 'vehicleNumber',
            padding: 0,
            cellStyle: { padding: 0 },
          },
          {
            title: 'Утасны дугаар',
            field: 'driver.tel',
            padding: 0,
            cellStyle: { padding: 0 },
          },
          {
            title: 'Статус',
            field: 'status',
            padding: 0,
            render: (rowData) => {
              let status = rowData.status;
              if (rowData.currentOrder) {
                status = rowData.currentOrder.status;
              }
              return (
                <div style={{ color: TaxiStatusColor[status] }}>{status}</div>
              );
            },
            cellStyle: { padding: 0 },
          },
          {
            title: 'Хаанаас',
            field: 'currentOrder.place.name',
            padding: 0,
            render: (rowData) => {
              if (rowData.currentOrder && rowData.currentOrder.place) {
                return <div>{rowData.currentOrder.place.name}</div>;
              } else {
                return <div>-</div>;
              }
            },
            cellStyle: { padding: 0 },
          },
          {
            title: 'Зорчигч',
            field: 'currentOrder.tel',
            padding: 0,
            render: (rowData) => {
              if (rowData.currentOrder) {
                return (
                  <a href={'tel:' + rowData.currentOrder.tel}>
                    {rowData.currentOrder.tel}
                  </a>
                );
              } else {
                return <div>-</div>;
              }
            },
            cellStyle: { padding: 0 },
          },
          {
            title: 'Идэвхитэй эсэх',
            field: '',
            padding: 0,
            render: (rowData) => {
              if (
                permissions === 'root' ||
                permissions === 'admin' ||
                permissions === 'supreme' ||
                permissions === 'reporter'
              ) {
                return (
                  <Fragment>
                    <Switch
                      checked={!rowData.disabled}
                      onChange={(e) => {
                        setOpenDisable(true);
                        setCurrentRowData(rowData);
                      }}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    ></Switch>
                  </Fragment>
                );
              } else {
                return (
                  <Fragment>
                    {!rowData.disabled ? <Check /> : <Close />}
                  </Fragment>
                );
              }
            },
            cellStyle: { padding: 0 },
          },
          {
            title: 'Ашиглах хугацаа',
            field: '',
            padding: 0,
            render: (rowData) => {
              // if (
              //   permissions === 'root' ||
              //   permissions === 'admin' ||
              //   permissions === 'supreme' ||
              //   permissions === 'reporter'
              // ) {
              return (
                <Fragment>{rowData.active ? <Check /> : <Close />}</Fragment>
              );
              // }
            },
            cellStyle: { padding: 0 },
          },
          {
            title: 'Шалтгаан',
            field: 'disableDescription',
            padding: 0,
            cellStyle: { padding: 0 },
          },
        ]}
        data={data}
        onRowClick={(event, value) => {
          if (onSearchChange) {
            onSearchChange(value);
          }
        }}
        onSearchChange={(value) => {
          searchEnterIndex = -1;
          if (value && value.length > 0) {
            if (tableRef.current.state.data.length === 1) {
              const first = tableRef.current.state.data[0];
              if (onSearchChange) {
                onSearchChange(first);
              }
            }
          } else {
            if (onSearchChange) {
              onSearchChange(null);
            }
          }
        }}
        actions={[
          {
            icon: View,
            onClick: (event, value) => {
              if (onPress) {
                onPress(value);
              }
            },
          },
        ]}
      />
      {
        <Dialog
          open={openDisable}
          onClose={handleCloseDisable}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Мэдэгдэл'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Жолоочийн төлвийг идэвхигүй болгох уу?
              <Switch
                checked={disableChecked}
                onChange={(e) => {
                  setDisableChecked(e.target.checked);
                }}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              ></Switch>
            </DialogContentText>
            <form className={classes.root}>
              <TextField
                label="Тайлбар"
                id="standard-basic"
                value={disableDesc}
                onChange={(e) => setDisableDesc(e.target.value)}
                fullWidth
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDisable} color="primary">
              Хаах
            </Button>
            <Button
              onClick={() => {
                handleSubmitDisableOrEnable(currentRowData, disableChecked);
              }}
              color="primary"
              autoFocus
            >
               Хадгалах
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}
