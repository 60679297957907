import React from 'react';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import { FilterList, FilterListItem } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { getStatusText } from '../../utils/order';
const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const DateFilter = (props) => (
  <FilterList label="Хугацаа" {...props}>
    <FilterListItem
      key="today"
      label="Өнөөдөр"
      value={{
        from: endOfYesterday().toISOString(),
        to: undefined,
      }}
    />
    <FilterListItem
      key="week"
      label="Энэ 7 хоног"
      value={{
        from: startOfWeek(new Date()).toISOString(),
        to: undefined,
      }}
    />
    <FilterListItem
      key="beforeWeek"
      label="Өмнөх 7 хоног"
      value={{
        from: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        to: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      key="month"
      label="Энэ сар"
      value={{
        from: startOfMonth(new Date()).toISOString(),
        to: undefined,
      }}
    />
    <FilterListItem
      key="beforeMonth"
      label="Өмнөх сар"
      value={{
        from: subMonths(startOfMonth(new Date()), 1).toISOString(),
        to: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Анхных"
      value={{
        from: undefined,
        to: new Date(2021, 3, 17, 17, 13),
      }}
    />
  </FilterList>
);

const TypeFilter = (props) => (
  <FilterList {...props} label="Захиалга оруулсан">
    <FilterListItem
      key="all"
      label="Бүгд"
      value={{
        source: undefined,
      }}
    />
    <FilterListItem
      key="driver"
      label="Жолооч"
      value={{
        source: 'driver',
      }}
    />
    <FilterListItem
      key="admin"
      label="Дис"
      value={{
        source: 'admin',
      }}
    />
    <FilterListItem
      key="user"
      label="Зорчигч"
      value={{
        source: 'user',
      }}
    />
  </FilterList>
);

const StatusFilter = (props) => (
  <FilterList {...props} label="Захиалгын төлөв">
    <FilterListItem
      key="all"
      label="Бүгд"
      value={{
        status: undefined,
      }}
    />
    <FilterListItem
      key="accepted"
      label={getStatusText('accepted')}
      value={{
        status: 'accepted',
      }}
    />
    <FilterListItem
      key="arrived"
      label={getStatusText('arrived')}
      value={{
        status: 'arrived',
      }}
    />

    <FilterListItem
      key="started"
      label={getStatusText('started')}
      value={{
        status: 'started',
      }}
    />
    <FilterListItem
      key="ended"
      label={getStatusText('ended')}
      value={{
        status: 'ended',
      }}
    />
    <FilterListItem
      key="canceled"
      label={getStatusText('canceled')}
      value={{
        status: 'canceled',
      }}
    />
    <FilterListItem
      key="driver-canceled"
      label={getStatusText('driver-canceled')}
      value={{
        status: 'driver-canceled',
      }}
    />
    <FilterListItem
      key="admin-canceled"
      label={getStatusText('admin-canceled')}
      value={{
        status: 'admin-canceled',
      }}
    />
    <FilterListItem
      key="bot-canceled"
      label={getStatusText('bot-canceled')}
      value={{
        status: 'bot-canceled',
      }}
    />
  </FilterList>
);

const FilterSidebar = (props) => {
  return (
    <Card style={{width: 300, maxWidth: 300}}>
      <CardContent>
        <DateFilter {...props} />
        <TypeFilter {...props} />
        <StatusFilter {...props} />
      </CardContent>
    </Card>
  );
};
export default FilterSidebar;
