import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-final-form';
import GoogleMapReact from 'google-map-react';
import { useInput } from 'react-admin';
import { stringify } from 'query-string';

import Api from '../../constants/Api';
import httpClient from '../../providers/httpClient';
const LocationInput = ({
  value: valueParam,
  order,
  placeSelected,
  setPlaceSelected,
  ...props
}) => {
  // const {
  //   input: { name, onChange, value },
  //   meta: { touched, error },
  //   isRequired,
  // } = useInput(props);

  const {
    input: { value: valueForm },
    meta: { touched, error },
  } = useInput(props);
  const value = valueParam ? valueParam : valueForm;

  // const { formData, ...rest } = props;
  const [markerPosition, setMarkerPosition] = useState(null);
  const [marker, setMarker] = useState(null);

  const form = useForm();
  const googleMapRef = React.createRef();
  const [zoom] = useState(13);
  const [center, setCenter] = useState({ lat: 47.9216914, lng: 106.9184941 });
  useEffect(() => {
    const companyLocation = localStorage.getItem('companyLocation');
    if (companyLocation) {
      const json = JSON.parse(companyLocation)
      const cent = { lat: json.coordinates[1], lng: json.coordinates[0] }
      setCenter(cent);
    }
  }, []);
  const [centerDidSet, setCenterDidSet] = useState(false);

  const pathCoordinates = [];

  
  useEffect(() => {
    const Marker = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.Marker
      : null;
    const GoogleMap = googleMapRef.current.map_;

    const Polyline = googleMapRef.current.maps_
    ? googleMapRef.current.maps_.Marker
    : null;

    if (markerPosition && Marker && GoogleMap && !marker && Polyline) {
      form.change('location', markerPosition);
      setMarker(
        new Marker({
          position: markerPosition,
          map: GoogleMap,
          scaledSize: { width: 16, height: 16 },
          icon: {
            url: require('../../assets/map-marker.png'),
          },
        })
      );

      const { lat, lng } = markerPosition;

      const getPlaces = async () => {
        try {
          const url = `${Api.placesNearby}?${stringify({ lat, lng })}`;
          const { json: data } = await httpClient(url);
          if (data && data.length > 0) {
            form.change('place', data[0]._id);
          }
        } catch (e) {}
      };

      getPlaces();
    }
  }, [form, googleMapRef, marker, markerPosition, setMarker, value]);

  useEffect(() => {
    const map = googleMapRef.current.map_;
    if (map) {
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }
      if (!centerDidSet) {
        setCenterDidSet(true);

        map.setCenter(value);
        setPlaceSelected(false);
      }

      setMarkerPosition(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapRef.current, value, placeSelected]);

  const handleOnClick = (e) => {
    if (marker) {
      marker.setMap(null);
      setMarker(null);
    }
    setMarkerPosition({ lat: e.lat, lng: e.lng });
  };
  // console.log('marker', marker);
  const renderPolylines = (map, maps) => {
    const GoogleMap = googleMapRef.current.map_;
    if (
      order &&
      order.road != null &&
      order.road.paths &&
      order.road.paths.length > 0
    ) {
      order.road.paths.forEach((item) => {
        pathCoordinates.push({
          lat: item.coordinates[0],
          lng: item.coordinates[1],
        });
      });
      let geodesicPolyline = new maps.Polyline({
        path: pathCoordinates,
        map: GoogleMap,
        strokeColor: 'red',
        strokeWeight: 2,
      });
      geodesicPolyline.setMap(map);
    }

    if (order && order.road != null && order.road.lastLocation) {
      const myLatLng = {
        lat: order.road.lastLocation.coordinates[1],
        lng: order.road.lastLocation.coordinates[0],
      };
      let MarkerLastLocation = new maps.Marker({
        position: myLatLng,
        map: GoogleMap,
      });
      MarkerLastLocation.setMap(map);
    }
    if (order && order.road != null && order.road.firstLocation) {
      const myLatLng = {
        lat: order.road.firstLocation.coordinates[1],
        lng: order.road.firstLocation.coordinates[0],
      };
      let MarkerLastLocation = new maps.Marker({
        position: myLatLng,
        map: GoogleMap,
        icon: {
          url: require('../../assets/map-marker.png'),
        },
      });
      MarkerLastLocation.setMap(map);
    }
  };

  return (
    <Fragment>
      <div
        style={{
          height: '500px',
          width: '100%',
          position: 'relative',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAEBWB-b-YZ5gCMN3aZxtWZ-3huACGEglk',
          }}
          options={() => {
            return {
              fullscreenControl: false,
              mapTypeControl: true,
              styles: [{ stylers: [{ visibility: 'on' }] }],
            };
          }}
          tilt={60}
          ref={googleMapRef}
          defaultCenter={center}
          defaultZoom={zoom}
          distanceToMouse={() => {}}
          yesIWantToUseGoogleMapApiInternals
          onClick={handleOnClick}
          onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
        ></GoogleMapReact>
      </div>
      {!!(touched && error) && (
        <div
          style={{
            color: 'red',
            height: 40,
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Газрын зураг дээр байршил заана уу
        </div>
      )}
    </Fragment>
  );
};

export default LocationInput;
