import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';

const useStyles = makeStyles({
  typeGreen: { color: 'green' },
  typeRed: { color: 'red' },
});

const VerifiedField = (props) => {
  const classes = useStyles();
  return (
    <span
      className={
        props.record && props.record.notVerified
          ? classes.typeRed
          : classes.typeGreen
      }
    >
      {props.record && props.record.notVerified ? '✗' : '✓'}
    </span>
  );
};

export default ({ permissions, ...props }) => (
  <List
    perPage={25}
    title={'Байршил'}
    filters={<Filter />}
    empty={<Empty emptyMessage={'Байршил байхгүй байна.'} />}
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField label="Хаяг" source="name" />
      <VerifiedField label="Баталгаажсан" source="notVerified" />
      {(permissions === 'root' || permissions === 'supreme') && (<EditButton />)}
    </Datagrid>
  </List>
);
