import * as React from "react";
import { Route } from "react-router-dom";
import AdminReport from "./containers/report/adminReport";
import DriverReport from "./containers/report/driverReport";
//import Dashboard from './pages/Dashboard';
// import MainDashboard from "./pages/MainDashboard";
import MainMap from "./pages/MainMap";
import Privacy from "./pages/Privacy";
import Replay from "./pages/Replay";
import PrivacyDriver from "./pages/PrivacyDriver";

export default [
  // <Route exact path="/maindashboard" component={MainDashboard} />,
  //<Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/map" component={() => <MainMap />} />,
  <Route exact path="/reportDriver" component={() => <DriverReport />} />,
  <Route exact path="/reportAdmin" component={() => <AdminReport />} />,
  <Route exact path="/replay" component={() => <Replay />} />,
  <Route exact path="/privacy" component={() => <Privacy />} noLayout/>,
  <Route exact path="/privacy-driver" component={() => <PrivacyDriver />} noLayout/>,
];
