export const PUSH_ORDER = 'PUSH_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';

export const pushOrder = (order) => {
  return { type: PUSH_ORDER, data: order };
};

export const removeOrder = (order) => {
  return {
    type: REMOVE_ORDER,
    data: order,
  };
};