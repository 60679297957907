import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
} from "react-admin";
import Filter from "./Filter";
import Empty from "../../components/react-admin/Empty";

export default (props, sos) => (
  <List
    perPage={25}
    title={"SOS"}
    filters={<Filter />}
    empty={<Empty emptyMessage={"SOS байхгүй байна."} />}
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="edit">
      {/* <ReferenceField
        label="Жолооч"
        source="taxi.driver.name"
        reference="taxis"
      >
        <TextField label="Жолооч" source="name" />
      </ReferenceField> */}
      <TextField label="Жолооч" source="taxi.driver.name" />
      <TextField label="Машины дугаар" source="taxi.vehicleNumber" />
      <TextField label="Утасны дугаар" source="taxi.driver.tel" />
      <DateField label="Хэзээ" source="createdAt" showTime options={{
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
      }} />
      {/* <ReferenceField label="Хаяг" source="place.id" reference="places">
        <TextField label="Жолооч" source="name" />
      </ReferenceField> */}
      <EditButton />
    </Datagrid>
  </List>
);
