import React from 'react';
import { Layout } from 'react-admin';

// import MyAppBar from './MyAppBar';
import MySidebar from './MySidebar';
import MyMenu from './MyMenu';
import MyNotification from './MyNotification';
import { lightTheme } from '../../utils/themes';

const MyLayout = (props) => {
  // const dispatch = useDispatch();

  // const role = localStorage.getItem('role');

  // useEffect(() => {
  //   if (role === 'viewer') {
  //     dispatch(setSidebarVisibility(false));
  //   }
  // });

  return (
    <Layout
      {...props}
      appBar={MyMenu}
      sidebar={MySidebar}
      // menu={null}
      notification={MyNotification}
      theme={lightTheme}
    />
  );
};

export default MyLayout;
