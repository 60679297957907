import React from 'react';
import Paper from '@material-ui/core/Paper';
import './index.css';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function TicketList({ tickets }) {
  return (
    <div style={{ marginTop: 15 }}>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Хэзээ</TableCell>
              <TableCell align="left">Эрх</TableCell>
              <TableCell align="left">Тайлбар</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  <Moment format="YYYY/MM/DD HH:mm">{row.createdAt}</Moment>
                </TableCell>
                <TableCell align="left">{row.total}</TableCell>
                <TableCell align="left">
                  {row.description ? row.description : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
