import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import Filter from "./Filter";
import Empty from "../../components/react-admin/Empty";

export default (props) => (
  <List
    perPage={25}
    title={"Бонус"}
    filters={<Filter />}
    empty={<Empty emptyMessage={"Тохиргоо байхгүй байна."} />}
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="edit">
      <TextField source="cost" />
      <EditButton />
    </Datagrid>
  </List>
);
