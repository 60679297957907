import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import Filter from './Filter';
import Empty from '../../components/react-admin/Empty';

export default (props) => (
  <List
    perPage={25}
    title={'Зорчигч'}
    filters={<Filter />}
    empty={<Empty emptyMessage={'Зорчигч байхгүй байна.'} />}
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
    <DateField
        label="Бүртгүүлсэн өдөр"
        source="createdAt"
        showTime
        options={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,
        }}
      />
      <TextField label="Нэр" source="name" />
      <TextField label="Утасны дугаар" source="tel" />
      <TextField label="Мэйл хаяг" source="email" />
    </Datagrid>
  </List>
);
