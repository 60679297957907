import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Slide from "@material-ui/core/Slide";
import ChatApp from './ChatApp';
import { Paper } from '@material-ui/core';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    flexWrap: 'wrap',
    display: 'flex',
    color: 'theme.palette.text.secondary',
    margin: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    // height: 450,
  },
}));

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

const ChatDialog = ({ open, handleClose, ...chatAppProps }) => {
  const classes = useStyles();
  return (
    <Paper
      // fullWidth={true}
      className={classes.paper}
      // TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      // open={open}
      style={{
        maxWidth: '350px',
        maxHeight: '350px',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >

      <Typography variant="h6">Messages</Typography>
      <ChatApp {...chatAppProps} />
    </Paper>
  );
};

export default ChatDialog;
