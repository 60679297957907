import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, useNotify } from 'react-admin';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import SubMenu from './SubMenu';
import MapIcon from '@material-ui/icons/Map';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AdminIcon from '@material-ui/icons/VerifiedUser';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import VideocamIcon from '@material-ui/icons/Videocam';
// import ErrorIcon from '@material-ui/icons/Error';
import { usePermissions } from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../constants/Api';
import ShortcutTextField from './ShortcutTextField';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
  },
  button: {
    fontWeight: 'bold',
    '& svg': { color: 'white' },
    color: 'white',
    background: '#f0ad4e',
  },
}));

const Menu = ({ onMenuClick, dense, logout }) => {
  const classes = useStyles();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    other: true,
    menuHis: false,
  });

  const closeAll = () => {
    setState({
      other: true,
      menuHis: false,
    });
  };

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const { permissions } = usePermissions();

  const [modalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };
  const hideModal = () => {
    setModalOpen(false);
  };

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [key, setKey] = useState('');

  useEffect(() => {
    const asyncFunc = async () => {
      const k = await localStorage.getItem('keyOrderCreate');

      console.log('key', k);
      if (k && k.length > 0) {
        setKey(k);
      }
    };
    asyncFunc();
  }, []);

  const changePassword = async () => {
    setIsLoading(true);
    try {
      if (
        !(
          oldPassword.length > 0 &&
          password.length > 0 &&
          passwordAgain.length > 0
        )
      ) {
        throw Error('Бүх талбарыг бөглөнө үү');
      }
      if (password !== passwordAgain) {
        throw Error('Нууц үг таарахгүй байна');
      }

      const config = { headers: {} };
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      await axios({
        method: 'PUT',
        url: Api.password,
        headers: config.headers,
        data: { newPassword: password, password: oldPassword },
      });
      setOldPassword('');
      setPassword('');
      setPasswordAgain('');
      notify('Нууц үг амжилттай солигдлоо');
    } catch (e) {
      notify(e.message, 'warning');
      console.log('e', e);
    }
    setIsLoading(false);
  };
  const changeKey = async () => {
    setIsLoading(true);
    try {
      if (!(key.length > 0)) {
        throw Error('Бүх талбарыг бөглөнө үү');
      }
      const config = { headers: {} };
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      await axios({
        method: 'PUT',
        url: Api.key,
        headers: config.headers,
        data: { keyOrderCreate: key },
      });
      localStorage.setItem('keyOrderCreate', key);
      notify('Амжилттай солигдлоо');
    } catch (e) {
      notify(e.message, 'warning');
      console.log('e', e);
    }
    setIsLoading(false);
  };

  // const company = localStorage.getItem('company');

  const getFrom = () => {
    const from = localStorage.getItem('user_from');
    if (from) {
      return from;
    } else {
      return moment(new Date()).subtract(1, 'days').toISOString();
    }
  };
  const getTo = () => {
    const to = localStorage.getItem('user_to');
    if (to) {
      return to;
    } else {
      return moment(new Date()).toISOString();
    }
  };

  const getReviewFrom = () => {
    const from = localStorage.getItem('review_from');
    if (from) {
      return from;
    } else {
      return moment(new Date()).subtract(1, 'days').toISOString();
    }
  };
  const getReviewTo = () => {
    const to = localStorage.getItem('review_to');
    if (to) {
      return to;
    } else {
      return moment(new Date()).toISOString();
    }
  };

  const getHeaderFrom = () => {
    const from = localStorage.getItem('header_from');
    if (from) {
      return from;
    } else {
      return moment(new Date()).subtract(1, 'days').toISOString();
    }
  };
  const getHeaderTo = () => {
    const to = localStorage.getItem('header_to');
    if (to) {
      return to;
    } else {
      return moment(new Date()).toISOString();
    }
  };

  const getTransactionFrom = () => {
    const from = localStorage.getItem('transaction_from');
    if (from) {
      return from;
    } else {
      return moment(new Date()).subtract(1, 'days').toISOString();
    }
  };
  const getTransactionTo = () => {
    const to = localStorage.getItem('transaction_to');
    if (to) {
      return to;
    } else {
      return moment(new Date()).toISOString();
    }
  };

  return (
    <div
      style={{
        zIndex: 10000,
        display: 'flex',
        marginTop: -40,
        height: '50px',
        position: 'absolute',
        width: '100%',
      }}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={hideModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div
            style={{
              width: 400,
              height: 400,
              backgroundColor: '#fff',
              padding: 15,
            }}
          >
            <strong style={{ fontSize: 21 }}>Нууц үг солих</strong>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: 20,
              }}
            >
              <div style={{ flex: 1, marginRight: 4 }}>
                <TextField
                  variant="outlined"
                  label="Хуучин нууц үг"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  size={'small'}
                />
              </div>
            </div>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: 20,
              }}
            >
              <div style={{ flex: 1, marginRight: 4 }}>
                <TextField
                  variant="outlined"
                  label="Шинэ нууц үг"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size={'small'}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 4 }}>
                <TextField
                  variant="outlined"
                  label="Шинэ нууц үг давтах"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={passwordAgain}
                  onChange={(e) => setPasswordAgain(e.target.value)}
                  min="1"
                  size={'small'}
                  security={true}
                />
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              {isLoading ? (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changePassword}
                  startIcon={<SaveIcon />}
                  fullWidth
                  loading={isLoading}
                >
                  Нууц үг солих
                </Button>
              )}
            </div>

            <div style={{ marginTop: 30 }}>
              <strong style={{ fontSize: 21 }}>Hotkeys</strong>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  marginTop: 20,
                }}
              >
                <div style={{ flex: 1, marginRight: 4 }}>
                  <ShortcutTextField
                    variant="outlined"
                    label="Захиалга өгөх"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={key}
                    onChange={(value) => setKey(value)}
                    size={'small'}
                    fullWidth
                    allowNumber
                  />
                </div>
              </div>
              <div style={{ marginTop: 15 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={changeKey}
                  startIcon={<SaveIcon />}
                  fullWidth
                >
                  Хадгалах
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <div
        style={{
          display: 'flex',
          flex: 1,
        }}
      >
        <MenuItemLink
          to={`/map`}
          primaryText={'Үндсэн нүүр'}
          // style={{ fontSize: 24 }}
          leftIcon={<MapIcon />}
          onClick={() => {
            closeAll();
            if (onMenuClick) {
              onMenuClick();
            }
          }}
          sidebarIsOpen={false}
          dense={dense}
        />
        {(permissions === 'root' ||
          permissions === 'supreme' ||
          permissions === 'reporter') && (
          <SubMenu
            handleToggle={() => {
              handleToggle('report');
            }}
            isOpen={state.report}
            sidebarIsOpen={open}
            name="Тайлан"
            icon={<MenuBookIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/reportHeader?displayedFilters={"from":true,"to":true}&filter={"from": "${getHeaderFrom()}", "to": "${getHeaderTo()}"}`}
              primaryText={'Ерөнхий'}
              // style={{ fontSize: 24 }}
              leftIcon={<DashboardIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/reportDriver`}
              primaryText={'Жолооч'}
              // style={{ fontSize: 24 }}
              leftIcon={<LocalTaxiIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/transactions?displayedFilters={"from":true,"to":true}&filter={"from": "${getTransactionFrom()}", "to": "${getTransactionTo()}"}`}
              primaryText={'Жолоочийн эрх'}
              leftIcon={<LocalTaxiIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/reportAdmin`}
              primaryText={'Хэрэглэгч'}
              // style={{ fontSize: 24 }}
              leftIcon={<AdminIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/reportByUser?displayedFilters={"from":true,"to":true}&filter={"from": "${getFrom()}", "to": "${getTo()}"}`}
              primaryText={'Зорчигч'}
              // style={{ fontSize: 24 }}
              leftIcon={<PeopleAltIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/reviews?displayedFilters={"from":true,"to":true}&filter={"from": "${getReviewFrom()}", "to": "${getReviewTo()}"}`}
              primaryText={'Үнэлгээ'}
              // style={{ fontSize: 24 }}
              leftIcon={<AdminIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
          </SubMenu>
        )}
        {/* {(permissions === 'root' ||
          permissions === 'admin' ||
          permissions === 'supreme' ||
          permissions === 'reporter') && (
          <MenuItemLink
            to={`/maindashboard`}
            primaryText={'Хянах самбар'}
            // style={{ fontSize: 24 }}
            leftIcon={<EqualizerIcon />}
            onClick={() => {
              closeAll();
              if (onMenuClick) {
                onMenuClick();
              }
            }}
            sidebarIsOpen={false}
            dense={dense}
          />
        )} */}
        {(permissions === 'root' || permissions === 'supreme') && (
          <MenuItemLink
            to={`/admins`}
            primaryText={'Хэрэглэгчид'}
            // style={{ fontSize: 24 }}
            leftIcon={<AdminIcon />}
            onClick={() => {
              closeAll();
              if (onMenuClick) {
                onMenuClick();
              }
            }}
            sidebarIsOpen={false}
            dense={dense}
          />
        )}
        <MenuItemLink
          to={`/orders`} //?displayedFilters={"user":true,"taxi":true,"admin":true}`}
          primaryText={'Захиалга'}
          // style={{ fontSize: 24 }}
          leftIcon={<DescriptionIcon />}
          onClick={() => {
            closeAll();
            if (onMenuClick) {
              onMenuClick();
            }
          }}
          sidebarIsOpen={false}
          dense={dense}
        />
        <MenuItemLink
          to={`/places`}
          primaryText={'Байршил'}
          // style={{ fontSize: 24 }}
          leftIcon={<LocationOnIcon />}
          onClick={() => {
            closeAll();
            if (onMenuClick) {
              onMenuClick();
            }
          }}
          sidebarIsOpen={false}
          dense={dense}
        />
        {(permissions === 'root' ||
          permissions === 'admin' ||
          permissions === 'supreme' ||
          permissions === 'reporter') && (
          <MenuItemLink
            to={`/taxis`}
            primaryText={'Жолооч'}
            // style={{ fontSize: 24 }}
            leftIcon={<LocalTaxiIcon />}
            onClick={() => {
              closeAll();
              if (onMenuClick) {
                onMenuClick();
              }
            }}
            sidebarIsOpen={false}
            dense={dense}
          />
        )}
        {(permissions === 'root' || permissions === 'supreme') && (
          <MenuItemLink
            to={`/users`}
            primaryText={'Зорчигч'}
            // style={{ fontSize: 24 }}
            leftIcon={<PeopleAltIcon />}
            onClick={() => {
              closeAll();
              if (onMenuClick) {
                onMenuClick();
              }
            }}
            sidebarIsOpen={false}
            dense={dense}
          />
        )}
        {(permissions === 'root' ||
          permissions === 'supreme' ||
          permissions === 'reporter') && (
          <MenuItemLink
            to={`/replay`}
            primaryText={'Replay'}
            // style={{ fontSize: 24 }}
            leftIcon={<VideocamIcon />}
            onClick={() => {
              closeAll();
              if (onMenuClick) {
                onMenuClick();
              }
            }}
            sidebarIsOpen={false}
            dense={dense}
          />
        )}
        {(permissions === 'root' || permissions === 'supreme') && (
          <SubMenu
            handleToggle={() => {
              handleToggle('admin');
            }}
            isOpen={state.admin}
            sidebarIsOpen={open}
            name="Бусад"
            icon={<SettingsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/settings`}
              primaryText={'Тохиргоо'}
              // style={{ fontSize: 24 }}
              leftIcon={<SettingsIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/bonuses`}
              primaryText={'Бонус'}
              // style={{ fontSize: 24 }}
              leftIcon={<SettingsIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/sos`}
              primaryText={'SOS'}
              // style={{ fontSize: 24 }}
              leftIcon={<SettingsIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/groups`}
              primaryText={'Груп'}
              // style={{ fontSize: 24 }}
              leftIcon={<SettingsIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/arrivalMinutes`}
              primaryText={'Хүлээлгийн минут'}
              // style={{ fontSize: 24 }}
              leftIcon={<SettingsIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
            <MenuItemLink
              to={`/cancelDescriptions`}
              primaryText={'Цуцлах шалтгаан'}
              // style={{ fontSize: 24 }}
              leftIcon={<SettingsIcon />}
              onClick={() => {
                closeAll();
                if (onMenuClick) {
                  onMenuClick();
                }
              }}
              sidebarIsOpen={false}
              dense={dense}
            />
          </SubMenu>
        )}
        <Divider />

        {logout}

        <div style={{ flex: 1 }}></div>

        <div
          style={{
            display: 'relative',
            right: 0,
            // flex: 1,
            justifyContent: 'center',
            alignItems: 'right',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              marginRight: 30,
              textAlign: 'right',
              fontSize: 12,
              color: 'gray',
              display: 'relative',
              right: 0,
            }}
          >
            Нэвтэрсэн хэрэглэгч
          </div>
          <div
            style={{ marginRight: 30, textAlign: 'right', color: '#085176' }}
          >
            <Link
              onClick={() => {
                showModal();
              }}
              component="button"
              style={{ fontSize: 16 }}
            >
              {localStorage.getItem('username')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Menu);
