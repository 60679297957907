import {
  GET_INIT,
  GET_INIT_LOADING,
  GET_TAXIS,
  GET_TAXIS_LOADING,
  GET_TAXIS_INFO,
  GET_TAXIS_INFO_LOADING,
  SOS_RECEIVED,
  SOS_CLEAR,
  UPDATE_TAXI,
  CLEAR_UPDATE_TAXI,
  GET_CHAT_TOKEN,
  GET_ALL_TAXIS,
  updateTaxi,
} from '../actions/main';

const initialState = {
  isLoading: false,
  data: [],
  taxisIsLoading: false,
  taxis: [],
  checkDate: null,
  sosData: null,
  updatedTaxi: null,
  chatToken: null,
  freeTaxi: 0,
  busyTaxi: 0,
  inactiveTaxi: 0,
  logoutTaxi: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INIT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INIT:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      };
    case GET_TAXIS_LOADING:
      return {
        ...state,
        taxisIsLoading: true,
      };
    case GET_TAXIS: {
      const taxisMap = {};
      state.taxis.forEach((item) => {
        taxisMap[item.id] = item;
      });

      const newTaxis = action.data;
      if (newTaxis) {
        newTaxis.forEach((item) => {
          taxisMap[item.id] = item;
        });
      }

      const taxis = Object.keys(taxisMap).map((key) => taxisMap[key]);

      return {
        ...state,
        taxis: taxis,
        taxisIsLoading: false,
        checkDate: action.checkDate,

        freeTaxi: action.freeTaxi,
        busyTaxi: action.busyTaxi,
        inactiveTaxi: action.inactiveTaxi,
        logoutTaxi: action.logoutTaxi,
      };
    }
    case GET_ALL_TAXIS: {
      const taxisMap = {};
      state.taxis.forEach((item) => {
        taxisMap[item.id] = item;
      });

      const newTaxis = action.data;
      if (newTaxis) {
        newTaxis.forEach((item) => {
          taxisMap[item.id] = item;
        });
      }
      const taxis = Object.keys(taxisMap).map((key) => taxisMap[key]);
      return {
        ...state,
        taxis: taxis,
        taxisIsLoading: false,
      };
    }
    case GET_TAXIS_INFO_LOADING:
      return {
        ...state,
        taxisIsLoading: true,
      };
    case GET_TAXIS_INFO:
      return {
        ...state,
        data: action.data,
        taxisIsLoading: false,
      };
    case SOS_RECEIVED:
      return {
        ...state,
        sosData: action.data,
      };
    case SOS_CLEAR:
      return {
        ...state,
        sosData: null,
      };
    case UPDATE_TAXI: {
      const taxisMap = {};
      state.taxis.forEach((item) => {
        taxisMap[item.id] = item;
      });

      let updatedTaxi = action.data;
      if (updatedTaxi && updatedTaxi.taxi) {
        const { taxi, ...currentOrder } = updatedTaxi;

        updatedTaxi = {
          ...updateTaxi,
          taxi: {
            ...taxi,
            currentOrder,
          },
        };

        taxisMap[updatedTaxi.taxi.id] = updatedTaxi.taxi;
      }

      const taxis = Object.keys(taxisMap).map((key) => taxisMap[key]);

      return {
        ...state,
        taxis: taxis,
        updatedTaxi,
      };
    }
    case CLEAR_UPDATE_TAXI:
      return {
        ...state,
        updatedTaxi: null,
      };
    case GET_CHAT_TOKEN:
      return {
        ...state,
        chatToken: action.data,
      };
    default:
      return state;
  }
};
