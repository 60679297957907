import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from 'react';
import { stringify } from 'query-string';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
// import { useForm } from 'react-final-form';
import Api from '../../constants/Api';
import CircularProgress from '@material-ui/core/CircularProgress';

const WAIT_INTERVAL = 300;
let timerID;

const PlacesNearbyInput = ({
  location,
  setLocation = () => {},
  placeSelectedByInput,
  setPlaceSelectedByInput = () => {},
  value,
  onChange,
  label = 'Хаяг',
}) => {
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const [open, setOpen] = React.useState(false);
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [currentPlace, setCurrentPlace] = useState(null);
  const [searching, setSearching] = useState(false);

  const source = useRef(null);

  function getSource() {
    if (source.current == null) {
      const CancelToken = axios.CancelToken;
      source.current = CancelToken.source();
    }
    return source.current;
  }

  useEffect(() => {
    if (!value) {
      setCurrentPlace(null);
    }
  }, [value]);

  const getPlaces = useCallback(async () => {
    let api = search ? Api.autoComplete : Api.placesNearby;

    let query = search
      ? { q: search }
      : { lat: location.lat, lng: location.lng };

    try {
      const url = `${api}?${stringify(query)}`;

      //Check if there are any previous pending requests
      if (typeof getSource() != typeof undefined) {
        getSource().cancel('Operation canceled due to new request.');
      }

      //new request
      source.current = null;

      const config = { cancelToken: getSource().token, headers: {} };
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      setLoading(true);

      const { data } = await axios.get(url, config);

      setPlaces(data.map((item, index) => ({ ...item, index })));

      setLoading(false);
    } catch (e) {
      console.log('err', e);
      setLoading(false);
    }
  }, [location, search]);

  useEffect(() => {
    if (!search && !location) {
      return;
    }
    clearTimeout(timerID);

    timerID = setTimeout(() => {
      getPlaces();
    }, WAIT_INTERVAL);
  }, [getPlaces, location, search]);

  const getGooglePlace = async (place) => {
    try {
      const url = Api.placeGet(place._id);

      const config = { headers: {} };
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      setLoading(true);
      const { data } = await axios.get(url, config);
      setLoading(false);

      return data;
    } catch (e) {
      console.log('err', e);
      setLoading(false);
    }
    return null;
  };

  useEffect(() => {
    if (!searching && !placeSelectedByInput) {
      if (places.length > 0) {
        setCurrentPlace(places[0]);
      } else {
        setCurrentPlace(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  useEffect(() => {
    if (currentPlace) {
      onChange(currentPlace);
    } else {
      onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlace]);

  useEffect(() => {
    setSearch(null);
  }, [currentPlace, location]);

  useEffect(() => {
    if (!location) {
      setCurrentPlace(null);
    }
  }, [location]);

  return (
    <Fragment>
      <div
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Autocomplete
          id={label}
          open={open}
          // freeSolo
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          renderOption={(option, option2) => {
            const { index } = option;
            const { selected } = option2;
            return (
              <div
                style={{
                  flex: 1,
                  color:
                    index === highlightedIndex || selected ? '#000' : '#555',
                  margin: 0,
                  padding: 0,
                }}
              >
                {option.type === 'google' && (
                  <img
                    style={{ width: 20, height: 20 }}
                    src={require('../../assets/google.png')}
                    alt=""
                  />
                )}
                {option.name}
              </div>
            );
          }}
          getOptionLabel={(option) => {
            if (option) {
              return option.name;
            } else {
              return '';
            }
          }}
          filterOptions={(x) => x}
          getOptionSelected={(option, value) => {
            return option.index === value.index;
          }}
          options={places}
          loading={loading}
          style={{ width: '100%' }}
          value={currentPlace}
          onChange={async (e, val) => {
            if (val && val.location) {
              setCurrentPlace(val);

              setPlaceSelectedByInput(true);
              setLocation({
                lat: val.location.coordinates[1],
                lng: val.location.coordinates[0],
              });
            } else {
              if (val && val.type === 'google') {
                const googlePlace = await getGooglePlace(val);
                if (googlePlace && googlePlace.location) {
                  setCurrentPlace({ ...val, location: googlePlace.location });

                  setLocation({
                    lat: googlePlace.location.coordinates[1],
                    lng: googlePlace.location.coordinates[0],
                  });

                  setPlaceSelectedByInput(true);
                }
              } else {
                setPlaces([]);
                setCurrentPlace(null);
                setPlaceSelectedByInput(true);
                setLocation(null);
              }
            }

            setHighlightedIndex(-1);
          }}
          onHighlightChange={(e, val) => {
            if (val) {
              setHighlightedIndex(val.index);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              onChange={(e) => {
                setSearch(e.target.value);
                // form.change('placeName', e.target.value);
              }}
              onFocus={() => {
                setSearching(true);
              }}
              onBlur={async () => {
                //Check if there are any previous pending requests
                if (typeof getSource() != typeof undefined) {
                  getSource().cancel('Operation canceled due to new request.');
                }

                //new request
                source.current = null;

                setSearching(false);

                if (places.length > 0) {
                  const index = highlightedIndex > 0 ? highlightedIndex : 0;

                  if (places[index].location) {
                    setLocation({
                      lat: places[index].location.coordinates[1],
                      lng: places[index].location.coordinates[0],
                    });
                  } else {
                    if (places[index] && places[index].type === 'google') {
                      const googlePlace = await getGooglePlace(places[index]);
                      if (googlePlace && googlePlace.location) {
                        setLocation({
                          lat: googlePlace.location.coordinates[1],
                          lng: googlePlace.location.coordinates[0],
                        });
                      }
                    } else {
                      setLocation(null);
                    }
                  }

                  setCurrentPlace(places[index]);
                  setPlaceSelectedByInput(true);
                }

                setHighlightedIndex(-1);
              }}
              value={search}
              size={'small'}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        {loading && (
          <CircularProgress
            size={20}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              bottom: 10,
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default PlacesNearbyInput;
