import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Title, Authenticated } from 'react-admin';
import { useDispatch } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Chat from 'twilio-chat';

import * as initActions from '../actions/main';
import useTaxiMarkers from '../hooks/useTaxiMarkers';
import useOrderRadius from '../hooks/useOrderRadius';
import useDirections from '../hooks/useDirections';

import OrderTabList from '../components/orders/OrderTabList';
import LiveDrivers from '../components/LiveDrivers';
import DriverDetailDialog from './DriverDetailDialog';
import OrderCard from '../components/OrderCard';
import Sos from '../components/Sos';
import Table from '../components/Table';
import './MainMap.css';
// import * as ordersActions from '../actions/orders';

import ChatDialog from '../components/ChatDialog';
import ChatIcon from '@material-ui/icons/Chat';

import httpClient from '../providers/httpClient';
import Api from '../constants/Api';
import ResizePanel from 'react-resize-panel';
import classNames from 'classnames/bind';
import style from './MainMap.css';
import Badge from '@material-ui/core/Badge';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
// import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginRight: '30%',
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: '0',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '540px',
  },
  drawerHeader: {
    // display: 'flex',
    // alignItems: 'bottom',
    // padding: theme.spacing(0, 1),
    // marginLeft: '20%',
    // marginTop: '8%',
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    // justifyContent: 'flex-start',
    marginTop: 50,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  paper: {
    border: '1px solid',
    width: 300,
    height: 300,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

let cx = classNames.bind(style);
const MainMap = () => {
  const googleMapRef = React.createRef();
  const dispatch = useDispatch();

  const [center, setCenter] = useState({ lat: 47.9216914, lng: 106.9184941 });
  useEffect(() => {
    const companyLocation = localStorage.getItem('companyLocation');
    if (companyLocation) {
      const json = JSON.parse(companyLocation)
      const cent = { lat: json.coordinates[1], lng: json.coordinates[0] }
      setCenter(cent);
    }
  }, []);
  const [zoom] = useState(14);
  const [item, setItem] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [marker, setMarker] = useState(null);
  const [placeSelectedByInput, setPlaceSelectedByInput] = useState(false);

  const [infoWindows] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useOrderRadius({
    googleMapRef,
  });

  useDirections({
    googleMapRef,
  });

  const [taxiMarkers] = useTaxiMarkers({
    googleMapRef,
    handleMarkerClick: (item) => {
      setItem(item);
    },
    allowInit: true,
    infoWindows,
  });

  const sosData = useSelector((state) => state.main.sosData);
  // const orderRadiusOrders = useSelector((state) => state.orderRadius.orders);

  const [, setChatOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  //-------------TWILIO CHAT---------------
  const [token, setToken] = useState('');
  const [chatReady, setChatReady] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(true);
  const [channelName, setChannelName] = useState('');
  const chatClient = useRef(null);
  const channel = useRef(null);
  const driverTable = useRef(null);
  const [newMessageJustAdded, setNewMessageJustAdded] = useState(null);

  useEffect(() => {
    if (newMessageJustAdded) {
      if (!notification) {
        setNotification(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageJustAdded]);

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    try {
      const { json: data } = await httpClient(`${Api.chatToken}`, {
        method: 'POST',
      });
      setToken(data.token);
      setChannelName(data.chatChannel);
    } catch (e) {}
  };

  useEffect(() => {
    if (token) {
      initChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const initChat = () => {
    chatClient.current = new Chat(token);
    chatClient.current.initialize().then(clientInitiated);
  };

  const clientInitiated = () => {
    setIsChatLoading(true);
    setChatReady(true);
  };

  const messageAdded = (message) => {
    setNewMessageJustAdded(message);
  };

  useEffect(() => {
    if (chatReady) {
      chatClient.current
        .getChannelByUniqueName(channelName)
        .then((_channel) => {
          if (_channel) {
            return (channel.current = _channel);
          }
        })
        .catch((err) => {
          if (err.body.code === 50300) {
            return chatClient.current.createChannel({
              uniqueName: channelName,
            });
          }
        })
        .then((_channel) => {
          channel.current = _channel;
          window.channel = _channel;
          return channel.current.join();
        })
        .catch((err) => {
          if (err.code !== 50404) {
            console.log('code', err.code);
            console.log('keys', Object.keys(err));
            console.log('err', err);
          }
        })
        .then(() => {
          channel.current.on('messageAdded', messageAdded);
          setIsChatLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatReady]);

  const handleGoogleMapApi = async ({ maps, map }) => {
    maps = googleMapRef.current.maps_;
    map = googleMapRef.current.map_;

    googleMapRef.current.maps_.event.addListener(
      map,
      'click',
      function (event) {
        infoWindows.forEach((item) => item.close());
        while (infoWindows.length) {
          infoWindows.pop();
        }
      }
    );

    googleMapRef.current.maps_.event.addListener(
      map,
      'dragstart',
      function (event) {
        infoWindows.forEach((item) => item.close());
        while (infoWindows.length) {
          infoWindows.pop();
        }
      }
    );
  };

  const handleClose = () => {
    setItem(null);
  };

  useEffect(() => {
    const map = googleMapRef.current.map_;
    const maps = googleMapRef.current.maps_;

    if (map && maps && sosData) {
      map.setZoom(15);

      //trigger mouseover
      if (map && maps) {
        if (sosData.id) {
          const filtered = taxiMarkers.filter(
            ({ item }) => item.id === sosData.id
          );

          if (filtered.length > 0) {
            const { marker } = filtered[0];
            map.setCenter(marker.position);
            maps.event.trigger(marker, 'mouseover');
          }
        } else {
          infoWindows.forEach((item) => item.close());
          while (infoWindows.length) {
            infoWindows.pop();
          }
        }
      }
    }
  }, [googleMapRef, infoWindows, sosData, taxiMarkers]);

  const getTaxis = useCallback(async () => {
    try {
      await dispatch(initActions.getTaxis());
    } catch (e) {
      console.log('err', e);
    }
  }, [dispatch]);

  const getChatToken = useCallback(async () => {
    try {
      await dispatch(initActions.getChatToken());
    } catch (e) {
      console.log('err', e);
    }
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      getTaxis();
    }, 7500);

    return () => clearInterval(interval);
  }, [dispatch, getTaxis]);

  useEffect(() => {
    getChatToken();
    getTaxis();
    // getCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowForm(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const Marker = googleMapRef.current.maps_
      ? googleMapRef.current.maps_.Marker
      : null;
    const GoogleMap = googleMapRef.current.map_;

    if (markerPosition && Marker && GoogleMap && !marker) {
      setMarker(
        new Marker({
          position: markerPosition,
          map: GoogleMap,
          scaledSize: { width: 16, height: 16 },
          icon: {
            url: require('../assets/map-marker.png'),
          },
        })
      );
    }
  }, [googleMapRef, marker, markerPosition, setMarker]);

  useEffect(() => {
    const map = googleMapRef.current.map_;
    if (map) {
      if (marker) {
        marker.setMap(null);
        setMarker(null);
      }
      if (placeSelectedByInput) {
        setPlaceSelectedByInput(false);
        if (markerPosition) {
          map.setCenter(markerPosition);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerPosition, placeSelectedByInput]);

  const handleOnClick = (e) => {
    if (marker) {
      marker.setMap(null);
      setMarker(null);
    }
    setMarkerPosition({ lat: e.lat, lng: e.lng });
  };

  const mapTypeId =
    localStorage.getItem('mapTypeId') &&
    localStorage.getItem('mapTypeId') !== 'null'
      ? localStorage.getItem('mapTypeId')
      : 'roadmap';

  //CHAT POPPER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openChat = Boolean(anchorEl);
  const id = openChat ? 'simple-popper' : undefined;
  //END
  return (
    <Authenticated>
      <div
        style={{
          display: 'flex',
          flexdirection: 'row',
          // overflow: 'scroll',
          height: 'calc((100vh - 70px))',
          marginBottom: '-10px',
        }}
      >
        <Title title="Үндсэн нүүр" />

        <div className="Map">
          <div className={cx('container')}>
            {/* <ResizePanel direction="s" style={{ flexGrow: "5" }}>
              <div className={cx("sidebar", "panel")}> */}
            <GoogleMapReact
              onMapTypeIdChange={(val) => {
                // console.log('val', val)
                localStorage.setItem('mapTypeId', val);
              }}
              bootstrapURLKeys={{
                key: 'AIzaSyAEBWB-b-YZ5gCMN3aZxtWZ-3huACGEglk',
              }}
              options={(maps) => {
                return {
                  fullscreenControl: true,
                  mapTypeControl: true,
                  mapTypeControlOptions: {
                    style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: open
                      ? maps.ControlPosition.BOTTOM_CENTER
                      : maps.ControlPosition.TOP_CENTER,
                  },
                  styles: [{ stylers: [{ visibility: 'on' }] }],
                  mapTypeId: mapTypeId,
                };
              }}
              tilt={60}
              ref={googleMapRef}
              defaultCenter={center}
              defaultZoom={zoom}
              distanceToMouse={() => {}}
              yesIWantToUseGoogleMapApiInternals
              onClick={handleOnClick}
              onGoogleApiLoaded={handleGoogleMapApi}
              // onReady={(mapProps, map)=> {
              //   map.setOptions({
              //     styles: [
              //       {
              //         featureType: "poi.business",
              //         stylers: [{ visibility: "on" }],
              //       },
              //       {
              //         featureType: "transit",
              //         elementType: "labels.icon",
              //         stylers: [{ visibility: "on" }],
              //       },
              //     ]
              //   })
              // }}
            ></GoogleMapReact>
            {/* <div
              style={{
                position: 'absolute',
                right: open ? 540 : 60,
                marginTop: 10,
                zIndex: 1,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                backgroundColor: 'white',
                height: 40,
                width: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {notification > 0 && (
                <Badge
                  color="error"
                  badgeContent={2}
                  max={1}
                  invisible={notification === 0}
                ></Badge>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  setChatOpen(true);
                  setNotification(false);
                }}
              >
                <ChatIcon />
              </IconButton>
            </div> */}
            <div
              style={{
                position: 'absolute',
                right: open ? 530 : 60,
                marginTop: 10,
                zIndex: 1,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                backgroundColor: 'white',
                height: 40,
                width: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {notification > 0 && (
                <Badge
                  color="error"
                  badgeContent={2}
                  max={1}
                  invisible={openChat}
                ></Badge>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={handleClick}
              >
                <ChatIcon />
              </IconButton>
              <Popper
                style={{ zIndex: 10000 }}
                placement="left-end"
                id={id}
                open={openChat}
                anchorEl={anchorEl}
              >
                {/* <div className={classes.paper}> */}
                <ChatDialog
                  open={true}
                  handleClose={() => setChatOpen(false)}
                  channel={channel}
                  isLoading={isChatLoading}
                  chatClient={chatClient}
                />
                {/* </div> */}
              </Popper>
            </div>
            <div
              style={{
                position: 'absolute',
                right: 10,
                marginTop: 60,
                zIndex: 1,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                backgroundColor: 'white',
                height: 40,
                width: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                color="primary"
                component="span"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <MenuIcon size={20} />
              </IconButton>
            </div>

            {/* </div>
            </ResizePanel> */}
            {/* <div className={cx("content", "panel")}>
              <ResizePanel
                direction="e"
                style={{ flexGrow: "1", height: "5px" }}
              >
                <div className={cx("sidebar", "panel")}> */}
            <ResizePanel direction="n" style={{ height: '300px' }}>
              <div
                className={cx('footer', 'panel')}
                style={{
                  position: 'relative',
                }}
              >
                <div
                  ref={driverTable}
                  className={cx('footerArea')}
                  style={{
                    width: open ? '70%' : '100%',
                    minWidth: '320px',
                    overflowX: 'auto',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                  }}
                >
                  <Table
                    maxBodyHeight={
                      driverTable.current
                        ? driverTable.current.clientHeight - 123
                        : 0
                    }
                    onPress={(first) => {
                      const map = googleMapRef.current.map_;
                      const maps = googleMapRef.current.maps_;

                      if (map && maps) {
                        if (first) {
                          const filtered = taxiMarkers.filter(
                            ({ item }) => item.id === first.id
                          );

                          if (filtered.length > 0) {
                            const { marker, item } = filtered[0];
                            map.setCenter(marker.position);
                            maps.event.trigger(marker, 'mouseover');

                            setItem(item);
                          }
                        } else {
                          infoWindows.forEach((item) => item.close());
                          while (infoWindows.length) {
                            infoWindows.pop();
                          }
                        }
                      }
                    }}
                    onSearchChange={(first) => {
                      const map = googleMapRef.current.map_;
                      const maps = googleMapRef.current.maps_;

                      if (map && maps) {
                        if (first) {
                          const filtered = taxiMarkers.filter(
                            ({ item }) => item.id === first.id
                          );
                          if (filtered.length > 0) {
                            const { marker } = filtered[0];
                            map.setCenter(marker.position);
                            maps.event.trigger(marker, 'mouseover');
                          }
                        } else {
                          infoWindows.forEach((item) => item.close());
                          while (infoWindows.length) {
                            infoWindows.pop();
                          }
                        }
                      }
                    }}
                  ></Table>
                </div>
              </div>
            </ResizePanel>
            {/* </div>
              </ResizePanel>
            </div> */}
            <div
              style={{
                position: 'absolute',
                left: 8,
                top: 8,
                zIndex: 1,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }}
            >
              <LiveDrivers />
              {showForm && (
                <OrderCard
                  location={markerPosition}
                  setLocation={setMarkerPosition}
                  placeSelectedByInput={placeSelectedByInput}
                  setPlaceSelectedByInput={setPlaceSelectedByInput}
                />
              )}
            </div>
          </div>
        </div>
        {/* <ResizePanel
          direction="w"
          style={{ width: '545px', zIndex: 2 }}
          handleClass={style.customHandle}
          borderClass={style.customResizeBorder}
        > */}
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          {/* <div className={cx('sidebar', 'panel')}>
            <div className="MapList"> */}
          <OrderTabList
            onClickVehicle={(taxi) => {
              const map = googleMapRef.current.map_;
              const maps = googleMapRef.current.maps_;

              if (map && maps) {
                if (taxi) {
                  const filtered = taxiMarkers.filter(
                    ({ item }) => item.id === taxi.id
                  );

                  if (filtered.length > 0) {
                    const { marker, item } = filtered[0];
                    map.setCenter(marker.position);
                    maps.event.trigger(marker, 'mouseover');

                    setItem(item);
                  }
                } else {
                  infoWindows.forEach((item) => item.close());
                  while (infoWindows.length) {
                    infoWindows.pop();
                  }
                }
              }
            }}
            onClickPlace={(place) => {
              const value = {
                lat: place.location.coordinates[1],
                lng: place.location.coordinates[0],
              };

              setMarkerPosition(value);
              const map = googleMapRef.current.map_;

              map.setCenter(value);
            }}
          />
        </Drawer>

        {/* <ChatDialog
          open={chatOpen}
          handleClose={() => setChatOpen(false)}
          channel={channel}
          messages={messages}
          isLoading={isChatLoading}
          chatClient={chatClient}
        /> */}

        {item && (
          <DriverDetailDialog
            item={item}
            handleClose={handleClose}
            onClickPlace={(place) => {
              const value = {
                lat: place.location.coordinates[1],
                lng: place.location.coordinates[0],
              };

              setMarkerPosition(value);
              const map = googleMapRef.current.map_;

              map.setCenter(value);
            }}
          />
        )}
        <Sos
          handleClose={handleClose}
          ModalProps={(first) => {
            const map = googleMapRef.current.map_;
            const maps = googleMapRef.current.maps_;

            if (map && maps) {
              if (first) {
                const filtered = taxiMarkers.filter(
                  ({ item }) => item.id === first.id
                );

                if (filtered.length > 0) {
                  const { marker, item } = filtered[0];
                  map.setCenter(marker.position);
                  maps.event.trigger(marker, 'mouseover');

                  setItem(item);
                }
              } else {
                infoWindows.forEach((item) => item.close());
                while (infoWindows.length) {
                  infoWindows.pop();
                }
              }
            }
          }}
        />
      </div>
    </Authenticated>
  );
};

export default MainMap;
