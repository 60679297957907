export const SET_LOCATION = 'SET_LOCATION';
export const SET_TARGET_LOCATION = 'SET_TARGET_LOCATION';
export const SET_DISTANCE = 'SET_DISTANCE';

export const setLocation = (location) => {
  return { type: SET_LOCATION, data: location };
};

export const setTargetLocation = (location) => {
  return { type: SET_TARGET_LOCATION, data: location };
};

export const setDistance = (distance) => {
  return { type: SET_DISTANCE, data: distance };
};
